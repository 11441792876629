import React from 'react';
import Table from '../../components/common/Table';
import { LegacyCourseDTO } from '../../services/apiClient';

interface CourseTableProps {
  courses: LegacyCourseDTO[];
  onEdit: (course: LegacyCourseDTO) => void;
  onDelete: (course: LegacyCourseDTO) => void;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  pageSize: number;
  onPageSizeChange: (size: number) => void;
  isLoading: boolean;
}


const CourseTable: React.FC<CourseTableProps> = ({
  courses,
  onEdit,
  onDelete,
  currentPage,
  totalPages,
  onPageChange,
  pageSize,
  onPageSizeChange,
  isLoading,
}) => {
  return (
    <Table
      columns={[
        { label: 'Name', key: 'course_Name' },
        { label: 'Address', key: 'course_Address' },
        { label: 'Phone', key: 'course_Phone_Number' },
        { label: 'Email', key: 'course_Email' },
        { label: 'State', key: 'state_Description' },
        { label: 'Country', key: 'country_Description' },
      ]}
      data={courses}
      actions={{
        onEdit: (course) => onEdit(course),
        onDelete: (course) => onDelete(course),
      }}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={onPageChange}
      pageSize={pageSize}
      onPageSizeChange={onPageSizeChange}
      isLoading={isLoading}
    />
  );
};

export default CourseTable;
