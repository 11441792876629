// PublicRoutes.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';

// (Import your public-only views)
import Login from '../../pages/public/Login';
import ForgotPassword from '../../pages/public/ForgotPassword';
import ResetPassword from '../../pages/public/ResetPassword';
import ExploreMap from '../../pages/public/ExploreMap';

const PublicRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/explore/map" element={<ExploreMap />} />
        </Routes>
    );
};

export default PublicRoutes;