import React, { useState, useEffect } from 'react';
import Button from '../../components/common/Button';
import FormField from '../../components/common/FormField';
import { LegacyCountryDTO, LegacyStateDTO } from '../../services/apiClient';
import handleChange from '../../utils/formUtils/handleChange';

interface StateFormProps {
  initialValues: LegacyStateDTO;
  countries: LegacyCountryDTO[];
  onSave: (data: LegacyStateDTO) => void;
  onCancel: () => void;
  countriesLoading: boolean;
  countriesError: string | null;
  isCreating: boolean;
}

const  StateForm: React.FC<StateFormProps> = ({
  initialValues,
  countries,
  onSave,
  onCancel,
  countriesLoading,
  countriesError,
  isCreating,
}) => {
  const [formData, setFormData] = useState(initialValues);
  

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <FormField
          id="state_Description"
          label="Description"
          type="text"
          value={formData.state_Description || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter description"
        />
      </div>
      <div className="mb-4">
        <FormField
          id="state_Code"
          label="State Code"
          type="text"
          value={formData.state_Code || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter state code"
        />
      </div>
      <div className="mb-4">
        <FormField
          id="state_Country_ID"
          label="Country"
          type="dropdown"
          value={formData.state_Country_ID?.toString() || ''}
          onChange={(e) => handleChange(e, setFormData)}
          options={countries.map((country) => ({ value: (country.country_ID ?? '').toString(), label: country.country_Description ?? '' }))}
          placeholder="Select a country"
          disabled={countriesLoading || !!countriesError}
        />
      </div>
      <div className="flex justify-end space-x-4">
        <Button variant="secondary" onClick={onCancel}>Cancel</Button>
        <Button type="submit">{isCreating ? 'Create' : 'Save'}</Button>
      </div>
    </form>
  );
};

export default StateForm;