import React, { useEffect, useState } from "react";
import { CourseClient, LegacyCourseClient, LegacyCourseDTO } from "../../../services/apiClient";
import { useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL } from "../../../config/config";
import useCountries from "../../../hooks/useCountries";
import AdminLayout from "../../../components/layout/AdminLayout";
import Card from "../../../components/common/Card";
import CourseForm from "../../../components/forms/CourseForm";

const CoursesEdit: React.FC = () => {
    const [course, setCourse] = useState<LegacyCourseDTO | null>(null);
    const { id } = useParams<{ id: string }>(); const navigate = useNavigate();
    const apiClient = new LegacyCourseClient(API_BASE_URL);
    const courseClient = new CourseClient(API_BASE_URL)

    const [logoImage, setLogoImage] = useState<File | null>(null);
    const [headerImage, setHeaderImage] = useState<File | null>(null);

    const { countries } = useCountries();

    const breadcrumbs = [
        { label: 'Admin', href: '#' },
        { label: 'Courses', href: '/admin/courses' },
        { label: 'Edit', href: '#' },
    ];

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const response = await apiClient.getLegacyCourseById(Number(id!));
                setCourse(response.data as LegacyCourseDTO);
            } catch (error) {
                console.error('Error fetching course:', error);
            }
        };
        fetchCourse();
    }, [id]);

    const handleSubmit = async (data: LegacyCourseDTO) => {
        try {
            if (logoImage) {
                // Call the method to upload the logo image
                const imageUplaodResponse = await courseClient.uploadCourseLogo(
                    logoImage.name,
                    logoImage,
                );

                if (imageUplaodResponse && imageUplaodResponse.success) {
                    data.course_Logo = imageUplaodResponse.data;
                }
            }

            if (headerImage) {
                // Call the method to upload the header image
                const imageUplaodResponse = await courseClient.uploadCourseHeader(
                    headerImage.name,
                    headerImage,
                );

                if (imageUplaodResponse && imageUplaodResponse.success) {
                    data.course_Header = imageUplaodResponse.data;
                }
            }

            await apiClient.updateLegacyCourse(
                data.course_ID || 0,
                data,
            );
        } catch (error) {
            console.error('Error saving:', error);
        } finally {
            navigate('/admin/courses')
        }
    };

    return (
        <AdminLayout
            title="Edit State"
            breadcrumbs={breadcrumbs}
            allowedRoles={['Admin']}
        >
            <Card>
                {course && (
                    <CourseForm
                        initialValues={course}
                        onSubmit={handleSubmit}
                        onCancel={() => navigate('/admin/courses')}
                        isCreating={false}
                        countries={countries}
                        countriesLoading={false}
                        countriesError={null}
                    />
                )}
            </Card>
        </AdminLayout>
    );
};

export default CoursesEdit;