import React, { useState} from 'react';
import {UserCreateDTO, UserEditDTO} from '../../services/apiClient';
import FormField from '../../components/common/FormField';
import Button from '../../components/common/Button';
import PasswordRequirements from '../common/PasswordRequirements';
import FileUpload from '../common/FileUpload';

interface UserFormProps {
    initialValues: UserCreateDTO | UserEditDTO;
    onSave: (user: UserCreateDTO | UserEditDTO) => void;
    onCancel: () => void;
    isCreating: boolean;
}

const UserForm: React.FC<UserFormProps> = ({
                                               initialValues,
                                               onSave,
                                               onCancel,
                                               isCreating,
                                           }) => {
    const [formData, setFormData] = useState(initialValues);
    const [profilePicture, setProfilePicture] = useState<File | null>(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    // Password validation states
    const [passwordValidation, setPasswordValidation] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        digit: false,
    });


    const validatePassword = (password: string) => {
        setPasswordValidation({
            length: password.length >= 6,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            digit: /\d/.test(password),
        });
    };

    const validateForm = () => {
        const formErrors: { [key: string]: string } = {};

        if (!formData.firstName?.trim())
            formErrors.firstName = 'First name is required.';
        if (!formData.lastName?.trim())
            formErrors.lastName = 'Last name is required.';

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email || !emailRegex.test(formData.email)) {
            formErrors.email = 'Valid email is required.';
        }

        if (isCreating) {
            // Password validation
            if (!password.trim()) {
                formErrors.password = 'Password is required.';
            } else {
                if (!passwordValidation.length) {
                    formErrors.password = 'Password must be at least 6 characters.';
                }
                if (!passwordValidation.uppercase) {
                    formErrors.password = 'Password must contain at least one uppercase letter.';
                }
                if (!passwordValidation.lowercase) {
                    formErrors.password = 'Password must contain at least one lowercase letter.';
                }
                if (!passwordValidation.digit) {
                    formErrors.password = 'Password must contain at least one digit.';
                }
            }

            if (password !== confirmPassword) {
                formErrors.confirmPassword = 'Passwords do not match.';
            }
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleInputChange =
        (key: keyof UserCreateDTO | keyof UserEditDTO) => (value: string) => {
            setFormData({
                ...formData,
                [key]: value,
            });
        };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setProfilePicture(e.target.files[0]);
        }
    };

    const handlePasswordChange = (value: string) => {
        setPassword(value);
        validatePassword(value);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        const userPayload: UserCreateDTO | UserEditDTO = {
            ...formData,
            ...(isCreating && { password }), // Include password only if creating
        };

        onSave(userPayload);
    };

    return (
        <form onSubmit={handleSubmit} className="mb-4">
            {/* Profile Picture Section */}
            {formData.profilePicture && (
                <div className="flex items-center mt-4 mb-4">
                    <img
                        src={formData.profilePicture}
                        alt="Profile"
                        className="rounded-full"
                        style={{ width: '80px', height: '80px', objectFit: 'cover' }}
                    />
                </div>
            )}

            <div>
                <FileUpload
                    id="profilePicture"
                    placeholderText="Upload or drag and drop profile picture"
                    onChange={handleFileChange}
                />
            </div>

            {/* Information Section */}
            <h2 className="text-lg font-bold text-gray-700 dark:text-gray-300 mb-4">
                Information
            </h2>
            <hr />
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 mb-4">
                <FormField
                    id="firstName"
                    label="First Name"
                    value={formData.firstName || ''}
                    onChange={(e) => handleInputChange('firstName')(e.target.value)}
                    placeholder="Enter first name"
                    error={errors.firstName}
                />
                <FormField
                    id="lastName"
                    label="Last Name"
                    value={formData.lastName || ''}
                    onChange={(e) => handleInputChange('lastName')(e.target.value)}
                    placeholder="Enter last name"
                    error={errors.lastName}
                />
                <FormField
                    id="email"
                    label="Email"
                    value={formData.email || ''}
                    onChange={(e) => handleInputChange('email')(e.target.value)}
                    placeholder="Enter email"
                    error={errors.email}
                />
                
            </div>

            {/* Credentials Section */}
            {isCreating && (
                <>
                    <h2 className="md font-bold text-gray-700 dark:text-gray-300 mb-4">
                        Credentials
                    </h2>
                    <hr />
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 mb-4">
                        <div>
                            <FormField
                                id="password"
                                label="Password"
                                type="password"
                                value={password}
                                onChange={(e) => handlePasswordChange(e.target.value)}
                                placeholder="Enter password"
                                error={errors.password}
                            />
                            <PasswordRequirements validation={passwordValidation} />
                        </div>
                        <FormField
                            id="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Confirm password"
                            error={errors.confirmPassword}
                        />
                    </div>
                </>
            )}

            <div className="flex justify-end space-x-4">
                <Button variant="secondary" onClick={onCancel}>
                    Cancel
                </Button>
                <Button type="submit">{isCreating ? 'Create' : 'Save'}</Button>
            </div>
        </form>
    );
};

export default UserForm;
