import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/layout/AdminLayout';
import UserForm from '../../../components/forms/UserForm';
import { UserClient, UserEditDTO } from '../../../services/apiClient';
import { useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL } from '../../../config/config';
import Card from '../../../components/common/Card';

const UserEdit: React.FC = () => {
  const [user, setUser] = useState<UserEditDTO | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const apiClient = new UserClient(API_BASE_URL);

  const breadcrumbs = [
    { label: 'Admin', href: '#' },
    { label: 'Users', href: '/admin/users' },
    { label: 'Edit', href: '#' },
  ];

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await apiClient.getUserById(id!);
        setUser(response.data as UserEditDTO);
      } catch (error) {
        console.error('Error fetching user:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, [id]);

  const handleSubmit = async (data: UserEditDTO) => {
    setIsSubmitting(true);
    try {
      await apiClient.updateUser(id!, data);
      navigate('/admin/users');
    } catch (error) {
      console.error('Error updating user:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AdminLayout
      title="Edit User"
      breadcrumbs={breadcrumbs}
      allowedRoles={['Admin']}
    >
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <Card>
          <UserForm
            initialValues={user!}
            onSave={handleSubmit}
            onCancel={() => navigate('/admin/users')}
            isCreating={false}
          />
        </Card>
      )}
    </AdminLayout>
  );
};

export default UserEdit;
