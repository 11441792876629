import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faShoppingCart,
  faCheckCircle,
  faTimesCircle,
  faChartPie
} from '@fortawesome/free-solid-svg-icons';
import AdminLayout from '../../components/layout/AdminLayout';
import MetricCard from '../../components/cards/MetricCard';

const Dashboard: React.FC = () => {
  const breadcrumbs = [
    { label: 'Admin', href: '#' },
    { label: 'Dashboard', href: '#' },
  ];

  return (
    <AdminLayout title="Dashboard" breadcrumbs={breadcrumbs} allowedRoles={["Admin"]}>
      {/* Metric Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        <MetricCard
          title="Sessions"
          value="6,132"
          icon={
            <FontAwesomeIcon
              icon={faChartPie}
              className="text-purple-300 text-4xl"
            />
          }
          changePercentage="150%"
          changeColor="text-green-500"
          description="vs Previous 30 Days"
        />
        <MetricCard
          title="Today Orders"
          value="536"
          icon={
            <FontAwesomeIcon
              icon={faShoppingCart}
              className="text-orange-300 text-4xl"
            />
          }
          changePercentage="50%"
          changeColor="text-green-500"
          description="vs Previous 30 Days"
        />
        <MetricCard
          title="Completed Orders"
          value="234"
          icon={
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="text-green-300 text-4xl"
            />
          }
          changePercentage="10%"
          changeColor="text-green-500"
          description="vs Previous 30 Days"
        />
        <MetricCard
          title="Pending Orders"
          value="332"
          icon={
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="text-red-300 text-4xl"
            />
          }
          changePercentage="5%"
          changeColor="text-red-500"
          description="vs Previous 30 Days"
        />
      </div>

      {/* Placeholder for Charts and Data */}
      <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
        <h2 className="text-gray-600 text-xl font-bold mb-4">Average Revenue</h2>
        {/* Chart Component Goes Here */}
        <p>[Chart Placeholder]</p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Top Selling Products */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-gray-600 text-xl font-bold mb-4">Top Selling Products</h2>
          {/* Table Component */}
          <p>[Table Placeholder]</p>
        </div>

        {/* Top Customers */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-gray-600 text-xl font-bold mb-4">Top Customers</h2>
          {/* Table or Chart Component */}
          <p>[Table/Chart Placeholder]</p>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Dashboard;
