import {jwtDecode} from 'jwt-decode';

interface JwtPayload {
  sub: string;
  jti: string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier": string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress": string;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": string;
  exp: number;
  iss: string;
  aud: string;
  ProfilePicture: string;
  MemberId: string;
}

const getToken = (): string | null => {
  return localStorage.getItem('token');
};

const decodeToken = (): JwtPayload | null => {
  const token = getToken();
  if (token) {
    return jwtDecode<JwtPayload>(token);
  }
  return null;
};

export const getUserName = (): string | null => {
  const decodedToken = decodeToken();
  return decodedToken
    ? decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"]
    : null;
};

export const getUserEmail = (): string | null => {
  const decodedToken = decodeToken();
  return decodedToken
    ? decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"]
    : null;
};

export const getUserId = (): string | null => {
  const decodedToken = decodeToken();
  return decodedToken
    ? decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"]
    : null;
};

export const getProfilePicture = (): string | null => {
  // First, try to get the profile picture from localStorage
  const profilePicture = localStorage.getItem('profilePicture');
  
  // If found in localStorage, return it
  if (profilePicture) {
    return profilePicture;
  }

  // Otherwise, decode the token and get the profile picture from claims
  const decodedToken = decodeToken();
  if (decodedToken && decodedToken.ProfilePicture) {
    // Store the profile picture in localStorage for future use
    localStorage.setItem('profilePicture', decodedToken.ProfilePicture);
    
    // Return the profile picture from the token
    return decodedToken.ProfilePicture;
  }

  // If nothing found, return null
  return null;
};

export const getMemberId = (): string | null => {
  // First, try to get the profile picture from localStorage
  const memberId = localStorage.getItem('memberId');
  
  // If found in localStorage, return it
  if (memberId) {
    return memberId;
  }

  // Otherwise, decode the token and get the profile picture from claims
  const decodedToken = decodeToken();
  if (decodedToken && decodedToken.MemberId) {
    // Store the profile picture in localStorage for future use
    localStorage.setItem('memberId', decodedToken.MemberId);
    
    // Return the profile picture from the token
    return decodedToken.MemberId;
  }

  // If nothing found, return null
  return null;
};


export const getRoles = (): string[] | null => {
  const decodedToken = decodeToken();

  if (decodedToken) {
    // Check if roles exist in the token
    const roles = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

    // If roles is a string, convert to an array, otherwise return the array (or null if undefined)
    return Array.isArray(roles) ? roles : [roles];
  }

  return null;
};

