import React from "react";
import AdminLayout from '../../../components/layout/AdminLayout';
import { useNavigate } from 'react-router-dom';
import { LegacyCourseClient, LegacyCourseDTO } from '../../../services/apiClient';
import { API_BASE_URL } from '../../../config/config';
import Card from '../../../components/common/Card';
import useCountries from '../../../hooks/useCountries';
import CourseForm from "../../../components/forms/CourseForm";


const CoursesCreate: React.FC = () => {
    const navigate = useNavigate();
    const apiClient = new LegacyCourseClient(API_BASE_URL);

    const breadcrumbs = [
        { label: 'Admin', href: '#' },
        { label: 'Courses', href: '#' },
        { label: 'Create', href: '#' },
    ];

    const { countries } = useCountries();

    const handleSubmit = async (data: LegacyCourseDTO) => {
        try {
            await apiClient.createLegacyCourse(data);
            navigate('/admin/courses');
        } catch (error) {
            console.error('Error creating course:', error);
        }
    };

    return (
        <AdminLayout title="Create Course" breadcrumbs={breadcrumbs} allowedRoles={['Admin']} >
            <Card>
                <CourseForm
                    initialValues={{} as LegacyCourseDTO}
                    onSubmit={handleSubmit}
                    onCancel={() => navigate('/admin/courses')}
                    isCreating={true}
                    countries={countries}
                    countriesLoading={false}
                    countriesError={null}
                />
            </Card>
        </AdminLayout>
    );
};

export default CoursesCreate;