import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProfileMenu: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove token from localStorage (or any other storage you're using)
    localStorage.removeItem('token');

    // Navigate to login page
    navigate('/login');
  };

  return (
    <div className="absolute right-0 mt-2 w-48 bg-white border rounded-lg shadow-lg py-1 z-50">
      <a
        href="#"
        className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
        onClick={handleLogout}
      >
        Log Out
      </a>
    </div>
  );
};

export default ProfileMenu;
