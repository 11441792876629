import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserClient, LoginUserDTO } from '../../services/apiClient';
import AuthLayout from '../../components/layout/AuthLayout';
import FormField from '../../components/common/FormField';
import Button from '../../components/common/Button';
import { API_BASE_URL } from '../../config/config';

const Login: React.FC = () => {
  const [loading, setLoaing] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const from =
    (location.state as { from?: Location })?.from?.pathname ||
    '/admin/dashboard';

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const loginData: LoginUserDTO = { email, password };

    try {
      setLoaing(true);
      const apiClient = new UserClient(API_BASE_URL);
      const response = await apiClient.loginUser(loginData);

      if (response && response.success && response.data) {
        localStorage.setItem('token', response.data);
        navigate(from);
      }
    } catch (error) {
      setErrorMessage('Invalid credentials. Please try again.');
    }
    finally{
      setLoaing(false);
    }
  };

  const handleExploreClick = () => {
    navigate('/explore/map');
  };

  return (
    <AuthLayout
      title="How many courses have you played?"
      description="With over 30 years experience and an expansive network we have developed our GolfPlayed app that helps you to keep track of all the courses you have ever played. "
    >
      <div className="mt-8">
        {errorMessage && (
          <div className="mb-4 text-sm text-red-600">{errorMessage}</div>
        )}
        <form onSubmit={handleSubmit}>
          <FormField
            id="email"
            label="Email Address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="example@example.com"
          />
          <div className="mt-6">
            <FormField
              id="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Your Password"
            />
          </div>
          <div className="mt-6">
            <Button type="submit" className="w-full" disabled={loading} isLoading={loading}>
              Sign In
            </Button>
          </div>
          <div className="mt-6">
            <Button type="button" variant="secondary" className="w-full" onClick={handleExploreClick}>
              Explore
            </Button>
          </div>
        </form>
        <p className="mt-6 text-sm text-center text-gray-400">
          Forgot your password?{' '}
          <Link
            to={'/forgot-password'}
            className="text-black focus:outline-none focus:underline hover:underline"
          >
            Reset it now
          </Link>
        </p>
      </div>
    </AuthLayout>
  );
};

export default Login;
