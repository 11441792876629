import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faHandshake,
  faGolfBallTee,
  faBucket,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import '../../styles/cards.css';
import { PUBLIC_CONTAINER_URL } from '../../config/config';
import { useState } from "react";
import NearestCourse from "../../interfaces/NearestCourse";

interface CourseListCardProps extends NearestCourse {
  isLoggedIn: boolean;
  isPlayedStatusLoading: boolean;
  coursePlayedStatus: boolean;
  setIsLoggedIn: (loggedIn: boolean) => void;
  showLogin: boolean;
  setShowLogin: (showLogin: boolean) => void;
  onCoursePlayedClick: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | null) => void;
  onRequestToPlayClick: () => void;
  onBucketListClick: () => void;
  onTeeTimesClick: (course: NearestCourse) => void;
}

const CourseListCard: React.FC<CourseListCardProps> = ({
  id,
  name,
  country,
  state,
  address,
  distance,
  headerImagePath,
  logoImagePath,
  isLoggedIn,
  isPlayedStatusLoading,
  coursePlayedStatus,
  setShowLogin,

  onCoursePlayedClick,
  onRequestToPlayClick,
  onBucketListClick,
  onTeeTimesClick,
  
}) => {

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className={`bg-[#202020] p-4 relative flex flex-col items-center ${isExpanded ? 'border-2 border-[#bcab64]' : ''}`}
      onClick={toggleExpanded}
      style={{ cursor: 'pointer', boxShadow: '0 3px 8px rgba(0, 0, 0, 1)', borderRadius: '3px' }}>

      {/* Top Section */}
      <div className="flex items-center w-full space-x-4">
        {/* Logo */}
        <div
          className="w-14 h-14 rounded-full border-2"
          style={{ borderColor: "#bcab64" }}>
          <img
            src={
              logoImagePath
                ? `${PUBLIC_CONTAINER_URL}/${logoImagePath}`
                : `${PUBLIC_CONTAINER_URL}/course-logos/fallback.png`
            }
            alt={`${name} logo`}
            className="w-full h-full object-cover rounded-full"
          />
        </div>

        {/* Course Details */}
        <div className="text-left">
          {/* Course */}
          <p className="text-white font-bold text-sm">{name}</p>
          {/* Province/State */} {/* Country */} {/* Distance */}
          <p className="text-white text-xs">{state}, {country}, {distance}km</p>
        </div>

      </div>

      {/* Conditionally Render Action Buttons */}
      {isExpanded && (
        <div className="w-full mt-4 flex justify-around border-t pt-2">

          {/* TCOURSE PLAYED*/}
          { isPlayedStatusLoading ? 
            <span className="spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full border-t-customPrimary ml-2"></span>
          :
            <button
              onClick={(e) => {
                e.stopPropagation();
                isLoggedIn ? onCoursePlayedClick(null) : setShowLogin(true);
              }}
              className="card-icon-button"
            >
              {
                coursePlayedStatus ? 
                <FontAwesomeIcon icon={faCheck} />
                :
                <FontAwesomeIcon icon={faPlus} />
              }
              
            </button>
          }
          {/* REQUEST TO PLAY*/}
          <button
            onClick={(e) => {
              e.stopPropagation();
              onRequestToPlayClick();
            }}
            className="card-icon-button"
          >
            <FontAwesomeIcon icon={faHandshake} />
          </button>

          {/* BUCKET LIST */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              onBucketListClick();
            }}
            className="card-icon-button"
          >
            <FontAwesomeIcon icon={faBucket} />
          </button>

          {/* TEE TIME*/}
          <button
            onClick={(e) => {
              e.stopPropagation();
              onTeeTimesClick({
                id,
                name,
                state,
                country,
                distance,
                headerImagePath,
                logoImagePath
              });
            }}
            className="card-icon-button"
          >
            <FontAwesomeIcon icon={faGolfBallTee} />
          </button>
        </div>
      )}
    </div>
  );
};

export default CourseListCard;
