import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

interface CollapsiblePanelProps {
  title: string;
  subHeader?: string; // Optional sub-header
  collapsed?: boolean
  children: React.ReactNode;
}

const CollapsiblePanel: React.FC<CollapsiblePanelProps> = ({
  title,
  subHeader,
  children,
  collapsed = false
}) => {
  const [isOpen, setIsOpen] = useState(!collapsed);

  return (
    <div className="mb-6">
      {/* Panel Header */}
      <div
        className="flex justify-between items-center cursor-pointer mb-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>
          <h3 className="text-lg font-semibold text-gray-600">{title}</h3>
          {subHeader && <p className="text-sm text-gray-500">{subHeader}</p>}
          
        </div>
        <div className="bg-gray-200 text-black p-2 rounded-full transition-transform">
          <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
        </div>
      </div>
      <hr />
      {/* Animated Panel Content */}
      <div
        className={`overflow-hidden transition-all duration-500 ease-in-out transform ${
          isOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <div className="mt-4">{children}</div>
      </div>
    </div>
  );
};

export default CollapsiblePanel;
