import React, { useState, useEffect } from "react";
import AdminLayout from "../../../components/layout/AdminLayout";
import Card from '../../../components/common/Card';
import MemberForm from "../../../components/forms/MemberForm";
import useCountries from '../../../hooks/useCountries';
import {
    LegacyUserClient,
    LegacyUserDTO,
    CoursesPlayedClient,
    HomeCourseClient,
    MemberCoursesPlayedDTO,
    MemberHomeCoursesDTO
} from '../../../services/apiClient';
import { API_BASE_URL } from '../../../config/config';
import { useNavigate, useParams } from 'react-router-dom';
import Tabs from "../../../components/common/Tabs";
import Tab from "../../../components/common/Tab";
import CoursesPlayedTable from "../../../components/tables/CoursesPlayedTable";
import HomeCoursesTable from "../../../components/tables/HomeCoursesTable";

const MembersEdit: React.FC = () => {
    const [member, setMember] = useState<LegacyUserDTO | null>(null);
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const apiClient = new LegacyUserClient(API_BASE_URL);

    const { countries } = useCountries();

    const breadcrumbs = [
        { label: 'Admin', href: '#' },
        { label: 'Members', href: '/admin/members' },
        { label: 'Edit', href: '#' },
    ];

    const [coursePlayedData, setCoursePlayedData] = useState<MemberCoursesPlayedDTO[]>([]);
    const [coursePlayedCurrentPage, setCoursePlayedCurrentPage] = useState(1);
    const [coursePlayedPageSize, setCoursePlayedPageSize] = useState(10);
    const [totalCoursePlayedPages, setTotalCoursePlayedPages] = useState(1);
    const [totalCoursePlayedRecords, setTotalCoursePlayedRecords] = useState(0);
    const [coursePlayedIsLoading, setCoursePlayedIsLoading] = useState(false);

    const [homeCourseData, setHomeCourseData] = useState<MemberHomeCoursesDTO[]>([]);
    const [homeCourseCurrentPage, setHomeCourseCurrentPage] = useState(1);
    const [homeCoursePageSize, setHomeCoursePageSize] = useState(10);
    const [totalHomeCoursePages, setTotalHomeCoursePages] = useState(1);
    const [totalHomeCourseRecords, setTotalHomeCourseRecords] = useState(0);
    const [homeCourseIsLoading, setHomeCourseIsLoading] = useState(false);

    const memberCoursesPlayedClient = new CoursesPlayedClient(API_BASE_URL);
    const memberHomeCoursesClient = new HomeCourseClient(API_BASE_URL);

    useEffect(() => {
        const fetchMember = async () => {
            try {
                const response = await apiClient.getLegacyUserById(Number(id!));
                setMember(response.data as LegacyUserDTO);
            } catch (error) {
                console.error('Error fetching member:', error);
            }
        };
        fetchMember();
    }, [id]);

    useEffect(() => {
        if (member?.user_ID) {
            searchCoursesPlayed(coursePlayedCurrentPage, coursePlayedPageSize, member.user_ID);
        }
    }, [coursePlayedCurrentPage, coursePlayedPageSize, member?.user_ID]);
    
    useEffect(() => {
        if (member?.user_ID) {
            searchHomeCourses(homeCourseCurrentPage, homeCoursePageSize, member.user_ID);
        }
    }, [homeCourseCurrentPage, homeCoursePageSize, member?.user_ID]);

    const searchCoursesPlayed = async (page: number, size: number, memberId: number) => {
        try {
            setCoursePlayedIsLoading(true);
            const coursesPlayedResponse = await memberCoursesPlayedClient.searchCoursesPlayed({
                filters: {
                    legacyMemberId: memberId
                },
                page,
                pageSize: size,
                sortBy: 'CourseName',
                sortDescending: false,
            });
            console.table(coursesPlayedResponse.data?.results);
            setCoursePlayedData((coursesPlayedResponse.data?.results as MemberCoursesPlayedDTO[]) || []);
            setTotalCoursePlayedRecords(coursesPlayedResponse.data?.totalCount || 0);
            setTotalCoursePlayedPages(Math.ceil((coursesPlayedResponse.data?.totalCount || 0) / size));
        } catch (error) {
            console.error('Error searching Courses Played:', error);
        } finally {
            setCoursePlayedIsLoading(false);
        }
    }

    const searchHomeCourses = async (page: number, size: number, memberId: number) => {
        try {
            setHomeCourseIsLoading(true);
            const homeCoursesResponse = await memberHomeCoursesClient.searchHomeCourses({
                filters: {
                    legacyMemberId: memberId
                },
                page,
                pageSize: size,
                sortBy: 'CourseName',
                sortDescending: false,
            });
            console.table(homeCoursesResponse.data?.results);
            setHomeCourseData((homeCoursesResponse.data?.results as MemberHomeCoursesDTO[]) || []);
            setTotalHomeCourseRecords(homeCoursesResponse.data?.totalCount || 0);
            setTotalHomeCoursePages(Math.ceil((homeCoursesResponse.data?.totalCount || 0) / size));
        } catch (error) {
            console.error('Error searching Home Courses:', error);
        } finally {
            setHomeCourseIsLoading(false);
        }
    }

    const handleSubmit = async (data: LegacyUserDTO) => {
        try {
            await apiClient.updateLegacyUser(Number(id!), data);
            navigate('/admin/members');
        } catch (error) {
            console.error('Error updating member:', error);
        }
    };

    return (
        <AdminLayout
            title="Edit Member"
            breadcrumbs={breadcrumbs}
            allowedRoles={['Admin']}
        >
            <Card>
                {member && (
                    <Tabs>
                        <Tab title="Member">
                            <MemberForm
                                initialValues={member}
                                onSubmit={handleSubmit}
                                onCancel={() => navigate('/admin/members')}
                                isCreating={false}
                                countries={countries}
                                countriesLoading={false}
                                countriesError={null}
                            />
                        </Tab>
                        <Tab title="Courses Played">
                            <CoursesPlayedTable
                                courses={coursePlayedData}
                                currentPage={coursePlayedCurrentPage}
                                totalPages={totalCoursePlayedPages}
                                onPageChange={setCoursePlayedCurrentPage}
                                pageSize={coursePlayedPageSize}
                                onPageSizeChange={setCoursePlayedPageSize}
                                isLoading={coursePlayedIsLoading}
                            />
                        </Tab>
                        <Tab title="Home Course">
                            <HomeCoursesTable
                                courses={homeCourseData}
                                currentPage={homeCourseCurrentPage}
                                totalPages={totalHomeCoursePages}
                                onPageChange={setHomeCourseCurrentPage}
                                pageSize={homeCoursePageSize}
                                onPageSizeChange={setHomeCoursePageSize}
                                isLoading={homeCourseIsLoading}
                            />
                        </Tab>
                    </Tabs>

                )}
            </Card>
        </AdminLayout>
    );
};

export default MembersEdit;