import Swal from 'sweetalert2';

/**
 * Show a simple alert with SweetAlert2.
 * @param options The configuration options for the alert dialog.
 * @returns A promise that resolves once the alert is closed.
 */
export const showAlert = async (options: {
    title: string;
    text?: string;
    icon?: 'success' | 'error' | 'warning' | 'info' | 'question';
    confirmButtonText?: string;
  }): Promise<void> => {
    const {
      title,
      text = '',
      icon = 'info',
      confirmButtonText = 'OK',
    } = options;
  
    await Swal.fire({
      icon,
      title,
      text,
      confirmButtonText,
      customClass: {
        confirmButton: 'custom-confirm-btn',
      },
    });
  };

/**
 * Confirm a delete action with SweetAlert2.
 * @param message The confirmation message to display.
 * @returns A promise that resolves to `true` if confirmed, `false` otherwise.
 */
export const confirmDelete = async (message: string = 'Are you sure you want to delete this record?'): Promise<boolean> => {
  const result = await Swal.fire({
    icon: 'warning',
    title: 'Confirm Deletion',
    text: message,
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it',
    cancelButtonText: 'No, cancel',
    reverseButtons: true,
    customClass: {
        confirmButton: 'custom-confirm-btn',
      },
  });

  return result.isConfirmed;
};

/**
 * Confirm an action with customizable SweetAlert2 options.
 * @param options The configuration options for the confirmation dialog.
 * @returns A promise that resolves to `true` if confirmed, `false` otherwise.
 */
export const confirmAction = async (options: {
  title?: string;
  text?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  icon?: 'warning' | 'error' | 'success' | 'info' | 'question';
} = {}): Promise<boolean> => {
  const {
    title = 'Are you sure?',
    text = 'Do you want to proceed?',
    confirmButtonText = 'Yes, proceed',
    cancelButtonText = 'No, cancel',
    icon = 'warning',
  } = options;

  const result = await Swal.fire({
    icon,
    title,
    text,
    showCancelButton: true,
    confirmButtonText,
    cancelButtonText,
    reverseButtons: true,
    customClass: {
        confirmButton: 'custom-confirm-btn',
      },
  });

  return result.isConfirmed;
};
