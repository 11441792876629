import React, { useState } from 'react';
import Button from '../../components/common/Button';
import FormField from '../../components/common/FormField';
import { LegacySubRegionDTO, LegacyCountryDTO } from '../../services/apiClient';
import useStates from '../../hooks/useStates';
import handleChange from '../../utils/formUtils/handleChange';

interface SubRegionFormProps {
  initialValues: LegacySubRegionDTO
  onSubmit: (data: LegacySubRegionDTO) => void;
  onCancel: () => void;
  countries: LegacyCountryDTO[];
  countriesLoading: boolean;
  countriesError: string | null;
  isCreating: boolean;
}

const SubRegionForm: React.FC<SubRegionFormProps> = ({
  initialValues,
  onSubmit,
  onCancel,
  countries,
  countriesLoading,
  countriesError,
  isCreating
}) => {
  const [formData, setFormData] = useState(initialValues);
  const { states } = useStates(formData.sub_Region_Country_ID || null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <FormField
          id="sub_Region_Name"
          label="SubRegion Name"
          type="text"
          value={formData.sub_Region_Name}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter sub-region name"
        />

        <FormField
          id="sub_Region_Code"
          label="SubRegion Code"
          type="text"
          value={formData.sub_Region_Code}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter sub-region code"
        />

        {/* Country Dropdown */}
        <FormField
          id="sub_Region_Country_ID"
          label="Country"
          type="dropdown"
          value={formData.sub_Region_Country_ID || ''}
          onChange={(e) => handleChange(e, setFormData)}
          options={countries.map((country) => ({ value: (country.country_ID ?? '').toString(), label: country.country_Description ?? '' }))}
          placeholder="Select a country"
          disabled={countriesLoading || !!countriesError}
        />

        {/* State Dropdown */}
        <FormField
          id="sub_Region_State_ID"
          label="State"
          type="dropdown"
          value={formData.sub_Region_State_ID || ''}
          onChange={(e) => handleChange(e, setFormData)}
          options={states.map((state) => ({ value: (state.state_ID ?? '').toString(), label: state.state_Description ?? '' }))}
          disabled={!formData.sub_Region_Country_ID}
        />
      </div>
      <div className="flex justify-end space-x-4">
        <Button variant="secondary" onClick={onCancel}>Cancel</Button>
        <Button type="submit">{isCreating ? 'Create' : 'Save'}</Button>
      </div>
    </form>
  );
};

export default SubRegionForm;
