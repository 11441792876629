interface FriendCardProps {
  onClick?: (data: { name: string; avatar?: string }) => void;
  name: string;
  surname: string;
  avatar?: string;
  matchesInCommon?: number;
}

const FriendCard: React.FC<FriendCardProps> = ({
  onClick,
  name,
  surname,
  avatar,
  matchesInCommon = 0,
}) => {
  // This is the data we’ll pass back when the card is clicked
  const cardData = { name, avatar };

  return (

    <div className="pl-2 pr-2">
      <div
        onClick={() => onClick?.(cardData)}
        className="flex items-center p-4 relative cursor-pointer"
        style={{
          backgroundColor: '#202020',
          boxShadow: '0 3px 8px rgba(0, 0, 0, 1)',
        }}
      >
        {/* Avatar */}
        <img
          src={avatar || '/images/user.png'}
          alt={name}
          className="w-10 h-10 rounded-full border-2 border-[#bcab64] mr-3 object-cover"
        />
        {/* Name and subtext */}
        <div>
          <h4 className="text-white font-semibold">{name} {surname}</h4>
          <p className="text-white text-xs">
            {matchesInCommon} matche(s) in common
          </p>
        </div>
      </div>
    </div>


  );
};

export default FriendCard;
