import React from 'react';
import AdminLayout from '../../../components/layout/AdminLayout';
import StateForm from '../../../components/forms/StateForm';
import { LegacyStateClient, LegacyStateDTO } from '../../../services/apiClient';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../../config/config';
import Card from '../../../components/common/Card';
import useCountries from '../../../hooks/useCountries';

const StateCreate: React.FC = () => {
  const navigate = useNavigate();
  const apiClient = new LegacyStateClient(API_BASE_URL);

  const breadcrumbs = [
    { label: 'Admin', href: '#' },
    { label: 'States', href: '#' },
  ];

  const { countries } = useCountries();

  const handleSubmit = async (data: LegacyStateDTO) => {
    try {
      await apiClient.createLegacyState(data);
      navigate('/admin/states');
    } catch (error) {
      console.error('Error creating state:', error);
    }
  };

  return (
    <AdminLayout title="Create State" breadcrumbs={breadcrumbs} allowedRoles={['Admin']} >
      <Card>
        <StateForm
          initialValues={{} as LegacyStateDTO}
          onSave={handleSubmit}
          onCancel={() => navigate('/admin/states')}
          isCreating={true}
          countries={countries} 
          countriesLoading={false} 
          countriesError={null} 
        />
      </Card>
    </AdminLayout>
  );
};

export default StateCreate;
