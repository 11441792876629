import React from "react";
import NearestCourse from '../../interfaces/NearestCourse';

interface CourseDataHandlerProps {
    calculatedCourses: NearestCourse[];
    children: (data: NearestCourse[]) => React.ReactNode;
}

const CourseDataHandler: React.FC<CourseDataHandlerProps> = ({
    calculatedCourses,
    children,
}) => {
    if (!calculatedCourses.length) {
        return <div>No courses available</div>; // Handle empty data
    }
    // Further processing or transformations can be done here if needed
    const sortedCourses = [...calculatedCourses].sort((a, b) => a.distance - b.distance);
    return <>{children(sortedCourses)}</>;
};

export default CourseDataHandler;
