import { useEffect, useState } from 'react';
import { LegacyStateClient, LegacyStateDTO } from '../services/apiClient'; // Adjust the import paths as needed
import { API_BASE_URL } from '../config/config';

const useStates = (countryId: number | null) => {
  const [states, setStates] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const stateClient = new LegacyStateClient(API_BASE_URL);

  useEffect(() => {
    const fetchStates = async () => {
      if (countryId === null) return; // Exit if no country is selected

      setIsLoading(true);
      try {
        const response = await stateClient.getLegacyStatesByCountryId(countryId);
        if (response && response.success && response.data) {
          setStates(response.data);
        } else {
          setError('Failed to fetch states');
        }
      } catch (err) {
        setError('Error fetching states');
        console.error('Error fetching states:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStates();
  }, [countryId]);

  return { states, isLoading, error };
};

export default useStates;
