import React from 'react';
import Table from '../../components/common/Table';
import { LegacyStateDTO } from '../../services/apiClient';

interface StateTableProps {
  data: LegacyStateDTO[];
  onEdit: (state: LegacyStateDTO) => void;
  onDelete: (state: LegacyStateDTO) => void;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  pageSize: number;
  onPageSizeChange: (size: number) => void;
  isLoading: boolean;
}

const StateTable: React.FC<StateTableProps> = ({
  data,
  onEdit,
  onDelete,
  currentPage,
  totalPages,
  onPageChange,
  pageSize,
  onPageSizeChange,
  isLoading,
}) => {
  return (
    <Table
      columns={[
        { label: 'Description', key: 'state_Description' },
        { label: 'Code', key: 'state_Code' },
        { label: 'Country', key: 'state_Country' },
      ]}
      data={data}
      actions={{
        onEdit,
        onDelete,
      }}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={onPageChange}
      pageSize={pageSize}
      onPageSizeChange={onPageSizeChange}
      isLoading={isLoading}
    />
  );
};

export default StateTable;
