import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/layout/AdminLayout';
import MemberTable from '../../../components/tables/MemberTable';
import Button from '../../../components/common/Button';
import AddNewButton from '../../../components/common/AddNewButton';
import Card from '../../../components/common/Card';
import { LegacyUserClient, LegacyUserDTO } from '../../../services/apiClient';
import { useNavigate } from 'react-router-dom';
import FormField from '../../../components/common/FormField';
import { API_BASE_URL } from '../../../config/config';
import useCountries from '../../../hooks/useCountries';
import useStates from '../../../hooks/useStates';

const MembersList: React.FC = () => {
    const [members, setMembers] = useState<LegacyUserDTO[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [firstNameSearch, setFirstNameSearch] = useState<string | undefined>();
    const [lastNameSearch, setLastNameSearch] = useState<string | undefined>();
    const [emailSearch, setEmailSearch] = useState<string | undefined>();
    const [countrySearch, setCountrySearch] = useState<number | undefined>();
    const [stateSearch, setStateSearch] = useState<number | undefined>();
    const [subRegionSearch, setSubRegionSearch] = useState<number | undefined>();

    const navigate = useNavigate();
    const apiClient = new LegacyUserClient(API_BASE_URL);
    const { countries, isLoading: countriesLoading } = useCountries();
    const { states } = useStates(countrySearch || null);
    
    const breadcrumbs = [
        { label: 'Admin', href: '#' },
        { label: 'Members', href: '#' },
    ];

    const search = async (page: number, size: number, filters = {}) => {
        try {
            setIsLoading(true);
            const response = await apiClient.searchLegacyUsers({
                filters: {
                    user_FName: firstNameSearch,
                    user_LName: lastNameSearch,
                    user_Email: emailSearch,
                    user_Country: countrySearch,
                    user_State_ID: stateSearch,
                    sub_Region_ID: subRegionSearch,
                    ...filters,
                },
                page,
                pageSize: size,
                sortBy: 'User_FName',
                sortDescending: false,
            });
            setMembers((response.data?.results as LegacyUserDTO[]) || []);
            setTotalRecords(response.data?.totalCount || 0);
            setTotalPages(Math.ceil((response.data?.totalCount || 0) / size));
        } catch (error) {
            console.error('Error searching:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        search(currentPage, pageSize, {
            firstNameSearch,
            lastNameSearch,
            emailSearch,
            countrySearch,
            stateSearch,
            subRegionSearch,
        });
    }, [currentPage, pageSize]);

    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        setCurrentPage(1);
        search(1, pageSize);
    };

    const handleClearSearch = () => {
        setFirstNameSearch('');
        setLastNameSearch('');
        setEmailSearch('');
        setCountrySearch(undefined);
        setStateSearch(undefined);
        setSubRegionSearch(undefined);
        search(1, pageSize, {
            user_FName: '',
            user_LName: '',
            user_Email: '',
            user_Country: undefined,
            user_State_ID: undefined,
            sub_Region_ID: undefined,
        });
    };

    return (
        <AdminLayout
            title="Members"
            breadcrumbs={breadcrumbs}
            allowedRoles={['Admin']}
        >
            <Card>
                <div className="flex justify-between mb-4">
                    <h2 className="text-lg font-bold text-gray-500">
                        Manage Members ({totalRecords})
                    </h2>
                    <AddNewButton onClick={() => navigate('/admin/members/create')} />
                </div>


                <form onSubmit={handleSearch} className="mb-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                        <FormField
                            id="firstNameSearch"
                            label="First Name"
                            type="text"
                            value={firstNameSearch}
                            onChange={(e) => setFirstNameSearch(e.target.value)}
                        />
                        <FormField
                            id="lastNameSearch"
                            label="Last Name"
                            type="text"
                            value={lastNameSearch}
                            onChange={(e) => setLastNameSearch(e.target.value)}
                        />
                        <FormField
                            id="emailSearch"
                            label="Email"
                            type="text"
                            value={emailSearch}
                            onChange={(e) => setEmailSearch(e.target.value)}
                        />
                        <FormField
                            id="countrySearch"
                            label="Country"
                            type="dropdown"
                            value={countrySearch?.toString() || ''}
                            onChange={(e) =>
                                setCountrySearch(
                                    e.target.value ? parseInt(e.target.value) : undefined,
                                )
                            }
                            options={[
                                { value: '', label: 'Select country to search' },
                                ...countries.map((country) => ({
                                    value: country.country_ID?.toString() || '',
                                    label: country.country_Description || '',
                                })),
                            ]}
                            disabled={countriesLoading}
                        />
                        <FormField
                            id="stateSearch"
                            label="State"
                            type="dropdown"
                            value={stateSearch?.toString() || ''}
                            onChange={(e) =>
                                setStateSearch(
                                    e.target.value ? parseInt(e.target.value) : undefined,
                                )
                            }
                            options={[
                                { value: '', label: 'Select state' },
                                ...states.map((state) => ({
                                    value: state.state_ID?.toString() || '',
                                    label: state.state_Description || '',
                                })),
                            ]}
                            disabled={!countrySearch}
                        />
                    </div>
                    <div className="flex justify-start space-x-4 mb-4">
                        <Button variant="secondary" onClick={handleClearSearch}>
                            Clear Search
                        </Button>
                        <Button type="submit">Search</Button>
                    </div>
                </form>
                <MemberTable
                    members={members}
                    handleEdit={(member) => navigate(`/admin/members/${member.user_ID }`)}
                    handleDelete={(member) => {
                        if (window.confirm('Are you sure you want to delete this member?')) {
                            apiClient
                                .deleteLegacyUser(member.user_ID ?? 0)
                                .then(() =>
                                    setMembers(
                                        members.filter((m) => m.user_ID !== member.user_ID),
                                    ),
                                );
                        }
                    }}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                    isLoading={isLoading}
                />
            </Card>
        </AdminLayout>
    );
};

export default MembersList;
