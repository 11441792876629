import { LayerProps } from 'react-map-gl';

// Cluster Layer (Circle)
export const clusterLayer: LayerProps = {
  id: 'clusters',
  type: 'circle',
  source: 'courses',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': [
      'step',
      ['get', 'point_count'],
      '#202020', // Color for clusters with < 100 points
      100,
      '#666666', // Color for clusters with < 750 points
      750,
      '#C1B06B', // Color for larger clusters
    ],
    'circle-radius': [
      'step',
      ['get', 'point_count'],
      20, // Radius for clusters with < 100 points
      100,
      30, // Radius for clusters with < 750 points
      750,
      40, // Radius for larger clusters
    ],
    'circle-stroke-color': '#C1B06B',
    'circle-stroke-width': 1.5,
  },
};

// Cluster Count Layer (Symbol)
export const clusterCountLayer: LayerProps = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'courses',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 20,
  },
  paint: {
    'text-color': '#FFFFFF',
  },
};

// Unclustered Point Layer (with Label)
export const unclusteredPointLayerWithLabel: LayerProps = {
  id: 'unclustered-point-label',
  type: 'symbol',
  source: 'courses',
  filter: ['!', ['has', 'point_count']],
  layout: {
    'icon-image': 'course-marker', // Marker icon for individual points
    'icon-size': 1,
    'text-field': ['get', 'description'], // Use the 'description' property for the text
    'text-font': ['Arial Unicode MS Bold'],
    'text-size': 10,
    'text-offset': [0, 2.5], // Adjust offset to position the text below the icon
    'text-anchor': 'top', // Anchor text to the top of the icon
  },
  paint: {
    'text-color': '#f3f3f3', // Text color
    'text-halo-color': '#000000', // Halo for better contrast
    'text-halo-width': 0.5,
  },
};
