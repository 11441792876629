import React from 'react';

interface DarkOverlayProps {
  isVisible: boolean;
  closeSidebar: () => void;
}

const DarkOverlay: React.FC<DarkOverlayProps> = ({ isVisible, closeSidebar }) => {
  return isVisible ? (
    <div
      className="fixed inset-0 bg-black opacity-50 z-40 lg:hidden"
      onClick={closeSidebar}
    ></div>
  ) : null;
};

export default DarkOverlay;
