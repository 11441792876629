import React from 'react';

interface AuthLayoutProps {
  children: React.ReactNode;
  title: string;
  description: string;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
  children,
  title,
  description,
}) => {
  return (
    <div className="bg-white">
      <div className="flex justify-center h-screen">
        {/* Background Image */}
        <div
          className="hidden bg-cover lg:block lg:w-2/3"
          style={{
            backgroundImage: 'url(/images/login-image.jpg)',
          }}
        >
          <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
            <div>
              <h2 className="text-4xl font-bold text-white">{title}</h2>
              <p className="max-w-xl mt-3 text-gray-300">{description}</p>
            </div>
          </div>
        </div>
        {/* Form Section */}
        <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
          <div className="flex-1">
            <div className="text-center">
              <img
                src="/images/logo-horizontal-dark.png"
                alt="GolfPlayed Logo"
                className="mx-auto w-auto"
              />
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
