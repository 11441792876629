import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface MenuItemProps {
  icon: any; // The FontAwesome icon
  label: string; // The label for the menu item
  isActive: boolean; // Whether the item is the currently active page
  onClick: () => void; // Function to handle item click
}

const MenuItem: React.FC<MenuItemProps> = ({ icon, label, isActive, onClick }) => {
  return (
    <li>
      <a
        href="#"
        className={`flex items-center space-x-3 p-4 transition-all ${
          isActive
            ? 'bg-gray-200 text-gray-900 shadow-lg rounded-r-none' // Active link without right border-radius
            : 'text-white hover:bg-gray-800 hover:text-white'
        }`}
        onClick={onClick}
      >
        <FontAwesomeIcon
          icon={icon}
          className={`${isActive ? 'text-black' : 'text-white'}`} // Icon color changes on active
        />
        <span>{label}</span>
      </a>
    </li>
  );
};

export default MenuItem;
