import React from 'react';
import { Plus } from 'lucide-react';

const AddNewButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="inline-flex items-center justify-center h-10 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none whitespace-nowrap bg-gray-700 hover:bg-gray-600 focus:bg-gray-700 disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-300 disabled:shadow-none"
    >
      <span className="order-2">Add New</span>
      <span className="relative">
        <Plus
          className="w-5 h-5"
          aria-hidden="true"
          role="graphics-symbol"
          aria-label="Add Icon"
        />
      </span>
    </button>
  );
};

export default AddNewButton;