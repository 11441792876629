import React from 'react';
import Table from '../../components/common/Table';
import { LegacyUserGridDTO } from '../../services/apiClient';

interface MemberTableProps {
  members: LegacyUserGridDTO[];
  handleEdit: (member: LegacyUserGridDTO) => void;
  handleDelete: (member: LegacyUserGridDTO) => void;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void; // Renamed from setCurrentPage
  pageSize: number;
  onPageSizeChange: (size: number) => void; // Renamed from setPageSize
  isLoading: boolean;
}

const MemberTable: React.FC<MemberTableProps> = ({
  members,
  handleEdit,
  handleDelete,
  currentPage,
  totalPages,
  onPageChange,
  pageSize,
  onPageSizeChange,
  isLoading,
}) => {
  return (
    <Table
      columns={[
        { label: 'First Name', key: 'user_FName' },
        { label: 'Last Name', key: 'user_LName' },
        { label: 'Email', key: 'user_Email' },
        { label: 'Country', key: 'user_Country' },
        { label: 'State', key: 'user_State' },
      ]}
      data={members}
      actions={{
        onEdit: handleEdit,
        onDelete: handleDelete,
      }}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={onPageChange}
      pageSize={pageSize}
      onPageSizeChange={onPageSizeChange}
      isLoading={isLoading}
    />
  );
};

export default MemberTable;
