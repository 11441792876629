import React, { useRef } from 'react';
import Map, { Marker, MapRef } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MAP_BOX_KEY } from '../../config/config';
import MapControls from './MapControls';

interface MapWithDraggableMarkerProps {
  latitude: number;
  longitude: number;
  onPositionChange: (lat: number, lng: number) => void;
}

const MapWithDraggableMarker: React.FC<MapWithDraggableMarkerProps> = ({
  latitude,
  longitude,
  onPositionChange,
}) => {
  const mapRef = useRef<MapRef | null>(null);

  const handleDragEnd = (event: any) => {
    const { lng, lat } = event.lngLat; // Get updated coordinates
    onPositionChange(lat, lng);
  };

  const MAPBOX_TOKEN = MAP_BOX_KEY;

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <Map
        initialViewState={{
          latitude,
          longitude,
          zoom: 10,
        }}
        mapboxAccessToken={MAPBOX_TOKEN}
        mapStyle="mapbox://styles/golfplayed/clws4surt011b01qx6qk794sk"
        ref={mapRef}
      >
        <MapControls/>
        <Marker
          latitude={latitude}
          longitude={longitude}
          draggable
          onDragEnd={handleDragEnd} 
        >
          <img
            src="/images/map/course-marker.png"
            alt="Marker"
            style={{ width: '25px' }}
          />
        </Marker>
      </Map>
    </div>
  );
};

export default MapWithDraggableMarker;
