import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/layout/AdminLayout';
import CountryForm from '../../../components/forms/CountryForm';
import { LegacyCountryClient, LegacyCountryDTO } from '../../../services/apiClient';
import { useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL } from '../../../config/config';
import Card from '../../../components/common/Card';

const CountryEdit: React.FC = () => {
  const [country, setCountry] = useState<LegacyCountryDTO | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const apiClient = new LegacyCountryClient(API_BASE_URL);

  const breadcrumbs = [
    { label: 'Admin', href: '#' },
    { label: 'Countries', href: '/admin/countries' },
    { label: 'Edit', href: '#' },
  ];

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await apiClient.getLegacyCountryById(Number(id!));
        setCountry(response.data as LegacyCountryDTO);
      } catch (error) {
        console.error('Error fetching country:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCountry();
  }, [id]);

  const handleSubmit = async (data: LegacyCountryDTO) => {
    setIsSubmitting(true);
    try {
      await apiClient.updateLegacyCountry(Number(id!), data);
      navigate('/admin/countries');
    } catch (error) {
      console.error('Error updating country:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AdminLayout title="Edit Country" breadcrumbs={breadcrumbs} allowedRoles={['Admin']}>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <Card>
          <CountryForm
            initialValues={country!}
            onSave={handleSubmit}
            onCancel={() => navigate('/admin/countries')}
            isCreating={false}
          />
        </Card>
      )}
    </AdminLayout>
  );
};

export default CountryEdit;