import React from 'react';

interface ButtonProps {
  onClick?: () => void;
  type?: 'button' | 'submit';
  children: React.ReactNode;
  className?: string;
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'gold' | 'gold-outline'; // Ensure these match keys in variantClasses
  disabled?: boolean;
  isLoading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  type = 'button',
  children,
  className,
  variant = 'primary',
  disabled = false,
  isLoading = false,
}) => {
  const baseClasses = 'px-6 py-2 transition-colors rounded';
  const variantClasses = {
    primary: 'bg-customPrimary text-white hover:bg-opacity-90',
    secondary:
      'border border-black text-black bg-white hover:bg-black hover:text-white',
    success: 'bg-green-500 text-white hover:bg-green-600',
    danger: 'bg-red-500 text-white hover:bg-red-600',
    gold: 'bg-customSecondary text-black hover:bg-customSecondary',
    "gold-outline": 
      'border border-customSecondary text-customSecondary bg-transparent hover:bg-customSecondary hover:text-black',
  };

  return (
    <button
      type={type}
      onClick={onClick}
      className={`${baseClasses} ${variantClasses[variant]} ${className || ''}`}
      disabled={disabled}
    >
      {isLoading ? (
        <>
          <div className="flex items-center justify-center">
            {children}
            <span className="ml-2 spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full border-t-white"></span>
          </div>
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
