import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faTimes,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL, PUBLIC_CONTAINER_URL } from '../../config/config';
import {
  HomeCourseClient,
  CoursesPlayedClient,
} from '../../services/apiClient';
import Button from '../common/Button';
import { useNavigate } from 'react-router-dom';
import FormField from '../common/FormField';

import NearestCourse from '../../interfaces/NearestCourse';

export interface NearestCoursesRef {
  goToSlide: (slideIndex: number) => void;
  courses: NearestCourse[];
}

interface NearestCoursesProps {
  showCourseDetails: boolean;
  setShowCourseDetails: (value: boolean) => void;
  courses: NearestCourse[];
  selectedCourse: NearestCourse | null; // Controlled by parent
  onCourseSelect: (course: NearestCourse) => void;
  courseplayedClient: CoursesPlayedClient;
  isLoggedIn: boolean;
  isPlayedStatusLoading: boolean;
  coursesPlayed: { [key: string]: boolean; };
  setIsLoggedIn: (loggedIn: boolean) => void;
  showLogin: boolean;
  setShowLogin: (showLogin: boolean) => void;
  updateCoursePlayed: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, course: NearestCourse) => void;
}

const NearestCourses = forwardRef<NearestCoursesRef, NearestCoursesProps>(
  (
    {
      showCourseDetails,
      courses,
      selectedCourse,
      onCourseSelect,
      setShowCourseDetails,
      courseplayedClient,
      isLoggedIn,
      isPlayedStatusLoading,
      coursesPlayed,
      setIsLoggedIn,
      showLogin,
      setShowLogin,
      updateCoursePlayed,
    },
    ref,
  ) => {
    const sliderRef = useRef<Slider>(null);
    const navigate = useNavigate();
    const [memberCount, setMemberCount] = useState(0);
    const [isMemberCountLoading, setIsMemberCountLoading] = useState(false);
    const [peoplePlayedCount, setPeoplePlayedCount] = useState(0);
    const [isPeoplePlayedLoading, setPeoplePlayedLoading] = useState(false);

    const homeCourseClient = new HomeCourseClient(API_BASE_URL);

    const fetchMemberCount = async () => {
      if (selectedCourse) {
        setIsMemberCountLoading(true);
        try {
          const response = await homeCourseClient.getMemberCountByCourseId(
            String(selectedCourse?.id),
          );
          setMemberCount(response.data || 0);
        } catch (error) {
          console.error('Error fetching course member count:', error);
        } finally {
          setIsMemberCountLoading(false);
        }
      }
    };

    const fetchPeoplePlayedCount = async () => {
      if (selectedCourse) {
        setPeoplePlayedLoading(true);
        try {
          const response =
            await courseplayedClient.getPeoplePlayedCountByCourseId(
              selectedCourse?.id.toString() ?? '',
            );
          setPeoplePlayedCount(response.data || 0);
        } catch (error) {
          console.error('Error fetching people played count:', error);
        } finally {
          setPeoplePlayedLoading(false);
        }
      }
    };

    useEffect(() => {
      fetchMemberCount();
      fetchPeoplePlayedCount();
    }, [selectedCourse, isLoggedIn]);

    useImperativeHandle(ref, () => ({
      goToSlide: (slideIndex: number) => {
        sliderRef.current?.slickGoTo(slideIndex);
      },
      courses,
    }));

    const handleSwipe = (currentSlide: number) => {
      const selectedCourse = courses[currentSlide]; // Get the course corresponding to the current slide index
      onCourseSelect(selectedCourse); // Your custom event logic
    };

    const settings = {
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeToSlide: true,
      touchThreshold: 10,
      afterChange: handleSwipe, // Trigger event after swipe
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    const handleImageLoad = () => {
      sliderRef.current?.slickGoTo(0); // Ensure slider recalculates dimensions
    };

    return (
      <div>
        {/* Slider Section */}
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 50,
            backgroundColor: '#202020',
            boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.2)',
            padding: '1rem 0 0 0',
            overflow: 'hidden',
          }}
        >
          <Slider {...settings} ref={sliderRef}>
            {courses.map((course) => (
              <div
                key={course.id}
                className={`cursor-pointer overflow-hidden}`}
                onClick={() => setShowCourseDetails(!showCourseDetails)}
              >
                {/* Image section with fixed height */}
                <div className="overflow-hidden h-[100px] sm:h-[150px] mx-2">
                  <img
                    src={
                      course.headerImagePath
                        ? `${PUBLIC_CONTAINER_URL}/${course.headerImagePath}`
                        : `${PUBLIC_CONTAINER_URL}/course-headers/fallback.jpg`
                    }
                    alt={course.name}
                    className="h-full object-cover"
                    style={{ width: '100%' }}
                    onLoad={handleImageLoad}
                  />
                </div>

                {/* Course details */}
                <div className="p-4 text-center text-white">
                  <div className="flex justify-between">
                    <p className="font-bold text-base mb-2">{course.name}</p>
                    <button className="card-icon-button">
                      <FontAwesomeIcon
                        icon={showCourseDetails ? faChevronDown : faChevronUp}
                      />
                    </button>
                  </div>
                  <div className="flex justify-between text-sm text-gray-400">
                    {Math.round(course.distance / 1000)} km away
                  </div>
                </div>
              </div>
            ))}
          </Slider>

          {showCourseDetails && selectedCourse && (
            <div
              style={{
                padding: '0',
                zIndex: 40,
                boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#202020',
                transition: 'transform 0.3s ease',
                transform: showCourseDetails
                  ? 'translateY(0)'
                  : 'translateY(100%)',
              }}
            >
              <div
                style={{
                  margin: '0 auto',
                  padding: '0 1rem',
                }}
              >
                <p className="text-sm text-white mb-2">
                  Total Members:{' '}
                  {isMemberCountLoading ? (
                    <span className="spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full border-t-customPrimary ml-2"></span>
                  ) : (
                    memberCount
                  )}
                </p>
                <p className="text-sm text-white mb-2">
                  People Played:{' '}
                  {isPeoplePlayedLoading ? (
                    <span className="spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full border-t-customPrimary ml-2"></span>
                  ) : (
                    peoplePlayedCount
                  )}
                </p>

                {/* Address Section with Map Marker */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '1rem',
                    color: 'white',
                    fontSize: '0.9rem',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    style={{
                      marginRight: '0.5rem',
                      color: '#FFD700', // Optional: Use a distinct color for the icon
                    }}
                  />
                  <span>
                    {selectedCourse.address || 'No address available'}
                  </span>
                </div>
                <>
                  {/* Course Details and Buttons */}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexWrap: 'wrap', // Ensures buttons stack on smaller screens
                      gap: '1rem', // Adds spacing between items when wrapped
                      marginBottom: '1rem',
                    }}
                  >
                    {/* Text Items */}
                    <div
                      style={{
                        flex: '1 1 auto', // Allow text to grow and occupy available space
                      }}
                    >
                      {isPlayedStatusLoading ? (
                        <span className="spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full border-t-customPrimary ml-2"></span>
                      ) : (
                        <>
                          <p className="text-sm text-white">
                            Have you played this course?
                          </p>
                          <FormField
                            id="coursePlayed"
                            label=""
                            type="checkbox"
                            placeholder=""
                            value={coursesPlayed[selectedCourse.id] ?? false}
                            onChange={(e) => {
                              isLoggedIn
                                ? updateCoursePlayed(e,selectedCourse)
                                : setShowLogin(true)
                            }}
                          />
                        </>
                      )}
                    </div>

                    {/* Buttons */}
                    <div
                      style={{
                        display: 'flex',
                        gap: '1rem', // Spacing between buttons
                        flex: '0 0 auto', // Prevent buttons from shrinking
                      }}
                    >
                      <Button
                        type="button"
                        variant="gold-outline"
                        className="w-auto" // Full width on mobile, auto on larger screens
                        onClick={() => alert('Request to play clicked!')}
                      >
                        Request to Play
                      </Button>
                      <Button
                        type="button"
                        variant="gold-outline"
                        className="w-auto" // Full width on mobile, auto on larger screens
                        onClick={() =>
                          alert(
                            'Book a Tee Time clicked! Course: ' +
                              selectedCourse.name,
                          )
                        }
                      >
                        Book a Tee Time
                      </Button>
                    </div>
                  </div>
                </>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default NearestCourses;
