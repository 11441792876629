import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  faChartLine,
  faGlobe,
  faMapMarkedAlt,
  faMap,
  faGolfBall,
  faUsersCog,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import {
  getUserName,
  getRoles,
  getProfilePicture,
} from '../../utils/tokenUtils';
import MenuItem from './MenuItem';
import { useNavigate } from 'react-router-dom';

interface SidebarProps {
  isOpen: boolean;
  activePage: string;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, activePage }) => {
  const roles = getRoles() || [];
  const navigate = useNavigate();

  const handleNavigation = (page: string) => {
    navigate(page);
  };

  const profilePicture = getProfilePicture();
  const userName = getUserName() || '';

  const getInitials = (name: string) => {
    const initials = name
      .split(' ')
      .map((part) => part.charAt(0))
      .join('')
      .toUpperCase();
    return initials || 'U';
  };

  return (
    <aside
      id="sidebar"
      className={`fixed inset-y-0 left-0 lg:relative z-50 transform ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      } lg:translate-x-0 transition duration-200 ease-in-out lg:w-64 w-64 bg-customPrimary h-full`}
    >
      <div className="flex flex-col h-full">
        {/* Header */}
        <div className="flex items-center justify-center h-16 px-6 bg-white">
          <a href="#">
            <img
              src="/images/logo-horizontal-dark.png"
              alt="GolfPlayed Logo"
              className="w-auto pt-3"
            />
          </a>
        </div>

        {/* Profile */}
        <div className="border-t border-gray-700 p-6 flex flex-col items-center">
          {profilePicture ? (
            <img
              className="w-16 h-16 rounded-full"
              src={profilePicture}
              alt="Profile"
            />
          ) : (
            <div className="w-16 h-16 rounded-full bg-gray-500 text-white flex items-center justify-center text-lg font-semibold">
              {getInitials(userName)}
            </div>
          )}
          <h2 className="mt-4 text-gray-100 text-lg font-semibold">
            {userName}
          </h2>
          <p className="text-gray-400 text-sm">
            {roles?.length > 0 ? roles.join(', ') : ''}
          </p>
        </div>

        {/* Scrollable Menu */}
        <PerfectScrollbar options={{ suppressScrollX: true }} className="flex-1 overflow-hidden">
          <div className="pl-6">
            <h3 className="text-xs text-gray-400 uppercase mb-4">Main</h3>
            <ul className="space-y-2">
              <MenuItem
                icon={faChartLine}
                label="Dashboard"
                isActive={activePage === 'Dashboard'}
                onClick={() => handleNavigation('/admin/dashboard')}
              />
            </ul>

            {roles.includes('Admin') && (
              <>
                <h3 className="text-xs text-gray-400 uppercase mb-4 mt-4">
                  Lookup Data
                </h3>
                <ul className="space-y-2">
                  <MenuItem
                    icon={faGlobe}
                    label="Countries"
                    isActive={activePage === 'Countries'}
                    onClick={() => handleNavigation('/admin/countries')}
                  />
                  <MenuItem
                    icon={faMapMarkedAlt}
                    label="States"
                    isActive={activePage === 'States'}
                    onClick={() => handleNavigation('/admin/states')}
                  />
                  <MenuItem
                    icon={faMap}
                    label="Sub Regions"
                    isActive={activePage === 'Sub Regions'}
                    onClick={() => handleNavigation('/admin/sub-regions')}
                  />
                </ul>

                <h3 className="text-xs text-gray-400 uppercase mb-4 mt-4">
                  Maintenance
                </h3>
                <ul className="space-y-2">
                  <MenuItem
                    icon={faGolfBall}
                    label="Courses"
                    isActive={activePage === 'Courses'}
                    onClick={() => handleNavigation('/admin/courses')}
                  />
                  <MenuItem
                    icon={faUser}
                    label="Members"
                    isActive={activePage === 'Members'}
                    onClick={() => handleNavigation('/admin/members')}
                  />
                  <MenuItem
                    icon={faUsersCog}
                    label="Admin Users"
                    isActive={activePage === 'Admin Users'}
                    onClick={() => handleNavigation('/admin/users')}
                  />
                </ul>
              </>
            )}
          </div>
        </PerfectScrollbar>

        {/* Footer */}
        <div className="p-4 bg-customPrimary text-white rounded-lg m-4">
          <h3 className="font-semibold text-sm">
            &copy; {new Date().getFullYear()} GolfPlayed
          </h3>
          <p className="text-xs mt-2">
            Created with a love for software development. All rights reserved.
          </p>
          <a
            href="#"
            className="text-white mt-2 inline-block underline text-sm font-semibold"
          >
            Learn More
          </a>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
