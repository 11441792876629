// AdminRoutes.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../PrivateRoute';

// (Import your admin-only views)
import Dashboard from '../../pages/admin/Dashboard';

import CountriesList from '../../pages/admin/countries/List';
import CountryCreate from '../../pages/admin/countries/Create';
import CountryEdit from '../../pages/admin/countries/Edit';

import StatesList from '../../pages/admin/states/List';
import StateCreate from '../../pages/admin/states/Create';
import StateEdit from '../../pages/admin/states/Edit';

import UsersList from '../../pages/admin/users/List';
import UserCreate from '../../pages/admin/users/Create';
import UserEdit from '../../pages/admin/users/Edit';

import SubRegionsList from '../../pages/admin/subregions/List';
import SubRegionsCreate from '../../pages/admin/subregions/Create';
import SubRegionsEdit from '../../pages/admin/subregions/Edit';

import CoursesList from '../../pages/admin/courses/List';
import CoursesCreate from '../../pages/admin/courses/Create';
import CoursesEdit from '../../pages/admin/courses/Edit';

import MembersList from '../../pages/admin/members/List';
import MembersCreate from '../../pages/admin/members/Create';
import MembersEdit from '../../pages/admin/members/Edit';

const AdminRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Wrap each protected member path in PrivateRoute if you want auth checks */}
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />

      <Route
        path="/countries"
        element={
          <PrivateRoute>
            <CountriesList />
          </PrivateRoute>
        }
      />
      <Route
        path="/countries/create"
        element={
          <PrivateRoute>
            <CountryCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/countries/:id"
        element={
          <PrivateRoute>
            <CountryEdit />
          </PrivateRoute>
        }
      />

      <Route
        path="/states"
        element={
          <PrivateRoute>
            <StatesList />
          </PrivateRoute>
        }
      />
      <Route
        path="/states/create"
        element={
          <PrivateRoute>
            <StateCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/states/:id"
        element={
          <PrivateRoute>
            <StateEdit />
          </PrivateRoute>
        }
      />
      <Route
        path="/sub-regions"
        element={
          <PrivateRoute>
            <SubRegionsList />
          </PrivateRoute>
        }
      />
      <Route
        path="/sub-regions/create"
        element={
          <PrivateRoute>
            <SubRegionsCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/sub-regions/:id"
        element={
          <PrivateRoute>
            <SubRegionsEdit />
          </PrivateRoute>
        }
      />

      <Route
        path='/courses'
        element={
          <PrivateRoute>
            <CoursesList />
          </PrivateRoute>
        }
      />

      <Route
        path='/courses/create'
        element={
          <PrivateRoute>
            <CoursesCreate />
          </PrivateRoute>
        }
      />

      <Route
        path='/courses/:id'
        element={
          <PrivateRoute>
            <CoursesEdit />
          </PrivateRoute>
        }
      />

      <Route
        path="/members"
        element={
          <PrivateRoute>
            <MembersList />
          </PrivateRoute>
        }
      />

      <Route
        path="/members/create"
        element={
          <PrivateRoute>
            <MembersCreate />
          </PrivateRoute>
        }
      />

      <Route
        path="/members/:id"
        element={
          <PrivateRoute>
            <MembersEdit />
          </PrivateRoute>
        }
      />

      <Route
        path="/users"
        element={
          <PrivateRoute>
            <UsersList />
          </PrivateRoute>
        }
      />
      <Route
        path="/users/create"
        element={
          <PrivateRoute>
            <UserCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/users/:id"
        element={
          <PrivateRoute>
            <UserEdit />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default AdminRoutes;
