import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { UserClient, ForgotPasswordDTO } from '../../services/apiClient';
import AuthLayout from '../../components/layout/AuthLayout';
import FormField from '../../components/common/FormField';
import Button from '../../components/common/Button';
import { API_BASE_URL } from '../../config/config';


const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null); // State for email error

  // Email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage(null); // Clear any existing error message
    setSuccessMessage(null); // Clear any existing success message
    setEmailError(null); // Clear email validation error

    // Validate email
    if (!email) {
      setEmailError('Email is required.');
      return;
    }

    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    const dto: ForgotPasswordDTO = { email };

    try {
      const apiClient = new UserClient(API_BASE_URL);
      const response = await apiClient.forgotPassword(dto);

      if (response && response.success) {
        // Show success message
        setSuccessMessage('Reset link has been sent to your email address.');
        setEmail('');
        setEmailError('');
      } else {
        setErrorMessage('Failed to send reset link. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <AuthLayout
      title="How many courses have you played?"
      description="With over 30 years experience and an expansive network we have developed our GolfPlayed app that helps you to keep track of all the courses you have ever played. "
    >
      <div className="mt-8">
        {errorMessage && (
          <div className="mb-4 text-sm text-red-600">{errorMessage}</div>
        )}
        {successMessage && (
          <div className="mb-4 text-sm text-green-600">{successMessage}</div>
        )}
        <form onSubmit={handleSubmit}>
          <FormField
            id="email"
            label="Email Address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="example@example.com"
            error={emailError ?? ''} 
          />
          <div className="mt-6">
            <Button type="submit" className="w-full">
              Send reset link
            </Button>
          </div>
        </form>
        <p className="mt-6 text-sm text-center text-gray-400">
          Return to{' '}
          <Link
            to={'/login'}
            className="text-black focus:outline-none focus:underline hover:underline"
          >
            login
          </Link>
        </p>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
