import React from 'react';
import Table from '../../components/common/Table';

interface CountryTableProps {
  data: any[];
  onEdit: (service: any) => void;
  onDelete: (service: any) => void;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  pageSize: number;
  onPageSizeChange: (size: number) => void;
  isLoading: boolean;
}

const CountryTable: React.FC<CountryTableProps> = ({ data, onEdit, onDelete, currentPage, totalPages, onPageChange, pageSize, onPageSizeChange, isLoading }) => {
  return (
    <Table
      columns={[{ label: 'Description', key: 'country_Description' },
        { label: 'Code', key: 'country_Var_ID' }
      ]}
      data={data}
      actions={{ onEdit: onEdit, onDelete: onDelete }}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={onPageChange}
      pageSize={pageSize}
      onPageSizeChange={onPageSizeChange}
      isLoading={isLoading}
    />
  );
};

export default CountryTable;
