import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/layout/AdminLayout';
import SubRegionTable from '../../../components/tables/SubRegionTable';
import Button from '../../../components/common/Button';
import Card from '../../../components/common/Card';
import { LegacySubRegionClient, LegacySubRegionDTO } from '../../../services/apiClient';
import FormField from '../../../components/common/FormField';
import { API_BASE_URL } from '../../../config/config';
import useCountries from '../../../hooks/useCountries';
import useStates from '../../../hooks/useStates';
import AddNewButton from '../../../components/common/AddNewButton';
import { useNavigate } from 'react-router-dom';

const SubRegionsList: React.FC = () => {
    const [subRegions, setSubRegions] = useState<LegacySubRegionDTO[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [nameSearch, setNameSearch] = useState<string | undefined>();
    const [countrySearch, setCountrySearch] = useState<number | undefined>();
    const [stateSearch, setStateSearch] = useState<number | undefined>();

    const navigate = useNavigate();
    const apiClient = new LegacySubRegionClient(API_BASE_URL);
    const { countries, isLoading: countriesLoading } = useCountries();
    const { states } = useStates(countrySearch || null);

    const breadcrumbs = [
        { label: 'Admin', href: '#' },
        { label: 'Sub Regions', href: '#' },
    ];

    const search = async (page: number, size: number, filters = {}) => {
        try {
            setIsLoading(true);
            const response = await apiClient.searchLegacySubRegions({
                filters: {
                    sub_Region_Name: nameSearch ?? undefined,
                    sub_Region_Country_ID: countrySearch,
                    sub_Region_State_ID: stateSearch,
                    ...filters
                },
                page,
                pageSize: size,
                sortBy: 'sub_Region_Name',
                sortDescending: false,
            });
            setSubRegions((response.data?.results as LegacySubRegionDTO[]) || []);
            setTotalRecords(response.data?.totalCount || 0);
            setTotalPages(Math.ceil((response.data?.totalCount || 0) / size));
        } catch (error) {
            console.error('Error searching:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        search(currentPage, pageSize, { nameSearch, countrySearch, stateSearch });
    }, [currentPage, pageSize]);

    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        setCurrentPage(1);
        search(1, pageSize, { nameSearch, countrySearch, stateSearch });
    };

    const handleClearSearch = () => {
        setNameSearch('');
        setCountrySearch(undefined);
        setStateSearch(undefined);
        search(1, pageSize, { sub_Region_Name: '', sub_Region_Country_ID: undefined, Sub_Region_State_ID: undefined });
    };

    return (
        <AdminLayout title="SubRegions" breadcrumbs={breadcrumbs} allowedRoles={['Admin']}>
            <Card>
                <div className="flex justify-between mb-4">
                    <h2 className="text-lg font-bold text-gray-500">Manage SubRegions ({totalRecords})</h2>
                    <AddNewButton onClick={() => navigate('/admin/sub-regions/create')} />
                </div>


                <form onSubmit={handleSearch} className="mb-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                        <FormField id="nameSearch" label="SubRegion Name" type="text" value={nameSearch} onChange={(e) => setNameSearch(e.target.value)} placeholder="Enter name" />
                        <FormField
                            id="countrySearch"
                            label="Country"
                            type="dropdown"
                            value={countrySearch?.toString() || ''}
                            onChange={(e) => setCountrySearch(e.target.value ? parseInt(e.target.value) : undefined)}
                            options={[
                                { value: '', label: 'Select country' },
                                ...countries.map((country) => ({
                                    value: country.country_ID?.toString() || '',
                                    label: country.country_Description || ''
                                })),
                            ]}
                            disabled={countriesLoading}
                        />
                        <FormField
                            id="stateSearch"
                            label="State"
                            type="dropdown"
                            value={stateSearch?.toString() || ''}
                            onChange={(e) => setStateSearch(e.target.value ? parseInt(e.target.value) : undefined)}
                            options={[
                                { value: '', label: 'Select state' },
                                ...states.map((state) => ({
                                    value: state.state_ID?.toString() || '',
                                    label: state.state_Description || ''
                                })),
                            ]}
                            disabled={!countrySearch}
                        />
                    </div>
                    <div className="flex justify-between mb-4">
                        <div className="space-x-4">
                            <Button variant="secondary" onClick={handleClearSearch}>Clear Search</Button>
                            <Button type="submit">Search</Button>
                        </div>
                    </div>
                </form>
                <SubRegionTable
                    services={subRegions}
                    handleEdit={(subRegion) => navigate(`/admin/sub-regions/${subRegion.sub_Region_ID}`)}
                    handleDelete={(subRegion) => {
                        if (window.confirm('Are you sure you want to delete this sub-region?')) {
                            apiClient
                                .deleteLegacySubRegion(subRegion.sub_Region_ID ?? 0)
                                .then(() =>
                                    setSubRegions(
                                        subRegions.filter((s) => s.sub_Region_ID !== subRegion.sub_Region_ID),
                                    ),
                                );
                        }
                    }}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    isLoading={isLoading}
                />
            </Card>
        </AdminLayout>
    );
};

export default SubRegionsList;
