import React from 'react';
import AdminLayout from '../../../components/layout/AdminLayout';
import CountryForm from '../../../components/forms/CountryForm';
import { LegacyCountryClient, LegacyCountryDTO } from '../../../services/apiClient';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../../config/config';
import Card from '../../../components/common/Card';

const CountryCreate: React.FC = () => {
  const navigate = useNavigate();
  const apiClient = new LegacyCountryClient(API_BASE_URL);

  const breadcrumbs = [
    { label: 'Admin', href: '#' },
    { label: 'Countries', href: '/admin/countries' },
    { label: 'Create', href: '#' },
  ];

  const handleSubmit = async (data: LegacyCountryDTO) => {
    try {
      await apiClient.createLegacyCountry(data);
      navigate('/admin/countries');
    } catch (error) {
      console.error('Error creating country:', error);
    }
  };

  return (
    <AdminLayout title="Create Country" breadcrumbs={breadcrumbs} allowedRoles={['Admin']}>
      <Card>
        <CountryForm
          initialValues={{} as LegacyCountryDTO}
          onSave={handleSubmit}
          onCancel={() => navigate('/admin/countries')}
          isCreating={true}
        />
      </Card>
    </AdminLayout>
  );
};

export default CountryCreate;