import React from 'react';
import Table from '../../components/common/Table';
import { MemberCoursesPlayedDTO } from '../../services/apiClient';

interface CoursesPLayedTableProps {
    courses: MemberCoursesPlayedDTO[];
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    pageSize: number;
    onPageSizeChange: (size: number) => void;
    isLoading: boolean;
}

const CoursesPlayedTable: React.FC<CoursesPLayedTableProps> = ({
    courses,
    currentPage,
    totalPages,
    onPageChange,
    pageSize,
    onPageSizeChange,
    isLoading,
}) => {
    return (
        <Table
            columns={[
                { label: 'Name', key: 'courseName' },
                { label: 'Rating', key: 'courseRating' },
            ]}
            data={courses}
            actions={{

            }}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            isLoading={isLoading}
        />
    );
};

export default CoursesPlayedTable;