import React from 'react';
import { Source, Layer } from 'react-map-gl';
import { LayerProps } from 'react-map-gl';

interface MapLayersProps {
  geoJsonData: any;
  clusterLayer: LayerProps;
  clusterCountLayer: LayerProps;
  unclusteredPointLayer: LayerProps;
}

const MapLayers: React.FC<MapLayersProps> = ({
  geoJsonData,
  clusterLayer,
  clusterCountLayer,
  unclusteredPointLayer,
}) => {
  return (
    <Source
      id="courses"
      type="geojson"
      data={geoJsonData}
      cluster={true}
      clusterMaxZoom={14}
      clusterRadius={50}
    >
      <Layer {...clusterLayer} />
      <Layer {...clusterCountLayer} />
      <Layer {...unclusteredPointLayer} />
    </Source>
  );
};

export default MapLayers;
