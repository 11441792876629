// MemberRoutes.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';

// (Import your member-only views)
import TeeTimeBookings from '../../pages/members/TeeTimeBookings';

const MemberRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/tee-time-booking" element={<TeeTimeBookings />} />
        </Routes>
    );
};

export default MemberRoutes;