import AvatarGrid from '../common/AvatarGrid';

interface TeeCardProps {
  onClick?: () => void;
  time: string;
  segment: string;
  footer: string;
  players?: {  // optional
    avatar?: string;
    name?: string;
  }[];
}

const TeeCard: React.FC<TeeCardProps> = ({
  onClick,
  time,
  segment,
  footer,
  players = [],
}) => {
  return (
    <div onClick={onClick} className="overflow-hidden p-2" style={{
      boxShadow: '0 3px 8px rgba(0, 0, 0, 1)',
    }}>

      <div className="flex justify-between mb-4 items-center">
        <label className="text-[#c1b06b] font-semibold">{time}</label>
        <label className="text-[#c1b06b] font-semibold">{segment}</label>
      </div>

      {/* Middle content */}
      <AvatarGrid players={players} />

      <hr className="mt-4 border-[#404244]" />
      <div className="flex justify-between mt-1 items-center">
        <label className="text-[#c1b06b] font-semibold">{footer}</label>
      </div>
    </div>
  );
};

export default TeeCard;
