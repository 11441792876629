import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/layout/AdminLayout';
import StateForm from '../../../components/forms/StateForm';
import { LegacyStateClient, LegacyStateDTO } from '../../../services/apiClient';
import { useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL } from '../../../config/config';
import Card from '../../../components/common/Card';
import useCountries from '../../../hooks/useCountries';

const StateEdit: React.FC = () => {
  const [state, setState] = useState<LegacyStateDTO | null>(null);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const apiClient = new LegacyStateClient(API_BASE_URL);

  const { countries } = useCountries();

  const breadcrumbs = [
    { label: 'Admin', href: '#' },
    { label: 'States', href: '/admin/states' },
    { label: 'Edit', href: '#' },
  ];

  useEffect(() => {
    const fetchState = async () => {
      try {
        const response = await apiClient.getLegacyStateById(Number(id!));
        setState(response.data as LegacyStateDTO);
      } catch (error) {
        console.error('Error fetching state:', error);
      }
    };
    fetchState();
  }, [id]);

  const handleSubmit = async (data: LegacyStateDTO) => {
    try {
      await apiClient.updateLegacyState(Number(id!), data);
      navigate('/admin/states');
    } catch (error) {
      console.error('Error updating state:', error);
    }
  };

  return (
    <AdminLayout
      title="Edit State"
      breadcrumbs={breadcrumbs}
      allowedRoles={['Admin']}
    >
      <Card>
        {state && (
          <StateForm
            initialValues={state}
            onSave={handleSubmit}
            onCancel={() => navigate('/admin/states')}
            isCreating={false}
            countries={countries}
            countriesLoading={false}
            countriesError={null}
          />
        )}
      </Card>
    </AdminLayout>
  );
};

export default StateEdit;
