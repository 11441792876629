interface PlayerData {
    avatar?: string;   // URL for the image
    name?: string;   // e.g. "Andrew +5.1"
}

interface AvatarGridProps {
    players: PlayerData[];
    onDeletePlayer?: (index: number) => void;
}

const AvatarGrid: React.FC<AvatarGridProps> = ({ players, onDeletePlayer }) => {

    // Always ensure we have 4 total spots.
    // If fewer than 4 players are provided, fill the remaining spots with placeholder objects.
    const totalSpots = 4;
    const filledPlayers = [
        ...players,
        ...Array(Math.max(0, totalSpots - players.length)).fill({}),
    ].slice(0, totalSpots);

    return (
        <div className="flex justify-evenly items-center ">
            {filledPlayers.map((player, index) => (
                <div key={index} className="flex flex-col items-center relative">
                    {/* Circular avatar */}
                    <div className="
                        w-12 h-12 
                        rounded-full 
                        flex items-center justify-center
                        bg-[#202020]
                        overflow-hidden
                        border-2 border border-[#c1b06b]
                        cursor-pointer">
                        {player.avatar ? (
                            <img
                                src={player.avatar}
                                alt={player.name || 'Player'}
                                className="w-full h-full object-cover"
                            />
                        ) : (
                            // Placeholder if no logo
                            <span className="text-[#c1b06b] text-lg">+</span>
                        )}

                        {/* The blue “remove” icon (button) - absolute positioned */}
                        {player.name && onDeletePlayer && (
                            <button
                                className="
                                absolute
                                bottom-8 right-0
                                w-4 h-4
                                bg-blue-600
                                text-white
                                rounded-full
                                flex items-center justify-center
                                text-xs
                                border-2 border-white
                                "
                                onClick={(e) => {
                                    e.stopPropagation(); // stops parent click if needed
                                    onDeletePlayer(index);
                                }}
                            >
                                &minus;
                            </button>
                        )}

                    </div>
                    {/* Player name or fallback text */}
                    <span className="text-sm text-white mt-2">
                        {player.name || 'Available'}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default AvatarGrid;
