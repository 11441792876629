import React from 'react';
import MediaQuery from 'react-responsive';
import CourseList from '../maps/CourseList';
import NearestCourse from '../../interfaces/NearestCourse';

export interface CourseListWrapperProps{
    courses: any[];
    isLoggedIn: boolean;
    isPlayedStatusLoading: boolean;
    coursesPlayed: { [key: string]: boolean; };
    setIsLoggedIn: (loggedIn: boolean) => void;
    showLogin: boolean;
    setShowLogin: (showLogin: boolean) => void;
    setSelectedCourse: (selectedCourse: NearestCourse) => void;
    updateCoursePlayed: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | null, course: NearestCourse) => void;
}

const CourseListWrapper: React.FC<CourseListWrapperProps> = ({ 
    courses,
    isLoggedIn,
    isPlayedStatusLoading,
    coursesPlayed,
    setIsLoggedIn,
    showLogin,
    setShowLogin,
    setSelectedCourse,
    updateCoursePlayed
}) => {
    return (
        <>
            <MediaQuery maxWidth={768}>
                <CourseList 
                    courses={courses} 
                    viewType="mobile"
                    isLoggedIn={isLoggedIn}
                    isPlayedStatusLoading={isPlayedStatusLoading}
                    coursesPlayed={coursesPlayed}
                    setIsLoggedIn={setIsLoggedIn}
                    showLogin={showLogin}
                    setShowLogin={setShowLogin}
                    setSelectedCourse={setSelectedCourse}
                    updateCoursePlayed={updateCoursePlayed}
                />
            </MediaQuery>
            <MediaQuery minWidth={769}>
                <div style={{ padding: '0px 30px 0px 30px' }}>
                    <CourseList 
                        courses={courses}
                        viewType="desktop" 
                        isLoggedIn={isLoggedIn}
                        isPlayedStatusLoading={isPlayedStatusLoading}
                        coursesPlayed={coursesPlayed}
                        setIsLoggedIn={setIsLoggedIn}
                        showLogin={showLogin}
                        setShowLogin={setShowLogin}
                        setSelectedCourse={setSelectedCourse}
                        updateCoursePlayed={updateCoursePlayed}
                    />
                </div>
            </MediaQuery>
        </>
    );
};

export default CourseListWrapper;
