import React, { ReactElement, useRef, useState } from 'react';

interface TabsProps {
  children: React.ReactNode
}

interface TabState {
  currentTab: number;
  noTabs: number;
}

const Tabs: React.FC<TabsProps> = ({
  children
}) => {

  const tabList = React.Children.toArray(children).filter(React.isValidElement) as ReactElement[];
  const tabListLength = tabList.length;
  const [tabSelected, setTabSelected] = useState<TabState>({
    currentTab: 0,
    noTabs: tabListLength,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const wrapperRef = useRef<HTMLUListElement>(null);

  return (
    <>
      <section className="max-w-full" aria-multiselectable="false">
        <ul
          className="flex items-center border-b border-slate-200"
          role="tablist"
          ref={wrapperRef}
        >
          {tabList.map((tab: ReactElement, index: number) => (
            <li className="" role="presentation" key={index}>
              <button
                className={`-mb-px inline-flex h-12 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-6 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${tabSelected.currentTab === index
                  ? "border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500"
                  : "justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500"
                  }`}
                id="tab-label-1a"
                role="tab"
                aria-setsize={tabListLength}
                aria-posinset={selectedIndex}
                tabIndex={tabSelected.currentTab === selectedIndex ? 0 : -1}
                aria-controls="tab-panel-1a"
                aria-selected={`${tabSelected.currentTab === selectedIndex ? "true" : "false"
                  }`}
                onClick={() => { setTabSelected({ ...tabSelected, currentTab: index }); setSelectedIndex(index) }}
              >
                <span>{tab.props.title}</span>
              </button>
            </li>
          ))}
        </ul>
        <div className="">
          <div
            className={`px-6 py-4 ${tabSelected.currentTab === selectedIndex ? "" : "hidden"
              }`}
            id="tab-panel-1a"
            aria-hidden={`${tabSelected.currentTab === selectedIndex ? "true" : "false"}`}
            role="tabpanel"
            aria-labelledby="tab-label-1a"
            tabIndex={-1}
          >
            {tabList[tabSelected.currentTab].props.children}
          </div>
        </div>
      </section>
    </>
  )
};

export default Tabs;