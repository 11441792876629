import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

interface FormFieldProps {
  id: string;
  label: string;
  value?: string | boolean | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  placeholder?: string;
  type?: 'text' | 'textarea' | 'email' | 'password' | 'number' | 'dropdown' | 'checkbox' | 'date' | 'datetime' | 'file' | 'richtext';
  options?: { value: string | undefined; label: string }[]; 
  error?: string; 
  disabled?: boolean;
}

const FormField: React.FC<FormFieldProps> = React.memo(({
  id,
  label,
  value,
  onChange,
  placeholder,
  type = 'text',
  options,
  error,
  disabled = false,
}) => {
  const handleCKEditorChange = (_: any, editor: any) => {
    const content = editor.getData();
    const event = {
      target: {
        id,
        value: content,
      },
    } as React.ChangeEvent<HTMLInputElement>; // Mimic ChangeEvent
    onChange(event);
  };

  return (
    <div className="text-left mb-4">
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      {type === 'dropdown' && options ? (
        <select
          id={id}
          value={value as string}
          onChange={onChange}
          className={`bg-white mt-1 block w-full px-4 py-2 border shadow-sm focus:ring-gray-300 focus:border-gray-300 sm:text-sm ${error ? 'border-red-500 focus:ring-red-500 focus:border-red-500' : 'border-gray-300'}`}
          disabled={disabled}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : type === 'checkbox' ? (
        <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
          <input
            type="checkbox"
            name={id}
            id={id}
            checked={value as boolean}
            onChange={onChange}
            className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            disabled={disabled}
          />
          <label
            htmlFor={id}
            className={`toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
          />
        </div>
      ) : type === 'textarea' ? (
        <textarea
          id={id}
          value={value as string}
          onChange={onChange}
          placeholder={placeholder}
          className={`mt-1 block w-full px-4 py-2 border shadow-sm focus:ring-gray-300 focus:border-gray-300 sm:text-sm ${error ? 'border-red-500 focus:ring-red-500 focus:border-red-500' : 'border-gray-300'}`}
          disabled={disabled}
        />
      ) : type === 'file' ? (
        <input
          type="file"
          id={id}
          onChange={onChange}
          className={`mt-1 block w-full px-4 py-2 border focus:ring-gray-300 focus:border-gray-300 sm:text-sm ${error ? 'border-red-500 focus:ring-red-500 focus:border-red-500' : 'border-gray-300'}`}
          disabled={disabled}
        />
      ) : type === 'richtext' ? (
        <div id={id} className={`mt-1 block w-full border px-2 py-2 ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
          <CKEditor
            editor={ClassicEditor}
            data={value as string}
            onChange={handleCKEditorChange}
            config={{
              placeholder,
              toolbar: [
                'heading',
                '|',
                'bold',
                'italic',
                'link',
                'bulletedList',
                'numberedList',
                '|',
                'undo',
                'redo',
              ],
            }}
          />
        </div>
      ) : (
        <input
          type={type}
          id={id}
          value={value as string}
          onChange={onChange}
          placeholder={placeholder}
          className={`mt-1 block w-full px-4 py-2 border focus:ring-gray-300 focus:border-gray-300 sm:text-sm ${error ? 'border-red-500 focus:ring-red-500 focus:border-red-500' : 'border-gray-300'}`}
          disabled={disabled}
        />
      )}
      {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
    </div>
  );
});

export default FormField;
