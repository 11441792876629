import React from 'react';
import Table from '../../components/common/Table';
import { UserDTO } from '../../services/apiClient';

interface UserTableProps {
  users: UserDTO[];
  onEdit: (user: UserDTO) => void;
  onDelete: (user: UserDTO) => void;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  pageSize: number;
  onPageSizeChange: (size: number) => void;
  isLoading: boolean;
}

const getUserInitials = (user: UserDTO) => {
  const initials = `${user.firstName?.charAt(0) || ''}${user.lastName?.charAt(0) || ''}`.toUpperCase();
  return initials || '?';
};

const UserTable: React.FC<UserTableProps> = ({
  users,
  onEdit,
  onDelete,
  currentPage,
  totalPages,
  onPageChange,
  pageSize,
  onPageSizeChange,
  isLoading,
}) => {
  return (
    <Table
      columns={[
        {
          label: '',
          key: 'profilePicture',
          isImage: true,
          imageSize: '40px',
          renderPlaceholder: (user) => (
            <div
              className="rounded-full bg-gray-300 text-white flex items-center justify-center"
              style={{ width: '40px', height: '40px' }}
            >
              {getUserInitials(user)}
            </div>
          ),
        },
        { label: 'First Name', key: 'firstName' },
        { label: 'Last Name', key: 'lastName' },
        { label: 'Email', key: 'email' },
      ]}
      data={users}
      actions={{
        onEdit: (user) => onEdit(user),
        onDelete: (user) => onDelete(user),
      }}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={onPageChange}
      pageSize={pageSize}
      onPageSizeChange={onPageSizeChange}
      isLoading={isLoading}
    />
  );
};

export default UserTable;
