import React from 'react';
import { PUBLIC_CONTAINER_URL } from '../../config/config';
import MediumButton from '../common/MediumButton';
import NearestCourse from '../../interfaces/NearestCourse';
import FormField from '../common/FormField';

interface CourseDisplayCardProps extends NearestCourse {
    isLoggedIn: boolean;
    isPlayedStatusLoading: boolean;
    coursePlayedStatus: boolean;
    setIsLoggedIn: (loggedIn: boolean) => void;
    showLogin: boolean;
    setShowLogin: (showLogin: boolean) => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    onRequestToPlayClick: () => void;
    onTeeTimesClick: (course: NearestCourse) => void;
}

const CourseDisplayCard: React.FC<CourseDisplayCardProps> = ({
    id,
    name,
    country,
    state,
    address,
    distance,
    headerImagePath,
    logoImagePath,
    isLoggedIn,
    isPlayedStatusLoading,
    coursePlayedStatus,
    setShowLogin,
    onChange,

    onRequestToPlayClick,
    onTeeTimesClick,
}) => {
    return (
        <div className="max-w-sm rounded overflow-hidden"
            style={{
                backgroundColor: '#202020',
                boxShadow: '0 3px 8px rgba(0, 0, 0, 1)',
            }}>

            <div className="relative">
                <img className="w-full h-48 object-cover"
                    src={
                        headerImagePath
                            ? `${PUBLIC_CONTAINER_URL}/${headerImagePath}`
                            : `${PUBLIC_CONTAINER_URL}/course-headers/fallback.jpg`
                    }
                    alt={`${name} logo`} />
                <div className="absolute bottom-0 left-2 bg-white flex items-center justify-center" style={{ width: '80px', bottom: '-10px', height: '80px', padding: '2px', borderRadius: '3px', overflow: 'hidden' }}>
                    <img className="object-contain"
                        src={
                            logoImagePath
                                ? `${PUBLIC_CONTAINER_URL}/${logoImagePath}`
                                : `${PUBLIC_CONTAINER_URL}/course-logos/fallback.png`
                        }
                        alt={`${name} logo`} />
                </div>
            </div>

            <div className="px-4 py-4 mt-2">
                <p className="font-bold text-lg text-white">{name}</p>
                <p className="text-md text-white">{state}, {country}</p>
            </div>

            {/* Buttons */}
            <div className="px-4 py-4" style={{ display: 'flex', gap: '1rem', flex: '0 0 auto' }}>
                <MediumButton
                    type="button"
                    variant="gold-outline"
                    className="px-2 py-1 text-xs w-auto" // Full width on mobile, auto on larger screens
                    onClick={onRequestToPlayClick}>
                    Request to Play
                </MediumButton>

                <MediumButton
                    type="button"
                    variant="gold-outline"
                    className="text-xs w-auto" // Full width on mobile, auto on larger screens
                    onClick={() => {
                        onTeeTimesClick({
                            id,
                            name,
                            state,
                            country,
                            distance,
                            headerImagePath,
                            logoImagePath
                        });
                    }}>
                    Book a Tee Time
                </MediumButton>
            </div>

            <div className="flex justify-between items-center px-4 py-4">
                <span className="text-sm text-white">Already played this course?</span>
                <div className="flex items-center">
                    { isPlayedStatusLoading ? 
                        <span className="spinner-border animate-spin inline-block w-3 h-3 border-2 rounded-full border-t-customPrimary ml-2"></span>
                    :
                        <FormField
                            id={`coursPlayed${id}`}
                            label=""
                            type="checkbox"
                            placeholder=""
                            value={coursePlayedStatus}
                            onChange={(e) => {
                                isLoggedIn ? 
                                    onChange(e)
                                : 
                                    setShowLogin(true)
                            }}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default CourseDisplayCard;
