import React from 'react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  pageSize: number;
  onPageSizeChange: (size: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  pageSize,
  onPageSizeChange,
}) => {
  const pageSizes = [10, 20, 50];

  // Calculate the start and end page numbers to display
  const maxPagesToShow = 5;
  let startPage = Math.max(1, currentPage - 2);
  let endPage = Math.min(totalPages, currentPage + 2);

  if (endPage - startPage < maxPagesToShow - 1) {
    if (currentPage < Math.ceil(maxPagesToShow / 2)) {
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (currentPage > totalPages - Math.floor(maxPagesToShow / 2)) {
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }
  }

  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return (
    <div className="flex justify-between items-center py-2">
      {/* Page Size Selector */}
      <div>
        <label className="mr-2 text-gray-600">Page Size:</label>
        <select
          value={pageSize}
          onChange={(e) => onPageSizeChange(Number(e.target.value))}
          className="px-3 py-2 border rounded-md text-gray-600 bg-white"
        >
          {pageSizes.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>

      {/* Pagination Controls */}
      <div className="flex items-center">
        <button
          className={`px-3 py-1 rounded-lg mr-1 ${currentPage === 1 ? 'text-customPrimary cursor-not-allowed' : 'text-black'}`}
          onClick={() => onPageChange(1)}
          disabled={currentPage === 1}
        >
          &laquo;
        </button>
        <button
          className={`px-3 py-1 rounded-lg mr-1 ${currentPage === 1 ? 'text-customPrimary cursor-not-allowed' : 'text-black'}`}
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &lsaquo;
        </button>

        {pages.map((page) => (
          <button
            key={page}
            className={`px-3 py-1 mx-1 rounded-lg ${currentPage === page ? 'bg-customPrimary text-white' : 'bg-gray-200 text-gray-600'}`}
            onClick={() => onPageChange(page)}
          >
            {page}
          </button>
        ))}

        <button
          className={`px-3 py-1 rounded-lg ml-1 ${currentPage === totalPages ? 'text-customPrimary cursor-not-allowed' : 'text-black'}`}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &rsaquo;
        </button>
        <button
          className={`px-3 py-1 rounded-lg ml-1 ${currentPage === totalPages ? 'text-customPrimary cursor-not-allowed' : 'text-black'}`}
          onClick={() => onPageChange(totalPages)}
          disabled={currentPage === totalPages}
        >
          &raquo;
        </button>
      </div>
    </div>
  );
};

export default Pagination;
