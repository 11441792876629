import { useEffect, useState } from 'react';
import { LegacySubRegionDTO, LegacySubRegionClient } from '../services/apiClient';
import { API_BASE_URL } from '../config/config';

const useSubRegions = (stateId: number | null) => {
  const [subRegions, setSubRegions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const subRegionClient = new LegacySubRegionClient(API_BASE_URL);

  useEffect(() => {
    // Fetch sub-regions only if stateId is defined
    if (stateId !== null) {
      const fetchSubRegions = async () => {
        setIsLoading(true);
        setError(null);

        try {
          const response = await subRegionClient.getLegacySubRegionsByStateId(stateId);
          if (response && response.success && response.data) {
            setSubRegions(response.data);
          } else {
            setError('Failed to fetch sub-regions');
          }
        } catch (err) {
          setError('Error fetching sub-regions');
          console.error('Error fetching sub-regions:', err);
        } finally {
          setIsLoading(false);
        }
      };

      fetchSubRegions();
    } else {
      // Clear sub-regions if no stateId is provided
      setSubRegions([]);
    }
  }, [stateId]);

  return { subRegions, isLoading, error };
};

export default useSubRegions;
