import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// Function to check if the user is authenticated
const isAuthenticated = () => {
  // Check if the token exists in localStorage (or another form of authentication)
  const token = localStorage.getItem('token');
  return token !== null;
};

// PrivateRoute component
const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation(); // Get the current location

  return isAuthenticated() ? (
    children
  ) : (
    // Navigate to the login page and pass the current location as state
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default PrivateRoute;
