
const handleMapLoad = (
  event: { target: mapboxgl.Map }
) => {
  const map = event.target;
  if (!map.hasImage('course-marker')) {
    map.loadImage('/images/map/course-marker.png', (error, image) => {
      if (error) {
        console.error('Error loading course marker image:', error);
        return;
      }
      if (image) {
        map.addImage('course-marker', image);
      }
    });
  }

  if (!map.hasImage('user-marker')) {
    map.loadImage('/images/map/user-marker.png', (error, image) => {
      if (error) {
        console.error('Error loading user marker image:', error);
        return;
      }
      if (image) {
        map.addImage('user-marker', image);
      }
    });
  }

  // Add pointer cursor for clusters
  map.on('mouseenter', 'clusters', () => {
    map.getCanvas().style.cursor = 'pointer';
  });

  map.on('mouseleave', 'clusters', () => {
    map.getCanvas().style.cursor = '';
  });

  // Add pointer cursor for individual markers
  map.on('mouseenter', 'unclustered-point-label', () => {
    map.getCanvas().style.cursor = 'pointer';
  });

  map.on('mouseleave', 'unclustered-point-label', () => {
    map.getCanvas().style.cursor = '';
  });
};

export default handleMapLoad;
