import React from 'react';
import { Source, Layer } from 'react-map-gl';

interface UserLocationMarkerProps {
  userLocation: { latitude: number; longitude: number } | null;
}

const UserLocationMarker: React.FC<UserLocationMarkerProps> = ({ userLocation }) => {
  if (!userLocation) return null;

  return (
    <Source
      id="user-location"
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Point',
              coordinates: [userLocation.longitude, userLocation.latitude],
            },
          },
        ],
      }}
    >
      <Layer
        id="user-location-layer"
        type="symbol"
        layout={{
          'icon-image': 'user-marker',
          'icon-size': 1,
        }}
      />
    </Source>
  );
};

export default UserLocationMarker;
