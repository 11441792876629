import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { X } from "lucide-react";

interface ModalProps {
  title: string;
  subTitle: string;
  children: React.ReactNode;
  isShowing: boolean;
  setIsShowing: (showing: boolean) => void;
}

const Modal: React.FC<ModalProps> = ({
  title,
  subTitle,
  children,
  isShowing,
  setIsShowing,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsShowing(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    let html = document.querySelector('html');

    if (html) {
      if (isShowing && html) {
        html.style.overflowY = 'hidden';

        const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

        const modal = document.querySelector('#modal'); // select the modal by it's id

        const focusableContent = modal.querySelectorAll(focusableElements);

        const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal

        const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

        document.addEventListener('keydown', function (e) {
          if (e.keyCode === 27) {
            setIsShowing(false);
          }

          let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

          if (!isTabPressed) {
            return;
          }

          if (e.shiftKey) {
            // if shift key pressed for shift + tab combination
            if (document.activeElement === firstFocusableElement) {
              lastFocusableElement.focus(); // add focus for the last focusable element
              e.preventDefault();
            }
          } else {
            // if tab key is pressed
            if (document.activeElement === lastFocusableElement) {
              // if focused has reached to last focusable element then focus first focusable element after pressing tab
              firstFocusableElement.focus(); // add focus for the first focusable element
              e.preventDefault();
            }
          }
        });
        firstFocusableElement.focus();
      } else {
        html.style.overflowY = 'visible';
      }
    }
  }, [isShowing]);

  return (
    <>
      {isShowing && typeof document !== 'undefined'
        ? ReactDOM.createPortal(
            <div
              className="fixed top-0 left-0 z-[100] flex items-center justify-center w-screen h-screen bg-slate-300/20 backdrop-blur-sm"
              aria-labelledby="header-3a content-3a"
              aria-modal="true"
              tabIndex={-1}
              role="dialog"
            >
              {/* <!-- Modal --> */}
              <div
                ref={wrapperRef}
                className="flex max-h-[90vh] w-11/12 max-w-xl flex-col gap-6 overflow-hidden rounded bg-white p-6 text-slate-500 shadow-xl shadow-slate-700/10"
                id="modal"
                role="document"
              >
                {/* <!-- Modal header --> */}
                <header id="header-3a" className="flex items-center gap-4">
                  <div className='flex-1'>
                    <h3 className="text-xl font-medium text-slate-700">
                      {title}
                    </h3>
                    <p>{subTitle}</p>
                  </div>

                  <button
                    onClick={() => setIsShowing(false)}
                    className="inline-flex items-center justify-center h-10 gap-2 px-5 text-sm font-medium tracking-wide transition duration-300 rounded-full justify-self-center whitespace-nowrap text-customSecondary hover:bg-customSecondary hover:text-customPrimary focus:bg-customSecondary focus:text-customPrimary focus-visible:outline-none disabled:cursor-not-allowed disabled:text-customPrimary disabled:shadow-none disabled:hover:bg-transparent"
                    aria-label="close dialog"
                  >
                    <span className="relative only:-mx-5">
                        <X className="w-5 h-5" aria-label="Close login modal" />
                    </span>
                  </button>
                </header>
                {/*        <!-- Modal body --> */}

                <div id="content-3a" className="flex-1 overflow-auto">
                  {children}
                </div>
              </div>
            </div>,
            document.body,
          )
        : null}
    </>
  );
};

export default Modal;
