import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faBell,
  faChevronDown,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import ProfileMenu from './ProfileMenu';
import { getUserName, getRoles, getProfilePicture } from '../../utils/tokenUtils';
import { useNavigate } from 'react-router-dom';

interface Breadcrumb {
  label: string;
  href: string;
}

interface TopbarProps {
  toggleSidebar: () => void;
  breadcrumbs: Breadcrumb[];
}

const Topbar: React.FC<TopbarProps> = ({ toggleSidebar, breadcrumbs }) => {
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [viewSwitcherOpen, setViewSwitcherOpen] = useState(false);
  const navigate = useNavigate();

  const toggleProfileMenu = () => {
    setProfileMenuOpen(!profileMenuOpen);
  };

  const toggleViewSwitcher = () => {
    setViewSwitcherOpen(!viewSwitcherOpen);
  };

  const toggleNotificationDropdown = () => {
    setNotificationOpen(!notificationOpen);
  };

  const roles = getRoles() || [];
  const profilePicture = getProfilePicture();
  const userName = getUserName() || '';

  // Generate initials if there's no profile picture
  const getInitials = (name: string) => {
    const initials = name
      .split(' ')
      .map(part => part.charAt(0))
      .join('')
      .toUpperCase();
    return initials || 'U'; // Fallback to "U" for unknown
  };

  return (
    <header className="flex items-center justify-between h-16 px-4 lg:px-6 bg-white">
      <div className="flex items-center space-x-2">
        {/* Hamburger Menu */}
        <button
          className="lg:hidden p-2 text-gray-600 hover:bg-purple-500 rounded-lg focus:outline-none"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon icon={faBars} className="w-5 h-5 text-gray-600" />
        </button>

        {/* Breadcrumb */}
        <nav className="hidden lg:block text-sm font-semibold text-gray-600 lg:ml-0 ml-2">
          <ol className="inline-flex space-x-1">
            {breadcrumbs.map((breadcrumb, index) => (
              <li key={index}>
                <a href={breadcrumb.href} className="text-gray-600">
                  {breadcrumb.label}
                </a>
                {index < breadcrumbs.length - 1 && (
                  <span className="text-gray-600">{' > '}</span>
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>

      {/* Notifications, Messages, View Switcher & Profile */}
      <div className="flex items-center space-x-4">
        {/* Messages Icon with Badge */}
        <div className="relative">
          <a
            href="/admin/messages"
            className="p-2 rounded-lg hover:bg-white hover:bg-opacity-10 transition relative"
          >
            <FontAwesomeIcon icon={faEnvelope} className="h-5 w-5 text-gray-600" />
            <span className="absolute top-0 right-0 block h-2.5 w-2.5 transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full"></span>
          </a>
        </div>

        {/* Notifications Icon with Badge */}
        <div className="relative">
          <button
            className="p-2 rounded-lg hover:bg-white hover:bg-opacity-10 transition relative"
            onClick={toggleNotificationDropdown}
          >
            <FontAwesomeIcon icon={faBell} className="h-5 w-5 text-gray-600" />
            <span className="absolute top-0 right-0 block h-2.5 w-2.5 transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full"></span>
          </button>

          {/* Notifications Dropdown */}
          {notificationOpen && (
            <div className="absolute right-0 mt-2 w-64 bg-white border rounded-lg shadow-lg py-1 z-50">
              <h3 className="px-4 py-2 font-semibold text-gray-700 border-b">
                Notifications
              </h3>
              <div className="px-4 py-3 border-b">
                <h4 className="text-gray-800">New Message</h4>
                <p className="text-sm text-gray-600">
                  You have a new message from John.
                </p>
              </div>
              <div className="px-4 py-3">
                <h4 className="text-gray-800">Account Update</h4>
                <p className="text-sm text-gray-600">
                  Your account has been updated successfully.
                </p>
              </div>
            </div>
          )}
        </div>

        {/* Profile Dropdown */}
        <div className="relative">
          <button
            className="flex items-center space-x-2 p-2 rounded-lg hover:bg-white hover:bg-opacity-10"
            onClick={toggleProfileMenu}
          >
            {/* Display profile picture or initials */}
            {profilePicture ? (
              <img
                className="w-8 h-8 rounded-full"
                src={profilePicture}
                alt="Profile"
              />
            ) : (
              <div className="w-8 h-8 rounded-full bg-gray-500 text-white flex items-center justify-center text-sm font-semibold">
                {getInitials(userName)}
              </div>
            )}
            <span className="text-gray-600 hidden sm:block">{getUserName()}</span>
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`w-5 h-5 text-gray-600 transition-transform ${
                profileMenuOpen ? 'rotate-180' : ''
              }`}
            />
          </button>

          {/* Profile Menu Dropdown */}
          {profileMenuOpen && <ProfileMenu />}
        </div>
      </div>
    </header>
  );
};

export default Topbar;
