import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/layout/AdminLayout';
import CourseTable from '../../../components/tables/CourseTable';
import Button from '../../../components/common/Button';
import AddNewButton from '../../../components/common/AddNewButton';
import Card from '../../../components/common/Card';
import FormField from '../../../components/common/FormField';
import {
    LegacyCourseClient,
    LegacyCourseDTO,
} from '../../../services/apiClient';
import { API_BASE_URL } from '../../../config/config';
import useCountries from '../../../hooks/useCountries';
import useStates from '../../../hooks/useStates';
import useSubRegions from '../../../hooks/useSubRegions';
import { useNavigate } from 'react-router-dom';

const CoursesList: React.FC = () => {
    const [courses, setCourses] = useState<LegacyCourseDTO[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [courseNameSearch, setCourseNameSearch] = useState<string | undefined>(
        '',
    );
    const [countrySearch, setCountrySearch] = useState<number | undefined>();
    const [stateSearch, setStateSearch] = useState<number | undefined>();
    const [subRegionSearch, setSubRegionSearch] = useState<number | undefined>();

    const { countries, isLoading: countriesLoading } = useCountries();
    const { states } = useStates(countrySearch ?? null);
    const { subRegions } = useSubRegions(stateSearch ?? null);

    const navigate = useNavigate();
    const apiClient = new LegacyCourseClient(API_BASE_URL);

    const breadcrumbs = [
        { label: 'Admin', href: '#' },
        { label: 'Courses', href: '#' },
    ];

    const search = async (page: number, size: number, filters = {}) => {
        try {
            setIsLoading(true);
            const response = await apiClient.searchLegacyCourses({
                filters: {
                    course_Name: courseNameSearch || '',
                    country_ID: countrySearch,
                    state_ID: stateSearch,
                    sub_Region_ID: subRegionSearch,
                    ...filters,
                },
                page: page,
                pageSize: size,
                sortBy: 'Course_Name',
                sortDescending: false,
            });

            setCourses((response.data?.results as LegacyCourseDTO[]) || []);
            setTotalRecords(response.data?.totalCount || 0);
            setTotalPages(Math.ceil((response.data?.totalCount || 0) / size));
        } catch (error) {
            console.error('Error searching:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        search(currentPage, pageSize);
    }, [currentPage, pageSize]);

    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        setCurrentPage(1);
        search(1, pageSize);
    };

    const handleClearSearch = () => {
        setCourseNameSearch('');
        setCountrySearch(undefined);
        setStateSearch(undefined);
        setSubRegionSearch(undefined);
        search(1, pageSize, {
            course_Name: '',
            country_ID: null,
            state_ID: null,
            sub_Region_ID: null,
        });
    };

    return (
        <AdminLayout
            title="Courses"
            breadcrumbs={breadcrumbs}
            allowedRoles={['Admin']}
        >
            <Card>
                <div className="flex justify-between mb-4">
                    <h2 className="text-lg font-bold text-gray-500">
                        Manage Courses ({totalRecords})
                    </h2>
                    <AddNewButton onClick={() => navigate('/admin/courses/create')} />
                </div>
                <form onSubmit={handleSearch} className="mb-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                        <FormField
                            id="courseNameSearch"
                            label="Course Name"
                            type="text"
                            value={courseNameSearch}
                            onChange={(e) => setCourseNameSearch(e.target.value)}
                            placeholder="Search by course name"
                        />
                        <FormField
                            id="countrySearch"
                            label="Country"
                            type="dropdown"
                            value={countrySearch?.toString() || ''}
                            onChange={(e) =>
                                setCountrySearch(
                                    e.target.value ? parseInt(e.target.value) : undefined,
                                )
                            }
                            options={[
                                { value: '', label: 'Select a country' },
                                ...countries.map((country) => ({
                                    value: country.country_ID?.toString() || '',
                                    label: country.country_Description || '',
                                })),
                            ]}
                            disabled={countriesLoading}
                        />
                        <FormField
                            id="stateSearch"
                            label="State"
                            type="dropdown"
                            value={stateSearch?.toString() || ''}
                            onChange={(e) =>
                                setStateSearch(
                                    e.target.value ? parseInt(e.target.value) : undefined,
                                )
                            }
                            options={[
                                { value: '', label: 'Select a state' },
                                ...states.map((state) => ({
                                    value: state.state_ID?.toString() || '',
                                    label: state.state_Description || '',
                                })),
                            ]}
                            disabled={!countrySearch}
                        />
                        <FormField
                            id="subRegionSearch"
                            label="Sub Region"
                            type="dropdown"
                            value={subRegionSearch?.toString() || ''}
                            onChange={(e) =>
                                setSubRegionSearch(
                                    e.target.value ? parseInt(e.target.value) : undefined,
                                )
                            }
                            options={[
                                { value: '', label: 'Select a sub-region' },
                                ...subRegions.map((subRegion) => ({
                                    value: subRegion.sub_Region_ID?.toString() || '',
                                    label: subRegion.sub_Region_Name || '',
                                })),
                            ]}
                            disabled={!stateSearch}
                        />
                    </div>
                    <div className="flex justify-between mb-4">
                        <div className="space-x-4">
                            <Button variant="secondary" onClick={handleClearSearch}>
                                Clear Search
                            </Button>
                            <Button type="submit">Search</Button>
                        </div>
                    </div>
                </form>
                <CourseTable
                    courses={courses}
                    onEdit={(course) => navigate(`/admin/courses/${course.course_ID}`)}
                    onDelete={(course) => {
                        if (window.confirm('Are you sure you want to delete this course?')) {
                          apiClient
                            .deleteLegacyCourse(course.course_ID ?? 0)
                            .then(() =>
                              setCourses(
                                courses.filter((c) => c.course_ID !== course.course_ID),
                              ),
                            );
                        }
                      }}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                    isLoading={isLoading}
                />
            </Card>
        </AdminLayout>
    );
};

export default CoursesList;
