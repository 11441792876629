import React, { useState } from 'react';
import Button from '../../components/common/Button';
import FormField from '../../components/common/FormField';
import { LegacyCountryDTO, LegacyUserDTO } from '../../services/apiClient';
import useStates from '../../hooks/useStates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import handleChange from '../../utils/formUtils/handleChange';

interface MemberFormProps {
  initialValues: LegacyUserDTO;
  countries: LegacyCountryDTO[];
  countriesLoading: boolean;
  countriesError: string | null;
  isCreating: boolean;
  onSubmit: (data: LegacyUserDTO) => void;
  onCancel: () => void;
}

const MemberForm: React.FC<MemberFormProps> = ({
  initialValues,
  countries,
  countriesLoading,
  countriesError,
  isCreating,
  onSubmit,
  onCancel,
}) => {
  const [formData, setFormData] = useState(initialValues);

  const [showPassword, setShowPassword] = useState(false);
  const { states } = useStates(formData.user_Country || null);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <FormField
          id="user_FName"
          label="First Name"
          type="text"
          value={formData.user_FName || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter first name"
        />
      </div>
      <div className="mb-4">
        <FormField
          id="user_LName"
          label="Last Name"
          type="text"
          value={formData.user_LName || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter last name"
        />
      </div>

      <div className="mb-4">
        <FormField
          id="user_Email"
          label="Email"
          type="email"
          value={formData.user_Email || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter email address"
        />
      </div>
      <div className="mb-4">
        <FormField
          id="user_Phone"
          label="Phone"
          type="text"
          value={formData.user_Phone || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter phone number"
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="user_Password"
          className="block text-sm font-medium text-gray-700"
        >
          Password
        </label>
        <div className="relative">
          <input
            id="user_Password"
            type={showPassword ? 'text' : 'password'}
            value={formData.user_Password || ''}
            onChange={(e) => handleChange(e, setFormData)}
            placeholder="Enter password"
            className="mt-1 block w-full px-4 py-2 border border-gray-300 shadow-sm rounded focus:ring-gray-300 focus:border-gray-300 sm:text-sm"
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500 focus:outline-none"
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </button>
        </div>
      </div>
      <div className="mb-4">
        <FormField
          id="user_Gender_ID"
          label="Gender"
          type="dropdown"
          value={formData.user_Gender_ID?.toString() || ''}
          onChange={(e) => handleChange(e, setFormData)}
          options={[
            { value: '', label: 'Select gender' }, // Placeholder option
            { value: '1', label: 'Male' },
            { value: '2', label: 'Female' },
          ]}
          placeholder="Select gender"
        />
      </div>

      <div className="mb-4">
        <FormField
          id="user_Emails_Flag"
          label="Emails Enabled"
          type="checkbox"
          value={formData.user_Emails_Flag || false}
          onChange={(e) => handleChange(e, setFormData)}
        />
      </div>
      <div className="mb-4">
        <FormField
          id="user_Country"
          label="Country"
          type="dropdown"
          value={formData.user_Country?.toString() || ''}
          onChange={(e) => handleChange(e, setFormData)}
          options={[
            { value: '', label: 'Select a country' },
            ...countries.map((country) => ({
              value: (country.country_ID ?? '').toString(),
              label: country.country_Description ?? '',
            })),
          ]}
          disabled={countriesLoading || !!countriesError}
        />
      </div>
      <div className="mb-4">
        <FormField
          id="user_State_ID"
          label="State"
          type="dropdown"
          value={formData.user_State_ID?.toString() || ''}
          onChange={(e) => handleChange(e, setFormData)}
          options={[
            { value: '', label: 'Select a state' },
            ...states.map((state) => ({
              value: (state.state_ID ?? '').toString(),
              label: state.state_Description ?? '',
            })),
          ]}
          disabled={!formData.user_Country}
        />
      </div>
      <div className="mb-4">
        <FormField
          id="user_Active"
          label="Active"
          type="checkbox"
          value={formData.user_Active || false}
          onChange={(e) => handleChange(e, setFormData)}
        />
      </div>
      <div className="mb-4">
        <FormField
          id="user_Push_Token"
          label="Push Token"
          type="text"
          disabled
          value={formData.user_Push_Token || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter push token"
        />
      </div>

      <div className="mb-4">
        <FormField
          id="user_Last_Online"
          label="Last Online"
          type="datetime"
          disabled
          value={
            formData.user_Last_Online
              ? new Date(formData.user_Last_Online).toISOString().slice(0, -1)
              : ''
          }
          onChange={(e) => handleChange(e, setFormData)}
        />
      </div>
      <div className="mb-4">
        <FormField
          id="user_Validated"
          label="Validated"
          type="checkbox"
          value={formData.user_Validated || false}
          onChange={(e) => handleChange(e, setFormData)}
        />
      </div>
      <div className="mb-4">
        <FormField
          id="logged_IN"
          label="Logged In"
          type="checkbox"
          value={formData.logged_IN || false}
          onChange={(e) => handleChange(e, setFormData)}
        />
      </div>
      <div className="flex justify-end space-x-4">
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit">{isCreating ? 'Create' : 'Save'}</Button>
      </div>
    </form>
  );
};

export default MemberForm;
