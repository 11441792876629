import React from 'react';
import {
  NavigationControl,
  FullscreenControl,
  GeolocateControl,
} from 'react-map-gl';
import StyleSwitcherControl from './StyleSwitcherControl';
import { MapRef } from 'react-map-gl';
import { FeatureCollection, Point } from 'geojson';

interface MapControlsProps {
  mapRef: React.RefObject<MapRef>; // Accept mapRef as a prop
  geoJsonData: FeatureCollection<Point>; // Accept geoJsonData as a prop
}

const MapControls: React.FC<MapControlsProps> = ({ mapRef, geoJsonData }) => {
  return (
    <>
      <NavigationControl position="top-right" />
      <FullscreenControl position="top-right" />
      <GeolocateControl position="top-right" trackUserLocation showUserHeading />
      {/* Ensure mapRef.current exists before passing it */}
      {/* {mapRef.current && (
        <StyleSwitcherControl
          map={mapRef.current.getMap()}
          geoJsonData={geoJsonData}
        />
      )} */}
    </>
  );
};

export default MapControls;
