import React from 'react';
import Table from '../../components/common/Table';
import { MemberHomeCoursesDTO } from '../../services/apiClient';

interface HomeCoursesTableProps {
  courses: MemberHomeCoursesDTO[];
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  pageSize: number;
  onPageSizeChange: (size: number) => void;
  isLoading: boolean;
}

const HomeCoursesTable: React.FC<HomeCoursesTableProps> = ({
    courses,
    currentPage,
    totalPages,
    onPageChange,
    pageSize,
    onPageSizeChange,
    isLoading,
}) => {
    return (
        <Table
            columns={[
                { label: 'Name', key: 'courseName' }
            ]}
            data={courses}
            actions={{

            }}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={onPageChange}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            isLoading={isLoading}
        />
    );
};

export default HomeCoursesTable;