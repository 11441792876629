import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { UserClient, ResetPasswordDTO } from '../../services/apiClient';
import AuthLayout from '../../components/layout/AuthLayout';
import FormField from '../../components/common/FormField';
import Button from '../../components/common/Button';
import { API_BASE_URL } from '../../config/config';

const ResetPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null); // Password validation state

  const location = useLocation(); // Hook to access URL params
  const navigate = useNavigate();

  // Extract token and email from the query string
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage(null);
    setSuccessMessage(null);
    setPasswordError(null);

    // Basic validation
    if (!newPassword || !confirmPassword) {
      setPasswordError('Both fields are required.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match.');
      return;
    }

    if (newPassword.length < 6) {
      setPasswordError('Password must be at least 6 characters long.');
      return;
    }

    const dto: ResetPasswordDTO = {
      email: email ?? '',
      token: token ?? '',
      newPassword,
    };

    try {
      const apiClient = new UserClient(API_BASE_URL);
      const response = await apiClient.resetPassword(dto);

      if (response && response.success) {
        setSuccessMessage('Your password has been reset successfully.');
        setTimeout(() => {
          navigate('/login'); // Redirect to login after success
        }, 2000);
      } else {
        setErrorMessage('Failed to reset password. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <AuthLayout
      title="How many courses have you played?"
      description="With over 30 years experience and an expansive network we have developed our GolfPlayed app that helps you to keep track of all the courses you have ever played. "
    >
      <div className="mt-8">
        {errorMessage && (
          <div className="mb-4 text-sm text-red-600">{errorMessage}</div>
        )}
        {successMessage && (
          <div className="mb-4 text-sm text-green-600">{successMessage}</div>
        )}
        <form onSubmit={handleSubmit}>
          <FormField
            id="newPassword"
            label="New Password"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Enter new password"
            error={passwordError ?? ''}
          />
          <FormField
            id="confirmPassword"
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm new password"
            error={passwordError ?? ''}
          />
          <div className="mt-6">
            <Button type="submit" className="w-full">
              Reset Password
            </Button>
          </div>
        </form>
        <p className="mt-6 text-sm text-center text-gray-400">
          Return to{' '}
          <Link
            to={'/login'}
            className="text-black focus:outline-none focus:underline hover:underline"
          >
            login
          </Link>
        </p>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
