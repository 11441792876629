import React, { createContext, useContext, useState } from 'react';

// Create a context with default values
const ViewContext = createContext<{
}>({
});

// ViewProvider to wrap around components that need access to some shared state
export const ViewProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

  return (
    <ViewContext.Provider value={{  }}>
      {children}
    </ViewContext.Provider>
  );
};

// Custom hook for accessing the view context
export const useView = () => useContext(ViewContext);
