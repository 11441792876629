import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/layout/AdminLayout';
import Card from '../../../components/common/Card';
import UserTable from '../../../components/tables/UserTable';
import Button from '../../../components/common/Button';
import FormField from '../../../components/common/FormField';
import {
  UserClient,
  UserDTO,
  SearchRequestDTO_userSearchRequestDTO,
} from '../../../services/apiClient';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../../config/config';
import { Plus } from 'lucide-react';
import AddNewButton from '../../../components/common/AddNewButton';

const UsersList: React.FC = () => {
  const [users, setUsers] = useState<UserDTO[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [firstNameSearch, setFirstNameSearch] = useState('');
  const [lastNameSearch, setLastNameSearch] = useState('');
  const [emailSearch, setEmailSearch] = useState('');

  const navigate = useNavigate();
  const breadcrumbs = [
    { label: 'Admin', href: '#' },
    { label: 'Users', href: '#' },
  ];

  const fetchUsers = async (page: number, size: number, filters = {}) => {
    const apiClient = new UserClient(API_BASE_URL);

    const searchRequest: SearchRequestDTO_userSearchRequestDTO = {
      filters: {
        firstName: firstNameSearch || '',
        lastName: lastNameSearch || '',
        email: emailSearch || '',
        phoneNumber: '',
        role: 'Admin',
        ...filters,
      },
      page,
      pageSize: size,
      sortBy: 'FirstName',
      sortDescending: false,
    };

    try {
      setIsLoading(true);
      const response = await apiClient.searchUsers(searchRequest);
      const totalRecords = response.data?.totalCount ?? 0;

      setUsers(response.data?.results as UserDTO[] || []);
      setTotalRecords(totalRecords);
      setTotalPages(Math.ceil(totalRecords / size));
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchUsers(1, pageSize);
  };

  const handleClearSearch = () => {
    setFirstNameSearch('');
    setLastNameSearch('');
    setEmailSearch('');
    fetchUsers(1, pageSize, {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    });
  };

  return (
    <AdminLayout
      title="Users"
      breadcrumbs={breadcrumbs}
      allowedRoles={['Admin']}
    >
      <Card>
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-bold text-gray-500 dark:text-gray-300">
            Manage Users ({totalRecords})
          </h2>
          <div className="ml-auto">
            <AddNewButton onClick={() => navigate('/admin/users/create')} />
          </div>
        </div>

        <form onSubmit={handleSearch} className="mb-4">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
            <FormField
              id="firstNameSearch"
              label="First Name"
              value={firstNameSearch}
              onChange={(e) => setFirstNameSearch(e.target.value)}
              placeholder="Search by first name"
            />
            <FormField
              id="lastNameSearch"
              label="Last Name"
              value={lastNameSearch}
              onChange={(e) => setLastNameSearch(e.target.value)}
              placeholder="Search by last name"
            />
            <FormField
              id="emailSearch"
              label="Email"
              value={emailSearch}
              onChange={(e) => setEmailSearch(e.target.value)}
              placeholder="Search by email"
            />
          </div>

          <div className="flex space-x-4 mb-4">
            <Button variant="secondary" onClick={handleClearSearch}>
              Clear Search
            </Button>
            <Button type="submit">Search</Button>
          </div>
        </form>

        <UserTable
          users={users}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          isLoading={isLoading}
          onEdit={(user) => navigate(`/admin/users/${user.id}`)} // Add navigation to edit user
          onDelete={(user) => {
            // Add a delete confirmation and delete logic here
          }}
        />
      </Card>
    </AdminLayout>
  );
};

export default UsersList;
