import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Button from '../common/Button';
import { PUBLIC_CONTAINER_URL } from '../../config/config';
import MediaQuery from 'react-responsive';
import NearestCourse from '../../interfaces/NearestCourse';

interface SearchBarProps {
  searchTerm: string;
  searchResults: NearestCourse[];
  handleSearchInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleResultClick: (course: any) => void;
  searchLoading: boolean;
  loadedCourses: number;
  totalCourses: number;
  onToggleViewClick: () => void;
  isListView: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({
  searchTerm,
  searchResults,
  handleSearchInput,
  handleResultClick,
  searchLoading,
  loadedCourses,
  totalCourses,
  onToggleViewClick,
  isListView,
}) => {
  const progressPercentage = totalCourses
    ? Math.round((loadedCourses / totalCourses) * 100)
    : 0;

  return (
    <div
      className="absolute top-4 z-50 w-full max-w-xl"
      style={{
        left: '50%',
        transform: 'translateX(-50%)',
        paddingLeft: '0.5rem', // Adds space on mobile
        paddingRight: '0.5rem',
        pointerEvents: 'auto', // Ensure search bar is clickable
      }}
    >
      {loadedCourses < totalCourses || totalCourses == 0 ? (
        <div className="flex flex-col justify-center items-center h-[80px] text-white text-lg">
          <div className="w-3/4 bg-gray-700 h-2 rounded-full overflow-hidden mb-2">
            <div
              className="h-full rounded-full"
              style={{
                width: `${progressPercentage}%`,
                backgroundColor: '#B1A466', // Updated color
              }}
            ></div>
          </div>
          {totalCourses === 0 ? (
            <div className="flex items-center space-x-2 text-sm text-white">
              {/* Spinner */}
              <div className="w-5 h-5 border-2 border-t-2 border-gray-300 border-t-white rounded-full animate-spin"></div>
              {/* Loading text */}
              <div>Loading course information ...</div>
            </div>
          ) : (
            <div className="text-sm">{`Loading ${loadedCourses} of ${totalCourses} courses (${progressPercentage}%)`}</div>
          )}
        </div>
      ) : (
        <div className="relative">
          <div className="flex items-center">
            <span className="absolute left-3 text-gray-500">
              <FontAwesomeIcon icon={faSearch} />
            </span>
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchInput}
              placeholder="Search courses..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#bcab64] focus:border-transparent"
            />
            <Button
              type="button"
              variant="gold"
              className="w-auto ml-2" // Full width on mobile, auto on larger screens
              onClick={onToggleViewClick}
            >
              {isListView ? 'Map' : 'List'}
            </Button>
            {searchLoading && (
              <div className="absolute right-3">
                <div className="w-5 h-5 border-2 border-t-2 border-gray-300 border-t-indigo-500 rounded-full animate-spin"></div>
              </div>
            )}
          </div>
          {searchResults.length > 0 && (
            <ul
              className="absolute mt-2 w-full bg-[#202020] rounded-lg max-h-64 overflow-y-auto"
              style={{ boxShadow: '0 3px 8px rgba(0, 0, 0, 1)' }}>
              {searchResults.map((course) => (
                <li
                  key={course.id}
                  onClick={() => handleResultClick(course)}
                  className="px-4 py-2 hover:bg-[#1c1c1c] cursor-pointer"
                >

                  <div className="flex items-center w-full space-x-4">

                    <MediaQuery minWidth={769}>
                      <div
                        className="w-10 h-10 rounded-full border-2"
                        style={{ borderColor: "#bcab64" }}>
                        <img
                          src={
                            course.logoImagePath
                              ? `${PUBLIC_CONTAINER_URL}/${course.logoImagePath}`
                              : `${PUBLIC_CONTAINER_URL}/course-logos/fallback.png`
                          }
                          alt={`${name} logo`}
                          className="w-full h-full object-cover rounded-full"
                        />
                      </div>
                    </MediaQuery>

                    <div>
                      <p className="text-white font-bold">{course.name}</p>
                      <p className="text-[#bcab64] text-sm">{course.state}, {course.country}, {parseFloat((course.distance / 1000).toFixed(2))}km</p>
                    </div>
                  </div>

                  <hr
                    className='mt-3'
                    style={{
                      border: 'none',
                      height: '1px',
                      width: '100%',
                      backgroundImage: 'linear-gradient(to right, transparent, white, transparent)', // Fading effect
                    }} />

                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
