import React from 'react';
import Table from '../../components/common/Table';
import { SubRegionDTO } from '../../services/apiClient';

interface SubRegionTableProps {
  services: SubRegionDTO[];
  handleEdit: (service: SubRegionDTO) => void;
  handleDelete: (service: SubRegionDTO) => void;
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  pageSize: number;
  setPageSize: (size: number) => void;
  isLoading: boolean;
}

const SubRegionTable: React.FC<SubRegionTableProps> = ({
  services,
  handleEdit,
  handleDelete,
  currentPage,
  totalPages,
  setCurrentPage,
  pageSize,
  setPageSize,
  isLoading,
}) => {
  return (
    <Table
      columns={[
        { label: 'SubRegion Name', key: 'sub_Region_Name' },
        { label: 'SubRegion Code', key: 'sub_Region_Code' },
        { label: 'Country', key: 'sub_Region_Country' },
        { label: 'State', key: 'sub_Region_State' },
      ]}
      data={services}
      actions={{
        onEdit: handleEdit,
        onDelete: handleDelete,
      }}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={setCurrentPage}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      isLoading={isLoading}
    />
  );
};

export default SubRegionTable;
