import AdminLayout from '../../../components/layout/AdminLayout';
import UserForm from '../../../components/forms/UserForm';
import { UserClient, UserCreateDTO } from '../../../services/apiClient';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../../config/config';
import Card from '../../../components/common/Card';
import React from "react";

const UserCreate: React.FC = () => {
  const navigate = useNavigate();
  const apiClient = new UserClient(API_BASE_URL);

  const breadcrumbs = [
    { label: 'Admin', href: '#' },
    { label: 'Users', href: '/admin/users' },
    { label: 'Create', href: '#' },
  ];

  const handleSubmit = async (data: UserCreateDTO) => {
    try {
      await apiClient.createUser(data);
      navigate('/admin/users');
    } catch (error) {
      console.error('Error creating user:', error);
    } finally {
    }
  };

  return (
    <AdminLayout
      title="Create User"
      breadcrumbs={breadcrumbs}
      allowedRoles={['Admin']}
    >
      <Card>
        <UserForm
          initialValues={{} as UserCreateDTO}
          onSave={handleSubmit}
          onCancel={() => navigate('/admin/users')}
          isCreating={true}
        />
      </Card>
    </AdminLayout>
  );
};

export default UserCreate;
