import React from "react";
import { useNavigate } from "react-router-dom";
import { LegacySubRegionClient, LegacySubRegionDTO } from "../../../services/apiClient";
import { API_BASE_URL } from "../../../config/config";
import useCountries from '../../../hooks/useCountries';
import AdminLayout from "../../../components/layout/AdminLayout";
import SubRegionForm from "../../../components/forms/SubRegionForm";
import Card from '../../../components/common/Card';

const SubRegionsCreate: React.FC = () => {
    const navigate = useNavigate();
    const apiClient = new LegacySubRegionClient(API_BASE_URL);

    const breadcrumbs = [
        { label: 'Admin', href: '#' },
        { label: 'Sub Regions', href: '#' },
        { label: 'Create', href: '#' },
    ];

    const { countries } = useCountries();

    const handleSubmit = async (data: LegacySubRegionDTO) => {
        try {
            await apiClient.createLegacySubRegion(data);
            navigate('/admin/sub-regions');
        } catch (error) {
            console.error('Error creating sub region:', error);
        }
    };

    return (
        <AdminLayout title="Create Sub Region" breadcrumbs={breadcrumbs} allowedRoles={['Admin']} >
            <Card>
                <SubRegionForm
                    initialValues={{} as LegacySubRegionDTO}
                    onSubmit={handleSubmit}
                    onCancel={() => navigate('/admin/sub-regions')}
                    isCreating={true}
                    countries={countries}
                    countriesLoading={false}
                    countriesError={null}
                />
            </Card>
        </AdminLayout>
    )
}

export default SubRegionsCreate;