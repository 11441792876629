import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Tippy from '@tippyjs/react'; // Import Tippy.js
import Pagination from './Pagination'; // Import the Pagination component
import 'tippy.js/dist/tippy.css'; // Tippy.js styles
import Loader from './Loader';

interface TableColumn {
  label: string;
  key: string;
  format?: (value: any) => string;
  isImage?: boolean;
  imageSize?: string;
  renderPlaceholder?: (row: any) => JSX.Element; // Custom placeholder rendering
}

interface TableProps {
  columns: TableColumn[];
  data: any[];
  actions?: {
    onEdit?: (row: any) => void;
    onDelete?: (row: any) => void;
  };
  currentPage: number;
  totalPages: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (size: number) => void;
  isLoading: boolean;
  noDataMessage?: string;
}

const Table: React.FC<TableProps> = ({
  columns,
  data,
  actions,
  currentPage,
  totalPages,
  pageSize,
  onPageChange,
  onPageSizeChange,
  isLoading,
  noDataMessage = 'No records found',
}) => {
  return (
    <div className="rounded-lg overflow-hidden">
      <table className="min-w-full bg-white border">
        <thead>
          <tr>
            {columns.map((col) => (
              <th
                key={col.key}
                className="px-4 py-2 border-b bg-gray-50 text-left text-sm font-semibold text-gray-700"
              >
                {col.label}
              </th>
            ))}
            {actions && (actions.onEdit || actions.onDelete) && (
              <th className="px-2 py-2 border-b bg-gray-50 text-left text-sm font-semibold text-gray-700 w-24">
                Actions
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={columns.length + (actions ? 1 : 0)} className="text-center py-4">
                <Loader />
              </td>
            </tr>
          ) : data.length === 0 ? (
            <tr>
              <td colSpan={columns.length + (actions ? 1 : 0)} className="text-center py-4 text-sm text-gray-500">
                {noDataMessage}
              </td>
            </tr>
          ) : (
            data.map((row, idx) => (
              <tr key={idx} className="hover:bg-gray-100 transition-colors">
                {columns.map((col) => (
                  <td key={col.key} className="px-4 py-2 border-b text-sm text-gray-700">
                    {col.isImage ? (
                      row[col.key] ? (
                        <img
                          src={row[col.key]}
                          alt={col.label}
                          className="rounded-full"
                          style={{ width: col.imageSize || '40px', height: col.imageSize || '40px' }}
                        />
                      ) : (
                        col.renderPlaceholder ? (
                          col.renderPlaceholder(row) // Render custom placeholder if provided
                        ) : (
                          <div
                            className="rounded-full bg-gray-300 text-white flex items-center justify-center"
                            style={{ width: col.imageSize || '40px', height: col.imageSize || '40px' }}
                          >
                            ?
                          </div>
                        )
                      )
                    ) : col.format ? (
                      col.format(row[col.key])
                    ) : (
                      row[col.key]
                    )}
                  </td>
                ))}
                {actions && (actions.onEdit || actions.onDelete) && (
                  <td className="px-4 py-2 border-b text-sm text-left">
                    <div className="flex space-x-2 justify-start">
                      {actions.onEdit && (
                        <Tippy content="Edit">
                          <button
                            onClick={() => actions.onEdit && actions.onEdit(row)}
                            className="text-white bg-black p-2 hover:bg-opacity-90 transition-colors"
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </button>
                        </Tippy>
                      )}
                      {actions.onDelete && (
                        <Tippy content="Delete">
                          <button
                            onClick={() => actions.onDelete && actions.onDelete(row)}
                            className="text-white bg-red-500 p-2 hover:bg-red-600 transition-colors"
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        </Tippy>
                      )}
                    </div>
                  </td>
                )}
              </tr>
            ))
          )}
        </tbody>
      </table>

      {/* Pagination */}
      {!isLoading && data.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
        />
      )}
    </div>
  );
};

export default Table;
