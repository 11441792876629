import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCreditCard,
    faUsers,
    faPlus,
    faCalendarDay
} from '@fortawesome/free-solid-svg-icons';

import '../../styles/navigation.css';

import { PUBLIC_CONTAINER_URL } from '../../config/config';

//COMPONENTS - COMMON
import Button from '../../components/common/Button';
import AvatarGrid from '../../components/common/AvatarGrid';

//COMPONENTS - CARDS
import TeeCard from "../../components/cards/TeeCard";
import FriendCard from "../../components/cards/FriendCard";

//UTILS
import { confirmAction, showAlert } from '../../utils/sweetAlertUtils';

const TeeTimeBookings: React.FC = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const course = location.state?.course;

    const [currentStage, setCurrentStage] = useState<number>(1);
    const [currentSegment, setCurrentSegment] = useState<number>(1);
    const [players, setPlayers] = useState<any[]>([]);

    useEffect(() => {
        if (!course) {
            navigate('/explore/map');
        }
    }, [course]);

    const handleNext = async () => {
        if (currentStage < 3)
            setCurrentStage(currentStage + 1);
    };

    const handleBack = () => {
        if (currentStage > 1)
            setCurrentStage(currentStage - 1);
    };

    const handleMap = () => {
        navigate('/explore/map');
    };

    const handleAddPlayer = (playerData: { name: string; avatar?: string }) => {
        if (players.length >= 4) {
            showAlert({
                icon: 'warning',
                title: 'Warning',
                text: 'Maximum of 4 players allowed.',
                confirmButtonText: 'OK',
            });
            return;
        }

        setPlayers([...players, { name: playerData.name, avatar: playerData.avatar }]);
    };

    const handleDeletePlayer = (index: number) => {
        const updatedPlayers = players.filter((_, idx) => idx !== index);
        setPlayers(updatedPlayers);
    };

    const handlePayment = async () => {
        setCurrentStage(1);
        showAlert({
            icon: 'success',
            title: 'Success',
            text: 'Booking Successful',
            confirmButtonText: 'Confirm',
        });
        return;
    };

    const handlePlayerConfig = async (index: number) => {
        showAlert({
            icon: 'info',
            title: `Player Update Info`,
            text: `Update ${players[index].name}`,
            confirmButtonText: 'OK',
        });
        return;
    };

    return (
        <div className="bg-[#202020]" style={{ height: '100vh', width: '100%', position: 'relative' }}>

            <div style={{ height: '24vh', width: '100%', overflow: 'scroll' }}>

                {/* Tabs/Progress Indicator */}
                <div className="flex justify-around">
                    <div className={`py-2 px-4 border-b-2 ${currentStage === 1 ? 'border-[#c1b06b]' : 'border-customPrimary'}`}>
                        <FontAwesomeIcon
                            icon={faCalendarDay}
                            className={`text-xs ${currentStage === 1 ? 'text-white' : 'text-customPrimary'}`}
                        />
                    </div>
                    <div className={`py-2 px-4 border-b-2 ${currentStage === 2 ? 'border-[#c1b06b]' : 'border-customPrimary'}`}>
                        <FontAwesomeIcon
                            icon={faUsers}
                            className={`text-xs ${currentStage === 2 ? 'text-white' : 'text-customPrimary'}`}
                        />
                    </div>
                    <div className={`py-2 px-4 border-b-2 ${currentStage === 3 ? 'border-[#c1b06b]' : 'border-customPrimary'}`}>
                        <FontAwesomeIcon
                            icon={faCreditCard}
                            className={`text-xs ${currentStage === 3 ? 'text-white' : 'text-customPrimary'}`}
                        />
                    </div>
                </div>

                {/* Top Section */}
                <div className="pl-8 pt-4 pb-4 relative flex flex-col items-center">
                    <div className="flex items-center w-full space-x-4">
                        {/* Logo */}
                        <div
                            className="w-14 h-14 border-2"
                            style={{ borderColor: "#fff" }}>
                            <img
                                src={
                                    course.logoImagePath
                                        ? `${PUBLIC_CONTAINER_URL}/${course.logoImagePath}`
                                        : `${PUBLIC_CONTAINER_URL}/course-logos/fallback.png`
                                }
                                alt={`${course} logo`}
                                className="w-full h-full object-cover"
                            />
                        </div>

                        {/* Course Details */}
                        <div className="text-left">
                            <h1 className="text-white font-bold text-lg">{course?.name}</h1>
                            <p className="text-white text-xs">{course?.state}, {course?.country} {course?.distance}km</p>
                        </div>

                    </div>
                </div>

                <div className="pl-4 pr-4">
                    {currentStage === 1 && (
                        <div className="flex justify-around mb-4">
                            <div
                                className={`py-2 px-4 border-b-2 cursor-pointer ${currentSegment === 1 ? 'border-[#c1b06b]' : 'border-customPrimary'}`}
                                onClick={() => setCurrentSegment(1)}>
                                <p className={`text-lg text-white`}>Front nine</p>
                            </div>
                            <div
                                className={`py-2 px-4 border-b-2 cursor-pointer ${currentSegment === 2 ? 'border-[#c1b06b]' : 'border-customPrimary'}`}
                                onClick={() => setCurrentSegment(2)}>
                                <p className={`text-lg text-white`}>Back nine</p>
                            </div>
                        </div>
                    )}

                    {currentStage === 2 && (
                        <div className="width-full">
                            <AvatarGrid players={players} onDeletePlayer={handleDeletePlayer} />
                        </div>
                    )}

                    {currentStage === 3 && (
                        <>
                        </>
                    )}
                </div>
            </div>

            <div style={{ height: '64vh', width: '100%', overflow: 'scroll' }}>
                <div className="pl-4 pr-4">


                    {/* SELECT SEGMENT AND TEE TIME */}
                    {currentStage === 1 && (
                        <div className="pl-2 pr-2 pb-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            {currentSegment === 1 && (
                                <>
                                    <TeeCard onClick={handleNext}
                                        time={'07:00'}
                                        segment={'Front Nine'}
                                        footer={"From R390 p/p"}
                                        players={players}>
                                    </TeeCard>
                                    <TeeCard onClick={handleNext}
                                        time={'07:10'}
                                        segment={'Front Nine'}
                                        footer={"From R390 p/p"}
                                        players={[
                                            { avatar: '/images/user.png', name: 'Emma' },
                                            { avatar: '/images/user.png', name: 'Liam' },]}>
                                    </TeeCard>
                                    <TeeCard onClick={handleNext}
                                        time={'07:10'}
                                        segment={'Front Nine'}
                                        footer={"Fully Booked"}
                                        players={[
                                            { avatar: '/images/user.png', name: 'Sophia' },
                                            { avatar: '/images/user.png', name: 'Noah' },
                                            { avatar: '/images/user.png', name: 'Olivia' },
                                            { avatar: '/images/user.png', name: 'Ethan' }]}>
                                    </TeeCard>
                                    <TeeCard onClick={handleNext}
                                        time={'07:10'}
                                        segment={'Front Nine'}
                                        footer={"Fully Booked"}
                                        players={[
                                            { avatar: '/images/user.png', name: 'Ava' },
                                            { avatar: '/images/user.png', name: 'Lucas' },
                                            { avatar: '/images/user.png', name: 'Mia' },
                                            { avatar: '/images/user.png', name: 'Mason' }]}>
                                    </TeeCard>
                                </>
                            )}
                            {currentSegment === 2 && (
                                <>
                                    <TeeCard onClick={handleNext}
                                        time={'07:10'}
                                        segment={'Front Nine'}
                                        footer={"From R390 p/p"}
                                        players={[
                                            { avatar: '/images/user.png', name: 'Charlotte' },
                                            { avatar: '/images/user.png', name: 'James' },]}>
                                    </TeeCard>
                                    <TeeCard onClick={handleNext}
                                        time={'07:10'}
                                        segment={'Front Nine'}
                                        footer={"Fully Booked"}
                                        players={[
                                            { avatar: '/images/user.png', name: 'Amelia' },
                                            { avatar: '/images/user.png', name: 'Benjamin' },
                                            { avatar: '/images/user.png', name: 'Harper' },
                                            { avatar: '/images/user.png', name: 'Henry' }]}>
                                    </TeeCard>
                                    <TeeCard onClick={handleNext}
                                        time={'07:10'}
                                        segment={'Front Nine'}
                                        footer={"Fully Booked"}
                                        players={[
                                            { avatar: '/images/user.png', name: 'Evelyn' },
                                            { avatar: '/images/user.png', name: 'Alexander' },
                                            { avatar: '/images/user.png', name: 'Ella' },
                                            { avatar: '/images/user.png', name: 'William' }]}>
                                    </TeeCard>
                                </>
                            )}

                        </div>
                    )}

                    {/* SELECT PLAYERS */}
                    {currentStage === 2 && (
                        <>
                            <div className="mb-3 mt-3">
                                <FriendCard
                                    name="Andrew"
                                    surname="Georgiou"
                                    avatar="/images/user.png"
                                    matchesInCommon={1}
                                    onClick={(data) => handleAddPlayer(data)}
                                />
                            </div>
                            <div className="mb-3 mt-3">
                                <FriendCard
                                    name="Rudolph"
                                    surname="Fourie"
                                    avatar="/images/user.png"
                                    matchesInCommon={1}
                                    onClick={(data) => handleAddPlayer(data)}
                                />
                            </div>
                            <div className="mb-3 mt-3">
                                <FriendCard
                                    name="Marius"
                                    surname="Fourie"
                                    avatar="/images/user.png"
                                    matchesInCommon={1}
                                    onClick={(data) => handleAddPlayer(data)}
                                />
                            </div>
                            <div className="mb-3 mt-3">
                                <FriendCard
                                    name="Mandi"
                                    surname="Fourie"
                                    avatar="/images/user.png"
                                    matchesInCommon={1}
                                    onClick={(data) => handleAddPlayer(data)}
                                />
                            </div>
                            <div className="mb-3 mt-3">
                                <FriendCard
                                    name="Gert"
                                    surname="Grobelaar"
                                    avatar="/images/user.png"
                                    matchesInCommon={1}
                                    onClick={(data) => handleAddPlayer(data)}
                                />
                            </div>
                        </>
                    )}

                    {currentStage === 3 && (
                        <div className="p-6 text-white">

                            {/* Tee Time */}
                            <div className="mb-6">
                                <p><strong>Tee Time:</strong> 07:00</p>
                                <p><strong>Course Segment:</strong> Front Nine</p>
                            </div>

                            {/* Player Details */}
                            <div className="mb-6">
                                <h3 className="font-bold">Players</h3>
                                <ul className="mt-2 space-y-2">
                                    {players.map((player, index) => (
                                        <li key={index} className="flex items-center space-x-4">
                                            <img
                                                src={player.avatar || '/images/user.png'}
                                                alt={`${player.name} avatar`}
                                                className=" w-12 h-12 
                                                        rounded-full 
                                                        flex items-center justify-center
                                                        bg-[#202020]
                                                        overflow-hidden
                                                        border-2 border border-[#c1b06b]
                                                        cursor-pointer"
                                            />
                                            <p>{player.name}</p>
                                            <button
                                                onClick={() => handlePlayerConfig(index)}
                                                className="card-icon-button text-gold hover:text-gold-dark"
                                            >
                                                <FontAwesomeIcon icon={faPlus} />
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Pricing */}
                            <div className="mb-6">
                                <p><strong>Price per Player:</strong> R390</p>
                                <p><strong>Total:</strong> R{players.length * 390}</p>
                            </div>

                        </div>
                    )}

                </div>
            </div>

            <div className="stepper-button-container">
                <Button
                    type="button"
                    variant="gold-outline"
                    className="stepper-button-right"
                    onClick={currentStage > 1 ? handleBack : handleMap}>
                    {currentStage > 1 ? 'Back' : 'Map'}
                </Button>
                <Button
                    type="button"
                    variant="gold-outline"
                    className="stepper-button-left"
                    onClick={currentStage < 3 ? handleNext : handlePayment}>
                    {currentStage < 3 ? 'Next' : 'Pay'}
                </Button>
            </div>

        </div>
    )

}

export default TeeTimeBookings;