import React, { useState, useEffect } from "react";
import AdminLayout from '../../../components/layout/AdminLayout';
import Card from '../../../components/common/Card';
import { LegacySubRegionClient, LegacySubRegionDTO } from "../../../services/apiClient";
import SubRegionForm from "../../../components/forms/SubRegionForm";
import { API_BASE_URL } from '../../../config/config';
import { useNavigate, useParams } from 'react-router-dom';
import useCountries from '../../../hooks/useCountries';

const SubRegionsEdit: React.FC = () => {
    const [subRegion, setSubRegion] = useState<LegacySubRegionDTO | null>(null);
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const apiClient = new LegacySubRegionClient(API_BASE_URL);

    const { countries } = useCountries();

    const breadcrumbs = [
        { label: 'Admin', href: '#' },
        { label: 'Sub Region', href: '/admin/sub-regions' },
        { label: 'Edit', href: '#' },
    ];

    useEffect(() => {
        const fetchSubRegion = async () => {
          try {
            const response = await apiClient.getLegacySubRegionById(Number(id!));
            setSubRegion(response.data as LegacySubRegionDTO);
          } catch (error) {
            console.error('Error fetching sub-region:', error);
          }
        };
        fetchSubRegion();
      }, [id]);

    const handleSubmit = async (data: LegacySubRegionDTO) => {
        try {
            await apiClient.updateLegacySubRegion(Number(id!), data);
            navigate('/admin/sub-regions');
        } catch (error) {
            console.error('Error updating sub-region:', error);
        }
    };

    return (
        <AdminLayout
            title="Edit Sub-Region"
            breadcrumbs={breadcrumbs}
            allowedRoles={['Admin']}
        >
            <Card>
                {subRegion && (
                    <SubRegionForm
                        initialValues={subRegion}
                        onSubmit={handleSubmit}
                        onCancel={() => navigate('/admin/sub-regions')}
                        isCreating={false}
                        countries={countries}
                        countriesLoading={false}
                        countriesError={null}
                    />
                )}
            </Card>
        </AdminLayout>
    );
};

export default SubRegionsEdit