import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ViewProvider } from '../components/layout/ViewContext'; // Import ViewProvider

// Import our new route component
import AdminRoutes from './routes/AdminRoutes';
import MemberRoutes from './routes/MemberRoutes';
import PublicRoutes from './routes/PublicRoutes';

const AppRouter = () => {
  return (
    <ViewProvider> {/* Wrap all routes in the ViewProvider */}
      <Router>
        <Routes>
          <Route path="*" element={<PublicRoutes />} />
          <Route path="/admin/*" element={<AdminRoutes />} />
          <Route path="/member/*" element={<MemberRoutes />} />
        </Routes>
      </Router>
    </ViewProvider>
  );
};

export default AppRouter;
