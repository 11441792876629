import React from "react";

const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    setFormData: React.Dispatch<React.SetStateAction<Record<string, any>>>
  ) => {
    const { id, value } = e.target;
   
    let newValue: string | number | boolean | undefined = value;
   
    if (e.target instanceof HTMLInputElement) {
      if (e.target.type === "checkbox") {
        newValue = e.target.checked; // Handle checkboxes
      } else if (e.target.type === "number") {
        newValue = value ? parseInt(value, 10) : undefined; // Handle number inputs
      }
    } else if (e.target instanceof HTMLSelectElement) {
      newValue = value ? parseInt(value, 10) : undefined; // Ensure dropdown selections are converted to numbers
    }
   
    setFormData((prev) => ({
      ...prev,
      [id]: newValue,
    }));
   
};

export default handleChange;