import React from "react";
import { useNavigate } from 'react-router-dom';

//COMPONENTS
import CourseListCard from "../cards/CourseListCard";
import CourseDisplayCard from "../cards/CourseDisplayCard";

//INTERFACES
import { NearestCourse } from "../../interfaces/NearestCourse";

interface CourseListProps {
    courses: NearestCourse[];
    viewType: "mobile" | "desktop"; // Mobile or desktop view type
    isLoggedIn: boolean;
    isPlayedStatusLoading: boolean;
    coursesPlayed: { [key: string]: boolean; };
    setIsLoggedIn: (loggedIn: boolean) => void;
    showLogin: boolean;
    setShowLogin: (showLogin: boolean) => void;
    setSelectedCourse: (selectedCourse: NearestCourse) => void;
    updateCoursePlayed: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | null, course:NearestCourse) => void;
}

const CourseList: React.FC<CourseListProps> = ({ 
    courses, 
    viewType,
    isLoggedIn,
    isPlayedStatusLoading,
    coursesPlayed,
    setIsLoggedIn,
    showLogin,
    setShowLogin,
    setSelectedCourse,
    updateCoursePlayed
}) => {

    const navigate = useNavigate();
    const handleTeeTimesClick = (course: NearestCourse) => {
        navigate('/member/tee-time-booking', { state: { course } });
    };

    return (
        <div className="p-4 space-y-4">
            {viewType === "mobile" ? (
                courses.map((course) => (
                    <CourseListCard
                        key={course.id}
                        id={course.id}
                        name={course.name}
                        state={course.state}
                        country={course.country}
                        distance={parseFloat((course.distance / 1000).toFixed(2))} // Convert to km
                        logoImagePath={course.logoImagePath}
                        isLoggedIn={isLoggedIn}
                        isPlayedStatusLoading={isPlayedStatusLoading}
                        coursePlayedStatus={coursesPlayed[course.id] ?? false}
                        setIsLoggedIn={setIsLoggedIn}
                        showLogin={showLogin}
                        setShowLogin={setShowLogin}
                        onCoursePlayedClick={(e) => {
                            setSelectedCourse(course);
                            updateCoursePlayed(e,course);
                        }}
                        onRequestToPlayClick={() => alert(`Request to play: ${course.name}`)}
                        onBucketListClick={() => alert(`Bucket List for: ${course.name}`)}
                        onTeeTimesClick={handleTeeTimesClick}
                    />
                ))
            ) : (
                // Desktop view: Render a grid layout
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {courses.map((course) => (
                        <CourseDisplayCard
                            key={course.id}
                            id={course.id}
                            name={course.name}
                            state={course.state}
                            country={course.country}
                            distance={parseFloat((course.distance / 1000).toFixed(2))}
                            headerImagePath={course.headerImagePath}
                            logoImagePath={course.logoImagePath}
                            isLoggedIn={isLoggedIn}
                            isPlayedStatusLoading={isPlayedStatusLoading}
                            coursePlayedStatus={coursesPlayed[course.id] ?? false}
                            setIsLoggedIn={setIsLoggedIn}
                            showLogin={showLogin}
                            setShowLogin={setShowLogin}
                            onChange={(e) => {
                                setSelectedCourse(course);
                                updateCoursePlayed(e,course);
                            }}
                            onRequestToPlayClick={() => alert(`Booking for: ${course.name}`)}
                            onTeeTimesClick={handleTeeTimesClick}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default CourseList;
