import React, { useState } from 'react';
import FormField from './FormField';
import { LoginUserDTO, UserClient } from '../../services/apiClient';
import Button from './Button';
import { API_BASE_URL } from '../../config/config';
import Modal from './Modal';

interface LoginProps {
    onLogin: () => void;
    isShowing: boolean;
    setIsShowing: (showing: boolean) => void;
    setIsLoggedIn: (loggedIn: boolean) => void;
}

const LoginModal: React.FC<LoginProps> = ({
    onLogin,
    isShowing,
    setIsShowing,
    setIsLoggedIn
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [loginData, setLoginData] = useState<LoginUserDTO>({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    try {
      setLoading(true);
      const apiClient = new UserClient(API_BASE_URL);
      const response = await apiClient.loginUser(loginData);

      if (response && response.success && response.data) {
        localStorage.setItem('token', response.data);
        setIsLoggedIn(true);
        setErrorMessage('');
        setIsShowing(false);
        onLogin();
      }
    } catch (error) {
      setErrorMessage('Invalid credentials. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal title='Login' subTitle='Please enter email and password' isShowing={isShowing} setIsShowing={setIsShowing}>
      {errorMessage && <p className="text-sm text-red-500">{errorMessage}</p>}
      <FormField
        id="email"
        label=""
        type="email"
        placeholder="Email"
        value={loginData.email}
        onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
      />
      <FormField
        id="password"
        label=""
        type="password"
        placeholder="Password"
        value={loginData.password}
        onChange={(e) =>
          setLoginData({ ...loginData, password: e.target.value })
        }
      />
      <Button
        type="button"
        variant="gold"
        className="w-full mb-4"
        onClick={handleLogin}
        isLoading={loading}
      >
        Login
      </Button>
    </Modal>
  );
};

export default LoginModal;
