import React from 'react';
import { Check, X } from 'lucide-react';

interface PasswordRequirementsProps {
    validation: {
        length: boolean;
        uppercase: boolean;
        lowercase: boolean;
        digit: boolean;
    };
}

const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({ validation }) => {
    return (
        <div className="mt-4 mb-4 bg-gray-50 dark:bg-gray-800 p-4 rounded-md text-sm text-gray-600 dark:text-gray-300 text-left">
            <h3 className="font-semibold mb-2 text-gray-800 dark:text-gray-200">
                Password Requirements:
            </h3>
            <ul className="list-disc list-inside">
                <li className="flex items-center">
                    {validation.length ? (
                        <Check className="text-emerald-500 w-4 h-4 mr-2" />
                    ) : (
                        <X className="text-red-500 w-4 h-4 mr-2" />
                    )}
                    At least 6 characters long
                </li>
                <li className="flex items-center">
                    {validation.uppercase ? (
                        <Check className="text-emerald-500 w-4 h-4 mr-2" />
                    ) : (
                        <X className="text-red-500 w-4 h-4 mr-2" />
                    )}
                    At least one uppercase letter (A-Z)
                </li>
                <li className="flex items-center">
                    {validation.lowercase ? (
                        <Check className="text-emerald-500 w-4 h-4 mr-2" />
                    ) : (
                        <X className="text-red-500 w-4 h-4 mr-2" />
                    )}
                    At least one lowercase letter (a-z)
                </li>
                <li className="flex items-center">
                    {validation.digit ? (
                        <Check className="text-emerald-500 w-4 h-4 mr-2" />
                    ) : (
                        <X className="text-red-500 w-4 h-4 mr-2" />
                    )}
                    At least one digit (0-9)
                </li>
            </ul>
        </div>
    );
};

export default PasswordRequirements;
