interface MetricCardProps {
  title: string;
  value: string;
  icon: JSX.Element;
  changePercentage: string;
  changeColor: string;
  description: string;
}

const MetricCard: React.FC<MetricCardProps> = ({
  title,
  value,
  icon,
  changePercentage,
  changeColor,
  description,
}) => {
  return (
    <div className="bg-white shadow p-6 relative">
      {/* Icon */}
      <div className="absolute top-4 right-4">
        {icon}
      </div>
      
      {/* Title */}
      <h4 className="text-gray-600 font-semibold">{title}</h4>
      
      {/* Value */}
      <p className="text-3xl font-bold text-gray-600">{value}</p>
      
      {/* Change Percentage */}
      <p className={`${changeColor} text-sm font-semibold`}>
        {changePercentage} <span>↗</span>
      </p>

      {/* Description */}
      <p className="text-gray-500 text-sm">{description}</p>
    </div>
  );
};

export default MetricCard;
