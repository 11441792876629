import { useEffect, useState } from 'react';
import { LegacyCountryClient, LegacyCountryDTO } from '../services/apiClient'; // Adjust the import paths as needed
import { API_BASE_URL } from '../config/config';

const useCountries = () => {
  const [countries, setCountries] = useState<LegacyCountryDTO[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const countryClient = new LegacyCountryClient(API_BASE_URL);

  useEffect(() => {
    const fetchCountries = async () => {
      setIsLoading(true);
      try {
        const response = await countryClient.getAllLegacyCountries();
        if (response && response.success && response.data) {
          setCountries(response.data);
        } else {
          setError('Failed to fetch countries');
        }
      } catch (err) {
        setError('Error fetching countries');
        console.error('Error fetching countries:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCountries();
  }, []);

  return { countries, isLoading, error };
};

export default useCountries;
