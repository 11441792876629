import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/layout/AdminLayout';
import Card from '../../../components/common/Card';
import CountryTable from '../../../components/tables/CountryTable';
import FormField from '../../../components/common/FormField';
import Button from '../../../components/common/Button';
import AddNewButton from '../../../components/common/AddNewButton';
import {
  LegacyCountryClient,
  LegacyCountryDTO,
} from '../../../services/apiClient';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../../config/config';

const CountryList: React.FC = () => {
  const [countries, setCountries] = useState<LegacyCountryDTO[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [descriptionSearch, setDescriptionSearch] = useState<string>('');
  const navigate = useNavigate();
  const apiClient = new LegacyCountryClient(API_BASE_URL);

  const breadcrumbs = [
    { label: 'Admin', href: '#' },
    { label: 'Countries', href: '#' },
  ];

  const fetchCountries = async (filters = {}) => {
    try {
      setIsLoading(true);
      const response = await apiClient.searchLegacyCountries({
        filters: { country_Description: descriptionSearch || undefined, ...filters },
        page: currentPage,
        pageSize,
      });
      setCountries((response.data?.results as LegacyCountryDTO[]) || []);
      setTotalRecords(response.data?.totalCount || 0);
      setTotalPages(Math.ceil((response.data?.totalCount || 0) / pageSize));
    } catch (error) {
      console.error('Error fetching countries:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, [currentPage, pageSize]);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    setCurrentPage(1);
    fetchCountries({ descriptionSearch });
  };

  const handleClearSearch = () => {
    setDescriptionSearch('');
    fetchCountries({ country_Description: '' });
  };

  return (
    <AdminLayout
      title="Countries"
      breadcrumbs={breadcrumbs}
      allowedRoles={['Admin']}
    >
      <Card>
        <div className="flex justify-between mb-4">
          <h2 className="text-lg font-bold text-gray-500">
            Manage Countries ({totalRecords})
          </h2>
          <AddNewButton onClick={() => navigate('/admin/countries/create')} />
        </div>
        <form onSubmit={handleSearch} className="mb-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
            <FormField
              id="descriptionSearch"
              label="Description"
              type="text"
              value={descriptionSearch}
              onChange={(e) => setDescriptionSearch(e.target.value)}
              placeholder="Enter description"
            />
          </div>
          <div className="flex space-x-4 mb-4">
            <Button variant="secondary" onClick={handleClearSearch}>Clear Search</Button>
            <Button type="submit">Search</Button>
          </div>
        </form>
        <CountryTable
          data={countries}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          isLoading={isLoading}
          onEdit={(country: LegacyCountryDTO) =>
            navigate(`/admin/countries/${country.country_ID}`)
          }
          onDelete={(country: LegacyCountryDTO) => {
            if (
              window.confirm('Are you sure you want to delete this country?')
            ) {
              apiClient
                .deleteLegacyCountry(country.country_ID ?? 0)
                .then(() => fetchCountries());
            }
          }}
        />
      </Card>
    </AdminLayout>
  );
};

export default CountryList;
