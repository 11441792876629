import React, { useState } from 'react';
import Button from '../../components/common/Button';
import FormField from '../../components/common/FormField';
import { LegacyCountryDTO } from '../../services/apiClient';
import handleChange from '../../utils/formUtils/handleChange';

interface CountryFormProps {
  initialValues: LegacyCountryDTO;
  onSave: (data: LegacyCountryDTO) => void;
  onCancel: () => void;
  isCreating: boolean;
}

const CountryForm: React.FC<CountryFormProps> = ({ initialValues, onSave, onCancel, isCreating }) => {
  const [formData, setFormData] = useState(initialValues);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <FormField
          id="country_Description"
          label="Description"
          type="text"
          value={formData.country_Description || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter description"
        />
      </div>
      <div className="mb-4">
        <FormField
          id="country_Var_ID"
          label="Country Code"
          type="text"
          value={formData.country_Var_ID || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter country code"
        />
      </div>
      <div className="flex justify-end space-x-4">
        <Button variant="secondary" onClick={onCancel}>Cancel</Button>
        <Button type="submit">{isCreating ? 'Create' : 'Save'}</Button>
      </div>
    </form>
  );
};

export default CountryForm;
