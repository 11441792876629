import React, { useEffect, useRef, useState } from 'react';
import Map, { MapRef } from 'react-map-gl';
import debounce from 'lodash.debounce';
import { FeatureCollection, Feature, Point, GeoJsonProperties } from 'geojson';

//CONFIG
import { API_BASE_URL, MAP_BOX_KEY } from '../../config/config';
import {
  CourseClient,
  CourseDTO,
  CoursesPlayedClient,
  MemberCoursesPlayedDTO,
} from '../../services/apiClient';
import {
  clusterCountLayer,
  clusterLayer,
  unclusteredPointLayerWithLabel,
} from '../../config/mapLayersConfig';

//COMPONENTS
import CourseDataHandler from '../../components/common/CourseDataHandler';
import MapControls from '../../components/maps/MapControls';
import MapLayers from '../../components/maps/MapLayers';
import NearestCourses, {
  NearestCoursesRef,
} from '../../components/maps/NearestCourses';
import SearchBar from '../../components/maps/SearchBar';
import UserLocationMarker from '../../components/maps/UserLocationMarker';

//WRAPPERS
import CourseListWrapper from '../../components/wrappers/CourseListWrapper';

//INTERFACES
import CourseFeatureProperties from '../../interfaces/courseFeatureProperties';
import NearestCourse from '../../interfaces/NearestCourse';

//UTILS
import handleMapLoad from '../../utils/mapLoadHandler';
import { getMemberId } from '../../utils/tokenUtils';
import LoginModal from '../../components/common/LoginModal';

// Distance calculation using Haversine formula
const calculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
): number => {
  const R = 6371e3; // Earth's radius in meters
  const toRadians = (deg: number) => (deg * Math.PI) / 180;

  const φ1 = toRadians(lat1);
  const φ2 = toRadians(lat2);
  const Δφ = toRadians(lat2 - lat1);
  const Δλ = toRadians(lon2 - lon1);

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in meters
};

const DEFAULT_LOCATION = { latitude: -33.9249, longitude: 18.4241 }; // Cape Town, South Africa

const ExploreMap = () => {
  const [viewState, setViewState] = useState({
    longitude: -122.4376,
    latitude: 37.7577,
    zoom: 10,
  });

  const [geoJsonData, setGeoJsonData] = useState<FeatureCollection<Point>>({
    type: 'FeatureCollection',
    features: [],
  });
  const nearestCoursesRef = useRef<NearestCoursesRef>(null);
  const [lazyLoadPageSize, setlazyLoadPageSize] = useState<number>(5000);
  const [searchResults, setSearchResults] = useState<NearestCourse[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [userLocation, setUserLocation] = useState<{
    latitude: number;
    longitude: number;
  }>();
  const [showCourseDetilas, setShowCourseDetilas] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState<NearestCourse | null>(
    null,
  );
  const [searchLoading, setSearchLoading] = useState(false);
  const [coursesLoading, setCoursesLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCourses, setTotalCourses] = useState(0);
  const [loadedCourses, setLoadedCourses] = useState(0);
  const [sortedCourses, setSortedCourses] = useState<NearestCourse[]>([]);
  const [coursesPlayed, setCoursePlayed] = useState({});
  const [isPlayedStatusLoading, setIsPlayedStatusLoading] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const [isListView, setIsListView] = useState(false);

  const mapRef = useRef<MapRef | null>(null);
  const apiClient = new CourseClient(API_BASE_URL);

  const coursePlayedClient = new CoursesPlayedClient(API_BASE_URL);
  const memberId = getMemberId() ?? '';

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
    const token = localStorage.getItem('token');
    return !!token;
  });

  // Fetch user location immediately on component mount
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
          setViewState((prev) => ({ ...prev, latitude, longitude, zoom: 10 }));
          console.log('User location fetched:', { latitude, longitude });
        },
        (error) => {
          console.error('Error fetching user location:', error);
          setUserLocation(DEFAULT_LOCATION); // Fallback to default location
          setViewState((prev) => ({
            ...prev,
            latitude: DEFAULT_LOCATION.latitude,
            longitude: DEFAULT_LOCATION.longitude,
            zoom: 10,
          }));
        },
        { enableHighAccuracy: true, timeout: 5000 }, // Fast geolocation settings
      );
    } else {
      console.warn('Geolocation not available. Using default location.');
      setUserLocation(DEFAULT_LOCATION);
    }
  }, []);

  // Lazy load courses only when userLocation is available
  useEffect(() => {
    if (!userLocation) return;

    const fetchCourses = async () => {
      if (coursesLoading || (loadedCourses >= totalCourses && totalCourses > 0))
        return;

      try {
        setCoursesLoading(true);

        const response = await apiClient.searchCoursesMinified({
          filters: {
            name: '',
            active: true,
          },
          page,
          pageSize: lazyLoadPageSize,
          sortBy: '',
          sortDescending: false,
          tryUseCache: true,
        });

        if (response.success && response.data) {
          const courses = (response.data.results as CourseDTO[]) || [];
          const totalCount = response.data.totalCount || 0;

          // Filter and add only unique courses
          const existingIds = new Set(
            geoJsonData.features.map((feature) => feature.properties?.id),
          );
          const uniqueNewFeatures: Feature<Point, CourseFeatureProperties>[] =
            courses
              .filter((course) => !existingIds.has(course.id)) // Check if already exists
              .map((course) => ({
                type: 'Feature',
                properties: {
                  id: course.id ?? '',
                  description: course.name ?? '',
                  country: course.countryName ?? '',
                  state: course.stateName ?? '',
                  address: course.address ?? '',
                  headerImagePath: course.headerImagePath ?? '',
                  logoImagePath: course.logoImagePath ?? '',
                },
                geometry: {
                  type: 'Point',
                  coordinates: [course.longitude ?? 0, course.latitude ?? 0],
                },
              }));

          setGeoJsonData((prevData) => ({
            type: 'FeatureCollection',
            features: [...prevData.features, ...uniqueNewFeatures],
          }));

          setTotalCourses(totalCount);
          setLoadedCourses((prev) => prev + courses.length);

          if ((page + 1) * lazyLoadPageSize < totalCount) {
            setPage((prev) => prev + 1);
          }
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      } finally {
        setCoursesLoading(false);
      }
    };

    fetchCourses();
  }, [
    userLocation,
    page,
    coursesLoading,
    loadedCourses,
    totalCourses,
    geoJsonData.features,
  ]);

  // Calculate nearest courses when location or data changes
  useEffect(() => {
    if (
      userLocation &&
      geoJsonData.features.length > 0 &&
      loadedCourses >= 25
    ) {
      const nearestCourses = geoJsonData.features
        .map((feature) => {
          const {
            id,
            description,
            headerImagePath,
            logoImagePath,
            country,
            state,
            address,
          } = feature.properties as CourseFeatureProperties;
          const [longitude, latitude] = feature.geometry.coordinates;

          return {
            id,
            name: description,
            lat: latitude,
            long: longitude,
            headerImagePath: headerImagePath,
            logoImagePath: logoImagePath,
            country: country,
            state: state,
            address: address,
            distance: calculateDistance(
              userLocation.latitude,
              userLocation.longitude,
              latitude,
              longitude,
            ),
          };
        })
        .sort((a, b) => a.distance - b.distance);

      setSortedCourses(nearestCourses.slice(0, 25)); // Top 10 nearest
    }
  }, [userLocation, geoJsonData, loadedCourses, totalCourses]);

  const fetchMultipleCoursePlayedStatus = async () => {
    setIsPlayedStatusLoading(true);
    try {
      const courseIds = sortedCourses.map((course) => course.id.toString());
      const response = await coursePlayedClient.getMultipleCoursesPlayedStatus(
        memberId,
        courseIds
      );
      if(response.data){
        setCoursePlayed(response.data || {});
      }
    } catch (error) {
      console.error('Error fetching course played status:', error);
    } finally {
      setIsPlayedStatusLoading(false);
    }
  };

  useEffect(() => {
    if(sortedCourses.length && !coursesLoading && isLoggedIn){
      fetchMultipleCoursePlayedStatus();
    }
  }, [sortedCourses, coursesLoading, isLoggedIn]);

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    console.log(query);
    setSearchTerm(query);

    // Use debouncedSearch to filter the local dataset
    debouncedSearch(query.toLowerCase());
  };

  const toggleView = () => {
    setIsListView((prev) => !prev);
  };

  const debouncedSearch = debounce((query: string) => {
    if (userLocation && query.length > 3) {
      setSearchLoading(true);

      try {
        // Filter the already loaded courses in memory
        const results = geoJsonData.features
          .filter((feature) => {
            const { description } =
              feature.properties as CourseFeatureProperties;
            return description.toLowerCase().includes(query);
          })
          .map((feature) => {
            const {
              id,
              description,
              headerImagePath,
              logoImagePath,
              country,
              state,
              address,
            } = feature.properties as CourseFeatureProperties;
            const [longitude, latitude] = feature.geometry.coordinates;
            return {
              id,
              name: description,
              lat: latitude,
              long: longitude,
              headerImagePath: headerImagePath,
              logoImagePath: logoImagePath,
              country: country,
              state: state,
              address: address,
              distance: calculateDistance(
                userLocation.latitude,
                userLocation.longitude,
                latitude,
                longitude,
              ),
            };
          })
          .sort((a, b) => a.distance - b.distance);

        setSearchResults(results.slice(0, 10)); // Return top 10 matches
      } catch (error) {
        console.error('Error filtering courses:', error);
      } finally {
        setSearchLoading(false);
      }
    } else {
      setSearchResults([]);
    }
  }, 300);

  const handleResultClick = (course: NearestCourse) => {
    if (mapRef.current && course.lat && course.long) {
      //WE SHOUDL KNOW HOW FAR AWAY FROM ME THIS IS

      const selectedLatitude = course.lat;
      const selectedLongitude = course.long;

      // Fly to the selected course location
      const adjustedCenter: [number, number] = [
        selectedLongitude,
        selectedLatitude + -0.008, // Adjust latitude for reduced height
      ];

      mapRef.current.flyTo({
        center: adjustedCenter,
        zoom: 14,
        speed: 1.2,
        curve: 1.4,
      });

      // Update sortedCourses based on the selected course's location
      const nearestCourses = geoJsonData.features
        .map((feature) => {
          const {
            id,
            description,
            headerImagePath,
            logoImagePath,
            country,
            state,
            address,
          } = feature.properties as CourseFeatureProperties;
          const [longitude, latitude] = feature.geometry.coordinates;

          return {
            id,
            name: description,
            lat: latitude,
            long: longitude,
            headerImagePath: headerImagePath,
            logoImagePath: logoImagePath,
            country: country,
            state: state,
            address: address,
            distance: calculateDistance(
              selectedLatitude,
              selectedLongitude,
              latitude,
              longitude,
            ),
          };
        })
        .sort((a, b) => a.distance - b.distance);

      setShowCourseDetilas(true);
      setSortedCourses(nearestCourses.slice(0, 25)); // Show top 25 nearest courses
      setSearchResults([]);
      setSearchTerm(course.name ?? '');
      setSelectedCourse(course);
      if (nearestCoursesRef.current) {
        nearestCoursesRef.current.goToSlide(0);
      }
    }
  };

  const handleMapClick = (event: mapboxgl.MapLayerMouseEvent) => {
    console.log('handleMapClick');

    const map = mapRef.current?.getMap();
    if (!map) return;

    // Check if the click is on a cluster
    const clusterFeatures = map.queryRenderedFeatures(event.point, {
      layers: ['clusters'], // Ensure we're querying the cluster layer
    });

    if (clusterFeatures.length > 0) {
      const clusterId = clusterFeatures[0].properties?.cluster_id;
      if (clusterId) {
        const source = map.getSource('courses') as mapboxgl.GeoJSONSource;
        source.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            console.error('Error getting cluster expansion zoom:', err);
            return;
          }

          // Ensure geometry is a Point
          const geometry = clusterFeatures[0].geometry;
          if (geometry?.type === 'Point') {
            const [longitude, latitude] = geometry.coordinates;
            map.flyTo({
              center: [longitude, latitude],
              zoom: (zoom ?? 0) + 2,
              speed: 1.2, // Controls the fly-to animation speed
              curve: 1.4, // Controls the smoothness of the animation
              essential: true, // Ensures the animation is not interrupted
            });
          } else {
            console.error('Geometry is not a Point for cluster.');
          }
        });
      }
      return; // Exit after handling cluster click
    }

    // Check if the click is on an individual marker
    const markerFeatures = map.queryRenderedFeatures(event.point, {
      layers: ['unclustered-point-label'], // Ensure we're querying the marker layer
    });

    if (markerFeatures.length > 0) {
      const feature = markerFeatures[0];
      if (feature.geometry?.type === 'Point' && feature.properties) {
        const {
          id,
          description,
          headerImagePath,
          logoImagePath,
          country,
          state,
          address,
        } = feature.properties as CourseFeatureProperties;
        const [long, lat] = feature.geometry.coordinates;

        const course: NearestCourse = {
          id: String(id), // Convert id to string to match CourseDTO type
          name: description,
          address,
          lat,
          long,
          headerImagePath,
          logoImagePath,
        };

        // Call handleResultClick with the course
        handleResultClick(course);
      }
      return; // Exit after handling marker click
    }

    // If no cluster or marker is clicked
    console.log('Clicked on the map, but no cluster or marker found.');
  };

  if (!userLocation) {
    return (
      <div
        className="flex justify-center items-center h-screen text-center text-white"
        style={{ backgroundColor: '#202020' }}
      >
        <p className="text-lg">
          Location services are required to use this feature. Please enable
          location services in your browser and refresh the page.
        </p>
      </div>
    );
  }

  const updateCoursePlayed = async (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | null, course: NearestCourse | null) => { 
    const req: MemberCoursesPlayedDTO = {
      memberId: memberId,
      courseId: course ? course.id.toString() : selectedCourse?.id.toString(),
    };

    try {
      await coursePlayedClient.updateCoursePlayedStatus(req);
      fetchMultipleCoursePlayedStatus();
    } catch (error) {
      console.error('Error updating course played status:', error);
    }
  };

  return (
    <div style={{ height: '100dvh', width: '100%', position: 'relative' }}>
      {/* Search Bar */}
      <SearchBar
        searchTerm={searchTerm}
        searchResults={searchResults}
        handleSearchInput={handleSearchInput}
        handleResultClick={handleResultClick}
        searchLoading={searchLoading}
        loadedCourses={loadedCourses}
        totalCourses={totalCourses}
        onToggleViewClick={toggleView} // Pass toggle function
        isListView={isListView} // Pass current view state
      />

      {/* Map Section */}
      <div
        style={{
          width: '100%',
          height:
            showCourseDetilas && window.innerWidth <= 768 // Check screen width
              ? '70%' // Reduce height only for smaller devices
              : '100%', // Full height for larger devices
          transition: 'height 0.3s ease', // Smooth transition
          position: 'relative',
          display: isListView ? 'none' : 'block', // Hide map when list view is active
        }}
      >
        <Map
          {...viewState}
          ref={mapRef}
          onLoad={(e) => handleMapLoad(e)}
          onClick={(e) => handleMapClick(e)}
          onMove={(evt) =>
            setViewState({ ...evt.viewState, bearing: 0, pitch: 0 })
          }
          mapboxAccessToken={MAP_BOX_KEY}
          style={{ width: '100%', height: '100%' }}
          projection={{ name: 'globe' }}
          mapStyle="mapbox://styles/golfplayed/clws4surt011b01qx6qk794sk"
          dragRotate={false} // Disable rotation by dragging
          touchZoomRotate={true} // Disable rotation with touch gestures
        >
          <MapControls mapRef={mapRef} geoJsonData={geoJsonData} />
          <MapLayers
            geoJsonData={geoJsonData}
            clusterLayer={clusterLayer}
            clusterCountLayer={clusterCountLayer}
            unclusteredPointLayer={unclusteredPointLayerWithLabel}
          />
          {userLocation && <UserLocationMarker userLocation={userLocation} />}
        </Map>
      </div>

      {/* Nearest Courses Slider */}
      <div style={{ display: isListView ? 'none' : 'block' }}>
        <NearestCourses
          selectedCourse={selectedCourse}
          setShowCourseDetails={setShowCourseDetilas}
          showCourseDetails={showCourseDetilas}
          ref={nearestCoursesRef}
          courses={sortedCourses}
          onCourseSelect={(course) => {
            if (mapRef.current) {
              setSelectedCourse({
                id: course.id,
                name: course.name,
                address: course.address,
              } as NearestCourse);
              const adjustedCenter: [number, number] = [
                course.long,
                course.lat + -0.008, // Adjust latitude for reduced height
              ];

              mapRef.current.flyTo({
                center: adjustedCenter,
                zoom: 14,
                speed: 1.2,
                curve: 1.4,
              });
            }
          }}
          courseplayedClient={coursePlayedClient}
          isLoggedIn={isLoggedIn}
          isPlayedStatusLoading={isPlayedStatusLoading}
          coursesPlayed={coursesPlayed}
          setIsLoggedIn={setIsLoggedIn}
          showLogin={showLogin}
          setShowLogin={setShowLogin}
          updateCoursePlayed={updateCoursePlayed}
        />
      </div>

      {/* List View */}
      {isListView && (
        <div
          style={{
            paddingTop: '75px',
            backgroundColor: '#202020',
            boxShadow: '0px 100px 0px rgb(28, 28, 28)',
          }}
        >
          <div
            style={{
              height: '90vh',
              width: '100%',
              position: 'relative',
              overflow: 'scroll',
              backgroundColor: '#202020',
            }}
          >
            <CourseDataHandler calculatedCourses={sortedCourses}>
              {(processedCourses) => (
                <CourseListWrapper
                  courses={processedCourses}
                  isLoggedIn={isLoggedIn}
                  isPlayedStatusLoading={isPlayedStatusLoading}
                  coursesPlayed={coursesPlayed}
                  setIsLoggedIn={setIsLoggedIn}
                  showLogin={showLogin}
                  setShowLogin={setShowLogin}
                  setSelectedCourse={setSelectedCourse}
                  updateCoursePlayed={updateCoursePlayed}
                />
              )}
            </CourseDataHandler>
          </div>
        </div>
      )}

      {showLogin && (
        <LoginModal
          isShowing={showLogin}
          setIsShowing={setShowLogin}
          setIsLoggedIn={setIsLoggedIn}
          onLogin={() => updateCoursePlayed(null, selectedCourse)}
        />
      )}
    </div>
  );
};

export default ExploreMap;
