import React, { useEffect, useState, ReactNode } from 'react';
import Sidebar from '../../components/layout/Sidebar';
import Topbar from '../../components/layout/Topbar';
import DarkOverlay from '../../components/layout/DarkOverlay';
import { getRoles } from '../../utils/tokenUtils';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css'; // Ensure this CSS is imported

interface Breadcrumb {
  label: string;
  href: string;
}

interface AdminLayoutProps {
  title: string;
  breadcrumbs: Breadcrumb[];
  allowedRoles: string[];
  children: ReactNode;
}

const AdminLayout: React.FC<AdminLayoutProps> = ({
  title,
  breadcrumbs,
  children,
  allowedRoles,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [roles, setRoles] = useState<string[] | null>(null);

  const navigate = useNavigate();

  // Fetch roles only once during component mount
  useEffect(() => {
    const userRoles = getRoles(); // Ensure `getRoles` returns a static reference, not a new object on each call
    setRoles(userRoles);
  }, []); // Empty dependency array ensures it runs only once

  // Effect for checking access based on roles
  useEffect(() => {
    if (roles) {
      const hasAccess =
        roles?.some((role) => allowedRoles.includes(role));
      if (!hasAccess) {
        navigate('/admin/dashboard');
      }
    }
  }, [roles, allowedRoles, navigate]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="flex h-screen bg-gray-200 overflow-hidden">
      {/* Sidebar */}
      <Sidebar
        isOpen={sidebarOpen}
        activePage={title}
      />
      {/* Dark overlay for mobile */}
      <DarkOverlay isVisible={sidebarOpen} closeSidebar={toggleSidebar} />

      {/* Main Content */}
      <div className="flex-1 flex flex-col bg-gray h-screen">
        {/* Topbar */}
        <Topbar
          toggleSidebar={toggleSidebar}
          breadcrumbs={breadcrumbs}
        />

        {/* Title */}
        <h1 className="text-3xl font-bold text-gray-800 px-8 mt-4">{title}</h1>

        {/* Page-specific content with Perfect Scrollbar */}
        <PerfectScrollbar className="flex-1 p-8">
          {children}
        </PerfectScrollbar>
      </div>
    </div>
  );
};

export default AdminLayout;
