import React from "react";
import AdminLayout from '../../../components/layout/AdminLayout';
import Card from '../../../components/common/Card';
import MemberForm from "../../../components/forms/MemberForm";
import { LegacyUserClient, LegacyUserDTO } from "../../../services/apiClient";
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../../config/config';
import useCountries from '../../../hooks/useCountries';

const MembersCreate: React.FC = () => {
    const navigate = useNavigate();
    const apiClient = new LegacyUserClient(API_BASE_URL);

    const breadcrumbs = [
        { label: 'Admin', href: '#' },
        { label: 'Members', href: '/admin/members' },
        { label: 'Create', href: '#' },
    ];

    const { countries } = useCountries();

    const handleSubmit = async (data: LegacyUserDTO) => {
        try {
            await apiClient.createLegacyUser(data);
            navigate('/admin/members');
        } catch (error) {
            console.error('Error creating member:', error);
        }
    };

    return (
        <AdminLayout title="Create Member" breadcrumbs={breadcrumbs} allowedRoles={['Admin']} >
            <Card>
                <MemberForm
                    initialValues={{} as LegacyUserDTO}
                    onSubmit={handleSubmit}
                    onCancel={() => navigate('/admin/members')}
                    isCreating={true}
                    countries={countries}
                    countriesLoading={false}
                    countriesError={null}
                />
            </Card>
        </AdminLayout>
    );
};

export default MembersCreate;