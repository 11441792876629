import React, { useEffect, useState } from 'react';
import AdminLayout from '../../../components/layout/AdminLayout';
import Card from '../../../components/common/Card';
import StateTable from '../../../components/tables/StateTable';
import Button from '../../../components/common/Button';
import AddNewButton from '../../../components/common/AddNewButton';
import FormField from '../../../components/common/FormField';
import { LegacyStateClient, LegacyStateDTO } from '../../../services/apiClient';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../../config/config';
import useCountries from '../../../hooks/useCountries';

const StateList: React.FC = () => {
  const [states, setStates] = useState<LegacyStateDTO[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [descriptionSearch, setDescriptionSearch] = useState<
    string | undefined
  >();
  const [countrySearch, setCountrySearch] = useState<number | undefined>();

  const navigate = useNavigate();
  const apiClient = new LegacyStateClient(API_BASE_URL);
  const {
    countries,
    isLoading: countriesLoading,
    error: countriesError,
  } = useCountries();

  const breadcrumbs = [
    { label: 'Admin', href: '#' },
    { label: 'States', href: '#' },
  ];

  const search = async (page: number, size: number, filters = {}) => {
    try {
      setIsLoading(true);
      const response = await apiClient.searchLegacyStates({
        filters: {
          state_Description: descriptionSearch ?? undefined,
          state_Country_ID: countrySearch ?? undefined,
          ...filters,
        },
        page,
        pageSize: size,
        sortBy: 'state_Description',
        sortDescending: false,
      });
      setStates((response.data?.results as LegacyStateDTO[]) || []);
      setTotalRecords(response.data?.totalCount || 0);
      setTotalPages(Math.ceil((response.data?.totalCount || 0) / size));
    } catch (error) {
      console.error('Error searching:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    search(currentPage, pageSize, { state_Country_ID: countrySearch });
  }, [currentPage, pageSize, countrySearch]);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    setCurrentPage(1);
    search(1, pageSize, {
      state_Description: descriptionSearch,
      state_Country_ID: countrySearch,
    });
  };

  const handleClearSearch = () => {
    setDescriptionSearch('');
    setCountrySearch(undefined);
    search(1, pageSize, {
      state_Description: undefined,
      state_Country_ID: undefined,
    });
  };

  return (
    <AdminLayout
      title="States"
      breadcrumbs={breadcrumbs}
      allowedRoles={['Admin']}
    >
      <Card>
        <div className="flex justify-between mb-4">
          <h2 className="text-lg font-bold text-gray-500">
            Manage States ({totalRecords})
          </h2>
          <AddNewButton onClick={() => navigate('/admin/states/create')} />
        </div>

        <form onSubmit={handleSearch} className="mb-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
            <FormField
              id="descriptionSearch"
              label="Description"
              type="text"
              value={descriptionSearch}
              onChange={(e) => setDescriptionSearch(e.target.value)}
              placeholder="Enter description"
            />

            <FormField
              id="countrySearch"
              label="Country"
              type="dropdown"
              value={countrySearch?.toString() || ''}
              onChange={(e) =>
                setCountrySearch(
                  e.target.value ? parseInt(e.target.value) : undefined,
                )
              }
              options={[
                { value: '', label: 'Select country to search' },
                ...countries.map((country) => ({
                  value: country.country_ID?.toString() || '',
                  label: country.country_Description || '',
                })),
              ]}
              placeholder="Select a country"
              disabled={countriesLoading || !!countriesError}
            />
          </div>
          <div className="flex justify-between mb-4">
            <div className="space-x-4">
              <Button variant="secondary" onClick={handleClearSearch}>
                Clear Search
              </Button>
              <Button type="submit">Search</Button>
            </div>
          </div>
        </form>

        <StateTable
          data={states}
          onEdit={(state) => navigate(`/admin/states/${state.state_ID}`)}
          onDelete={(state) => {
            if (window.confirm('Are you sure you want to delete this state?')) {
              apiClient
                .deleteLegacyState(state.state_ID ?? 0)
                .then(() =>
                  setStates(
                    states.filter((s) => s.state_ID !== state.state_ID),
                  ),
                );
            }
          }}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          isLoading={isLoading}
        />
      </Card>
    </AdminLayout>
  );
};

export default StateList;
