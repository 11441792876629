import React, { useState } from 'react';
import { Upload } from 'lucide-react'; // Import Lucide React's Upload icon

interface FileUploadProps {
  id: string; // Unique identifier for the input
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholderText?: string; // Optional text for display
  supportedFormats?: string; // Optional formats info
}

const FileUpload: React.FC<FileUploadProps> = ({
  id,
  onChange,
  placeholderText = 'Upload media or drag and drop',
  supportedFormats = 'PNG, JPG or GIF up to 10MB',
}) => {
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setUploadedFileName(e.target.files[0].name); // Set uploaded file name
      onChange(e);
    }
  };

  return (
    <div className="relative my-6">
      {/* Hidden file input */}
      <input
        id={id}
        name={id}
        type="file"
        className="hidden"
        onChange={handleFileChange}
      />
      {/* Label for file input */}
      <label
        htmlFor={id}
        className="relative flex flex-col items-center gap-2 px-3 py-6 text-sm font-medium text-center transition-colors border border-dashed rounded cursor-pointer border-slate-300 hover:bg-slate-50"
      >
        {/* Icon container */}
        <span className="inline-flex items-center justify-center h-12 px-3 rounded-full bg-slate-100/70 text-slate-400">
          <Upload className="w-6 h-6" aria-label="File input icon" />
        </span>
        {/* Placeholder Text */}
        <span className="text-slate-500">
          {placeholderText}
        </span>
        {/* Supported Formats */}
        <span className="text-xs text-slate-400">{supportedFormats}</span>
        {/* Uploaded File Name */}
        {uploadedFileName && (
          <span className="mt-2 text-xs text-emerald-500">
            {uploadedFileName} ready to be uploaded
          </span>
        )}
      </label>
    </div>
  );
};

export default FileUpload;
