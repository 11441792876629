import React, { useEffect, useState } from 'react';
import Button from '../../components/common/Button';
import FormField from '../../components/common/FormField';
import { CourseClient, LegacyCourseDTO, LegacyCountryDTO } from '../../services/apiClient';
import useStates from '../../hooks/useStates';
import useSubRegions from '../../hooks/useSubRegions';
import MapWithDraggableMarker from '../maps/MapWithDraggableMarker';
import CollapsiblePanel from '../common/CollapsiblePanel';
import { API_BASE_URL, PUBLIC_CONTAINER_URL } from '../../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import handleChange from '../../utils/formUtils/handleChange';

interface CourseFormProps {
  initialValues: LegacyCourseDTO;
  countries: LegacyCountryDTO[];
  countriesLoading: boolean;
  countriesError: string | null;
  isCreating: boolean;
  setLogoImage?: (file: File | null) => void;
  setHeaderImage?: (file: File | null) => void;
  onSubmit: (data: LegacyCourseDTO) => void;
  onCancel: () => void;
}

const CourseForm: React.FC<CourseFormProps> = ({
  initialValues,
  countries,
  countriesLoading,
  countriesError,
  isCreating,
  setHeaderImage,
  setLogoImage,
  onSubmit,
  onCancel,
}) => {
  const [formData, setFormData] = useState(initialValues);
  const [imagesCollapsed, setImagesCollapsed] = useState(false);

  const {
    states,
    isLoading: statesLoading,
    error: statesError,
  } = useStates(formData.country_ID || null);
  const {
    subRegions,
    isLoading: subRegionsLoading,
    error: subRegionsError,
  } = useSubRegions(formData.state_ID || null);

  const courseClient = new CourseClient(API_BASE_URL);

  // Reset the state ID if country changes
  useEffect(() => {
    if (formData.country_ID) {
      setFormData((prev) => ({
        ...prev,
        state_ID: undefined,
      }));
    }
  }, [formData.country_ID]);

  // Reset the sub_Region_ID if state changes
  useEffect(() => {
    if (formData.state_ID) {
      setFormData((prev) => ({
        ...prev,
        sub_Region_ID: undefined,
      }));
    }
  }, [formData.state_ID]);

  const handlePositionChange = (lat: number, lng: number) => {
    setFormData((prev) => ({
      ...prev,
      course_Latitude: lat.toString(),
      course_Longitude: lng.toString(),
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-4">
        <FormField
          id="course_Name"
          label="Course Name"
          type="text"
          value={formData.course_Name || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter course name"
        />
        <FormField
          id="course_Details"
          label="Course Details"
          type="richtext"
          value={formData.course_Details || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter course details"
        />
        <FormField
          id="course_Address"
          label="Course Address"
          type="text"
          value={formData.course_Address || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter course address"
        />
        <FormField
          id="course_Phone_Number"
          label="Course Phone Number"
          type="text"
          value={formData.course_Phone_Number || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter phone number"
        />
        <FormField
          id="course_Email"
          label="Course Email"
          type="email"
          value={formData.course_Email || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter email"
        />
        <FormField
          id="course_URL"
          label="Course URL"
          type="text"
          value={formData.course_URL || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter URL"
        />
        <FormField
          id="country_ID"
          label="Country"
          type="dropdown"
          value={formData.country_ID?.toString() || ''}
          onChange={(e) => handleChange(e, setFormData)}
          options={[
            { value: '', label: 'Please select a country' }, // Default option
            ...countries.map((country) => ({
              value: (country.country_ID ?? '').toString(),
              label: country.country_Description ?? '',
            })),
          ]}
          placeholder="Select a country"
          disabled={countriesLoading || !!countriesError}
        />

        <FormField
          id="state_ID"
          label="State"
          type="dropdown"
          value={formData.state_ID?.toString() || ''}
          onChange={(e) => handleChange(e, setFormData)}
          options={[
            { value: '', label: 'Please select a state' }, // Default option
            ...states.map((state) => ({
              value: (state.state_ID ?? '').toString(),
              label: state.state_Description ?? '',
            })),
          ]}
          disabled={statesLoading || !!statesError || !formData.country_ID}
        />

        <FormField
          id="sub_Region_ID"
          label="Sub Region"
          type="dropdown"
          value={formData.sub_Region_ID?.toString() || ''}
          onChange={(e) => handleChange(e, setFormData)}
          options={[
            { value: '', label: 'Please select a sub region' }, // Default option
            ...subRegions.map((subRegion) => ({
              value: (subRegion.sub_Region_ID ?? '').toString(),
              label: subRegion.sub_Region_Name ?? '',
            })),
          ]}
          disabled={subRegionsLoading || !!subRegionsError || !formData.state_ID}
        />
        {/* Google Map Component */}
        <div className="mb-4">
          <MapWithDraggableMarker
            latitude={parseFloat(formData.course_Latitude ?? '0') || 0}
            longitude={parseFloat(formData.course_Longitude ?? '0') || 0}
            onPositionChange={handlePositionChange}
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <FormField
            id="course_Latitude"
            label="Course Latitude"
            type="text"
            value={formData.course_Latitude || ''}
            onChange={(e) => handleChange(e, setFormData)}
            placeholder="Enter latitude"
          />

          <FormField
            id="course_Longitude"
            label="Course Longitude"
            type="text"
            value={formData.course_Longitude || ''}
            onChange={(e) => handleChange(e, setFormData)}
            placeholder="Enter longitude"
          />
        </div>
        <FormField
          id="course_Active"
          label="Course Active"
          type="checkbox"
          value={formData.course_Active || false}
          onChange={(e) => handleChange(e, setFormData)}
        />
        <FormField
          id="course_Time_Zone"
          label="Course Time Zone"
          type="text"
          value={formData.course_Time_Zone || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter time zone"
        />
        <FormField
          id="course_Tee_Off_Times"
          label="Course Tee Off Times"
          type="text"
          value={formData.course_Tee_Off_Times || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter tee off times"
        />

        <FormField
          id="course_Club_Manager_Name"
          label="Course Club Manager Name"
          type="text"
          value={formData.course_Club_Manager_Name || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter club manager name"
        />
        <FormField
          id="course_Club_Manager_Email"
          label="Course Club Manager Email"
          type="email"
          value={formData.course_Club_Manager_Email || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter club manager email"
        />
        <FormField
          id="course_Club_Manager_Phone_Number"
          label="Course Club Manager Phone Number"
          type="text"
          value={formData.course_Club_Manager_Phone_Number || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter club manager phone number"
        />
        <FormField
          id="course_Shop_Name"
          label="Course Shop Name"
          type="text"
          value={formData.course_Shop_Name || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter shop name"
        />
        <FormField
          id="course_Shop_Email"
          label="Course Shop Email"
          type="email"
          value={formData.course_Shop_Email || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter shop email"
        />
        <FormField
          id="course_Shop_Phone_Number"
          label="Course Shop Phone Number"
          type="text"
          value={formData.course_Shop_Phone_Number || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter shop phone number"
        />
        <FormField
          id="course_Pro_Name"
          label="Course Pro Name"
          type="text"
          value={formData.course_Pro_Name || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter pro name"
        />
        <FormField
          id="course_Pro_Email"
          label="Course Pro Email"
          type="email"
          value={formData.course_Pro_Email || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter pro email"
        />
        <FormField
          id="course_Pro_Phone_Number"
          label="Course Pro Phone Number"
          type="text"
          value={formData.course_Pro_Phone_Number || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter pro phone number"
        />
        <FormField
          id="course_Designer_Name"
          label="Course Designer Name"
          type="text"
          value={formData.course_Designer_Name || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter designer name"
        />
        <FormField
          id="course_Total_Holes"
          label="Course Total Holes"
          type="number"
          value={formData.course_Total_Holes?.toString() || ''}
          onChange={(e) => handleChange(e, setFormData)}
          placeholder="Enter total holes"
        />
        {formData && formData.course_ID && (
          <CollapsiblePanel
            title="Course Images"
            collapsed={imagesCollapsed}
            subHeader="Upload course-related images that will be used when users are viewing course details."
          >
            {/* Current Course Logo */}
            <div className="mb-4 relative">
              <label className="block text-sm font-medium text-gray-700">
                Current Logo
              </label>
              {formData.course_Logo ? (
                <div className="relative w-32 h-32">
                  <img
                    src={`${PUBLIC_CONTAINER_URL}/${formData.course_Logo}`}
                    alt="Course Logo"
                    className="w-full h-full object-cover border border-gray-300 rounded"
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="absolute top-2 right-2 text-red-600 cursor-pointer hover:text-red-800"
                    onClick={() => setFormData((prev) => ({
                      ...prev,
                      course_Logo: "",
                    }))}
                  />
                </div>
              ) : (
                <p className="text-sm text-gray-500">No logo uploaded.</p>
              )}
            </div>

            <FormField
              id="logoImage"
              label="Course Logo"
              type="file"
              onChange={(e) => {
                const file = (e.target as HTMLInputElement).files?.[0];
                if (file && setLogoImage) setLogoImage(file);
              }}
            />

            {/* Current Header Image */}
            <div className="mt-6 mb-4 relative">
              <label className="block text-sm font-medium text-gray-700">
                Current Header Image
              </label>
              {formData.course_Header ? (
                <div className="relative w-full h-48">
                  <img
                    src={`${PUBLIC_CONTAINER_URL}/${formData.course_Header}`}
                    alt="Course Header"
                    className="w-full h-full object-cover border border-gray-300 rounded"
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="absolute top-2 right-2 text-red-600 cursor-pointer hover:text-red-800"
                    onClick={() => setFormData((prev) => ({
                      ...prev,
                      course_Header: "",
                    }))}
                  />
                </div>
              ) : (
                <p className="text-sm text-gray-500">
                  No header image uploaded.
                </p>
              )}
            </div>

            <FormField
              id="headerImage"
              label="Header Image"
              type="file"
              onChange={(e) => {
                const file = (e.target as HTMLInputElement).files?.[0];
                if (file && setHeaderImage) setHeaderImage(file);
              }}
            />
          </CollapsiblePanel>
        )}
      </div>

      <div className="flex justify-end space-x-4">
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit">{isCreating ? 'Create' : 'Save'}</Button>
      </div>
    </form>
  );
};

export default CourseForm;
